import * as React from 'react';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Control, useFieldArray, useForm, UseFormGetValues } from 'react-hook-form';
import {
    DefaultButton,
    IColumn,
    IconButton,
    IDropdownOption,
    mergeStyleSets,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    TextField,
    useTheme,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useJobContext } from '../../../../../JobPortalLayoutPage';
import { useCreateInvoice } from 'pages/InvoicePortal/components/Invoices/hooks';
import { DefaultFormSettings } from '../../../../../../../constants';
import { IInvoice, IInvoiceExtraAmount, NewInvoice, } from '../../../../../../InvoicePortal/components/Invoices/interfaces';
import { InvoiceParagraphType } from '../../../../../../InvoicePortal/components/Invoices/enums';
import { BillingEntityType } from '../../../../../../../enums';
import { MessageBarType } from "@fluentui/react";
import {
    _BilllingEntityDropdownOptions,
    _InitialExtraAmountSumValue,
    _ParagraphDropdownOptions,
    calculateExtraAmountSum,
    calculateTotal,
} from '../../../../../../InvoicePortal/components/Invoices';
import {
    Card,
    ControlledCheckbox,
    ControlledDatePicker,
    ControlledDropdown,
    ControlledNumberField,
    DataTable,
    Modal
} from "../../../../../../../components";
import { IInvoiceInfo } from '../interfaces';
import { useNotifications } from "../../../../../../../components/notifications";
import { useInvoicePortalNotifications } from "../../../../../../InvoicePortal/hooks";
import { useGetExtraAmountParagraphs } from 'pages/InvoicePortal/components/Invoices/hooks/useGetExtraAmountParagraphs';
import { IExtraAmountParagraph } from 'pages/InvoicePortal/components/Invoices/interfaces/IExtraAmountParagraph';

interface IInvoiceAddFormPros {
    data: InvoiceInfo;
    auditReportDate: Date;
    enableOverride?: boolean;
    onSkip?: () => void;
    onClose: () => void;
    onCreated?: (item: IInvoice) => void;
    onError?: () => void;
}

type InvoiceInfo = Pick<IInvoiceInfo, 'paragraph' | 'billingEntity' | 'auditFee' | 'additionalCharges' | 'baseFeeSubtotal'>

interface IFormInvoiceExtraAmount extends IInvoiceExtraAmount { 
    isFmValue: boolean 
}

interface IInvoiceAddFormItem {
    amount: number;
    invoiceDate: string;
    paragraph: number;
    billingEntity: number;
    extraAmounts: IFormInvoiceExtraAmount[];
    overrideWithoutInvoice: boolean;
}

export const AdministrationInvoiceAddForm: FunctionComponent<IInvoiceAddFormPros> = ({ data, auditReportDate, enableOverride, onClose, ...props }: IInvoiceAddFormPros) => {
    const { formatMessage, formatNumber } = useIntl();
    const theme = useTheme();
    const {showNotification} = useNotifications();
    
    const _gst: number = 0.1;
    
    const { jobId, job } = useJobContext();
    const [clientIdentifier] = useState<string>(job.client.guid);
    const { create, isCreating } = useCreateInvoice(clientIdentifier, true);
    
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [isOverrideControlVisible, setIsOverrideControlVisible] = useState<boolean>(enableOverride ?? false);
    
    const { control, watch, handleSubmit, formState, getValues, setValue } = useForm<IInvoiceAddFormItem>({
        ...DefaultFormSettings,
        defaultValues: {
            amount: data.baseFeeSubtotal,
            invoiceDate: auditReportDate.toString(),
            paragraph: data.paragraph === 'DD' ? InvoiceParagraphType.DD : InvoiceParagraphType.Standard,
            billingEntity: data.billingEntity,
            extraAmounts: data.additionalCharges?.length ? data.additionalCharges.map(c => ({ paragraph: c.id, amount: c.amount, isFmValue: true })) : [],
            overrideWithoutInvoice: false
        }
    });

    const amountsWatcher = watch(['extraAmounts', 'amount']);
    const overrideWatch = watch('overrideWithoutInvoice')
    
    const onSubmit = (formItem: IInvoiceAddFormItem) => {
        if (formItem.overrideWithoutInvoice) {
            props.onSkip?.();
            return;
        } else {
            create({
                requests: [{
                    billingEntityType: formItem.billingEntity,
                    paragraph: formItem.paragraph,
                    gst: _gst,
                    amount: formItem.amount,
                    extraAmounts: [...formItem.extraAmounts],
                    jobId: jobId,
                    creationDate: new Date(formItem.invoiceDate)
                } as NewInvoice],
                applyCreditNotes: false
            }, {
                onSuccess: (resp: any) => {
                    if (!!resp.data?.errorCode) {
                        showNotification({
                            type: MessageBarType.error,
                            name: formatMessage({ id: 'error' }),
                            description: formatMessage({ id: 'unableToCreateInvoiceAtXero'}),
                        });
                        props.onError?.();
                    } else {
                        props.onCreated?.(resp.data);
                        showNotification({
                            type: MessageBarType.success,
                            name: formatMessage({ id: 'success' }),
                            description: formatMessage({ id: 'entityWasCreated' }, { name: 'Invoice' }),
                        });
                    }
                },
                onError: () => {
                    showNotification({
                        type: MessageBarType.error,
                        name: formatMessage({ id: 'error' }),
                        description: formatMessage({ id: 'unableToCreateInvoice'}),
                    });
                    props.onError?.()
                }
            })
        }
    }

    useEffect(() => {
        setTotalAmount(calculateTotal((+getValues('amount') ?? 0), calculateExtraAmountSum(getValues('extraAmounts'))));
    }, [amountsWatcher]);

    const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false);
    useEffect(() => {
        const isDisabled = !formState.isValid || isCreating || (isOverrideControlVisible && overrideWatch);
        setSaveButtonEnabled(!isDisabled)
    }, [formState.isValid, isCreating, overrideWatch, isOverrideControlVisible]);
    
    const fieldWidth = 'calc(33% - 16px)';

    const classNames = mergeStyleSets({
        field_disabled: {
            'label': {
                color: theme.schemes?.default?.semanticColors.bodyText
            },
            'input': {
                color: theme.palette.blackTranslucent40
            }
        }
    })
    
    return (
        <>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    <Card styles={{ root: { padding: '16px 16px 20px 16px' } }}>
                        <Stack horizontal tokens={{ childrenGap: 16 }} wrap>
                            <Stack.Item styles={{ root: { width: fieldWidth } }}>
                                <ControlledNumberField
                                    name='amount'
                                    control={control}
                                    disabled={overrideWatch}
                                    rules={{ required: formatMessage({id: 'amountIsRequired'}) }}
                                    label={formatMessage({ id: 'amount' })}
                                />
                            </Stack.Item>
                            <Stack.Item styles={{ root: { width: fieldWidth } }}>
                                <ExtraAmountControl control={control as any}
                                                    disabled={isCreating || overrideWatch}
                                                    getValues={getValues}
                                                    setValue={setValue as any}/>
                            </Stack.Item>
                            <Stack.Item styles={{ root: { width: fieldWidth } }}>
                                <TextField
                                    className={classNames.field_disabled}
                                    label={formatMessage({ id: 'total' })}
                                    disabled
                                    value={formatNumber(totalAmount, { style: 'currency', currency: 'USD' })}
                                />
                            </Stack.Item>

                            <Stack.Item styles={{ root: { width: fieldWidth } }}>
                                <ControlledDatePicker
                                    control={control}
                                    name='invoiceDate'
                                    disabled={overrideWatch}
                                    rules={{ required: formatMessage({id: 'creationDateRequired'}) }}
                                    label={formatMessage({ id: 'creationDate' })}
                                />
                            </Stack.Item>
                            <Stack.Item styles={{ root: { width: fieldWidth } }}>
                                <ControlledDropdown
                                    name='paragraph'
                                    control={control}
                                    disabled={overrideWatch}
                                    label={formatMessage({ id: 'invoiceParagraph' })}
                                    options={_ParagraphDropdownOptions}
                                />
                            </Stack.Item>
                            <Stack.Item styles={{ root: { width: fieldWidth } }}>
                                <ControlledDropdown
                                    name='billingEntity'
                                    control={control}
                                    disabled={overrideWatch}
                                    label={formatMessage({ id: 'billingEntity' })}
                                    options={_BilllingEntityDropdownOptions}
                                />
                            </Stack.Item>
                        </Stack>
                    </Card>
                </Stack.Item>

                <Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign={'end'} verticalAlign={'center'}>
                        {isOverrideControlVisible && <Stack.Item>
                            <ControlledCheckbox name={'overrideWithoutInvoice'} 
                                                control={control} 
                                                label={formatMessage({id: 'initialQueriesInvoiceOverrideMessage'})}
                            />
                        </Stack.Item>}
                        <Stack.Item>
                            <PrimaryButton disabled={!saveButtonEnabled}
                                           onClick={handleSubmit(onSubmit)} >
                                {isCreating
                                    ? <Spinner size={SpinnerSize.small}></Spinner>
                                    : formatMessage({id: 'save'})}
                            </PrimaryButton>
                        </Stack.Item>
                        {!overrideWatch && <Stack.Item>
                            <DefaultButton disabled={isCreating} text={formatMessage({id: 'close'})} onClick={onClose} />
                        </Stack.Item>}
                        {overrideWatch && <Stack.Item>
                            <PrimaryButton text={formatMessage({id: 'continue'})} onClick={handleSubmit(onSubmit)} />
                        </Stack.Item>}
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    )
}

interface IExtraAmountControlProps {
    control: Control<Pick<IInvoiceAddFormItem, 'extraAmounts'>>
    getValues: UseFormGetValues<Pick<IInvoiceAddFormItem, 'extraAmounts'>>;
    setValue: UseFormSetValue<Pick<IInvoiceAddFormItem, 'extraAmounts'>>;
    
    disabled?: boolean
}

const ExtraAmountControl: FunctionComponent<IExtraAmountControlProps> = ({ control, getValues, setValue, disabled }: IExtraAmountControlProps) => {
    const { formatMessage, formatNumber } = useIntl();
    const theme = useTheme();
    const { clientGuid } = useJobContext();
    const { extraAmountParagraphs } = useGetExtraAmountParagraphs(clientGuid);

    const [extraAmountModalOpen, { toggle: toggleExtraAmountModalOpen }] = useBoolean(false);
    
    const [extraAmountSum, setExtraAmountSum] = useState<number>(_InitialExtraAmountSumValue);
    
    const onSubmit = (items: IFormInvoiceExtraAmount[]) => {
        setValue('extraAmounts', items ?? []);
        setExtraAmountSum(calculateExtraAmountSum(items))
        toggleExtraAmountModalOpen()
    }

    useEffect(() => {
        setExtraAmountSum(calculateExtraAmountSum(getValues('extraAmounts')))
        return () => {}
    }, []);
    
    const classNames = mergeStyleSets({
        field_disabled: {
            'label': {
                color: 'rgb(0, 0, 0)'
            },
            'input': {
                color: theme.palette.blackTranslucent40
            }
        }
    })
    
    return (
        <>
            <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign='end'>
                <Stack.Item grow={10}>
                    <TextField className={classNames.field_disabled}
                        label={formatMessage({ id: 'extraAmount' })}
                        disabled
                        value={formatNumber(extraAmountSum, { style: 'currency', currency: 'USD' })}
                    />
                </Stack.Item>
                <Stack.Item>
                    <IconButton iconProps={{iconName: 'Add'}}
                                disabled={!!disabled}
                                text={formatMessage({ id: 'add' })}
                                onClick={toggleExtraAmountModalOpen} />
                </Stack.Item>
            </Stack>

            <Modal title={formatMessage({id: 'extraAmount'})}
                   width={300}
                   isOpen={extraAmountModalOpen}
                   onDismiss={toggleExtraAmountModalOpen}
            >
                <Card>
                    <ExtraAmountAddForm items={getValues('extraAmounts')} 
                                        extraAmountParagraphs={extraAmountParagraphs!}
                                        onClose={toggleExtraAmountModalOpen}
                                        onSubmit={onSubmit}
                    />
                </Card>
            </Modal>
        </>
    )
}

interface IExtraAmountAddFormProps {
    items: IFormInvoiceExtraAmount[],
    extraAmountParagraphs: IExtraAmountParagraph[],
    onSubmit: (items: IFormInvoiceExtraAmount[]) => void,
    onClose: () => void;
}

interface IExtraAmountAddFormItem {
    extraAmounts: IFormInvoiceExtraAmount[];
}

const ExtraAmountAddForm: FunctionComponent<IExtraAmountAddFormProps> = ({ items, extraAmountParagraphs, onClose, ...props }: IExtraAmountAddFormProps) => {
    const { formatMessage } = useIntl();
    
    console.debug("[items]", items);
    const _defaultExtraAmountItem: IInvoiceExtraAmount = { paragraph: 1, isFmValue: false } as IFormInvoiceExtraAmount;
    
    const { control, handleSubmit, formState } = useForm<IExtraAmountAddFormItem>({
        ...DefaultFormSettings,
        defaultValues: {
            extraAmounts: (items ?? []).length ? [...items] : [{..._defaultExtraAmountItem}]
        }
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'extraAmounts'
    })
    
    const options: IDropdownOption[] = useMemo(() => {
        return [
            ...Object.keys(extraAmountParagraphs).filter(k => !isNaN(+k)).map(k => ({ key: extraAmountParagraphs[+k].id, text: extraAmountParagraphs[+k].title }))
        ]
    }, [formatMessage])
    
    const onAddClick = () => {
        append({..._defaultExtraAmountItem})
    }
    
    const onSubmit = (formItems: IExtraAmountAddFormItem) => {
        props.onSubmit(formItems.extraAmounts ?? [])
    }
    
    const classNames = mergeStyleSets({
        dropdown: {
            width: '100%',
            '.ms-Dropdown-container': {
                width: '100%'
            }
        }
    })
    
    const columns: IColumn[] = useMemo(() => [
        {
            key: 'amount',
            name: formatMessage({ id: 'amount' }),
            fieldName: 'amount',
            minWidth: 140,
            maxWidth: 140,
            onRender: (item: IFormInvoiceExtraAmount, index?: number) => (
                <ControlledNumberField
                    name={`extraAmounts.${index!}.amount`}
                    control={control}
                    disabled={item.isFmValue}
                    rules={{ required: formatMessage({id: 'amountIsRequired'}) }}
                />
            ),
        },
        {
            key: 'paragraphName',
            name: formatMessage({ id: 'invoiceParagraph' }),
            fieldName: 'paragraphName',
            minWidth: 140,
            onRender: (item: IFormInvoiceExtraAmount, index?: number) => (
                <Stack className={classNames.dropdown} grow>
                    <Stack.Item>
                        <ControlledDropdown
                            name={`extraAmounts.${index!}.paragraph`}
                            control={control}
                            options={options}
                            disabled={item.isFmValue}
                            rules={{ required: formatMessage({id: 'paragraphRequired'}) }}
                        />
                    </Stack.Item>
                </Stack>
                
            ),
        },
        {
            key: 'action',
            name: '',
            minWidth: 20,
            maxWidth: 20,
            onRender: (item: IFormInvoiceExtraAmount, index?: number) => (
                <IconButton iconProps={{iconName: 'Remove'}}
                            text={formatMessage({ id: 'remove' })}
                            disabled={item.isFmValue}
                            onClick={() => remove(index)}
                />
            ),
        },
    ], [formatMessage]);
    
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack.Item>
                <DataTable initialColumns={columns}
                           hideIfEmpty={false}
                           items={fields || []}
                />
            </Stack.Item>
            <Stack.Item>
                <IconButton iconProps={{iconName: 'Add'}}
                            disabled={!formState.isValid}
                            text={formatMessage({ id: 'add' })}
                            onClick={() => onAddClick()} />
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign={'end'}>
                    <Stack.Item>
                        <PrimaryButton disabled={!formState.isDirty || !formState.isValid}
                                       onClick={handleSubmit(onSubmit)}>
                            {formatMessage({id: 'save'})}
                        </PrimaryButton>
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton text={formatMessage({id: 'close'})} onClick={onClose} />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}