import {IEventSummaryItem} from "./interfaces/IEventSummaryItem";
import { forwardRef, FunctionComponent, useImperativeHandle, useState } from 'react';
import {IDropdownOption, Stack} from "@fluentui/react";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../../constants";
import {ControlledDatePicker, ControlledDropdown, ControlledTextField} from "../../../../../../components";
import {useIntl} from "react-intl";
import {EventSummaryContraventionTemplate} from "./EventSummaryContraventionTemplate";
import {IEventSummaryGeneralQuestion} from "./interfaces/IEventSummaryGeneralQuestion";
import { useTabContext } from "../../../../JobPortalPage";

type EventSummaryFormProps = {
    item?: IEventSummaryItem;
    generalQuestions: IEventSummaryGeneralQuestion[];
    jobId: number;
    onSubmit: (item: IEventSummaryItem) => void;
}

export const EventSummaryForm = forwardRef(({item, jobId, generalQuestions, onSubmit}: EventSummaryFormProps, ref) => {
    const {formatMessage} = useIntl();
    
    const {sections} = useTabContext();

    const [auditQualificationPtnApproved, setAuditQualificationPtnApproved] = useState((sections ?? []).find(x => x.reference === 'Audit Qualification')?.sectionAnswer?.partnerApproval);

    const questionOptions: IDropdownOption[] = [
        {key: '', text: ''},
        {key: 'Yes', text: 'Yes'},
        {key: 'No', text: 'No'}
    ];

    const {control, formState, handleSubmit} = useForm<IEventSummaryItem>({
        ...DefaultFormSettings,
        defaultValues: {
            id: item?.id,
            jobId: jobId,
            name: item?.name,
            description: item?.description || '',
            date: item?.date,
            completionDate: item?.completionDate,
            commenceQuestion: item?.commenceQuestion,
            contraventionBeenRectifiedQuestion: item?.contraventionBeenRectifiedQuestion,
            descriptionSteps: item?.descriptionSteps || '',
            eventSummaryContraventions: item?.eventSummaryContraventions
        }
    });

    const handleSubmitForm = () => {
            handleSubmit(onSubmit)();
    }


    useImperativeHandle(ref, () => ({
        submit() {
            handleSubmitForm();
        }
    }));

    return (
        <Stack tokens={{childrenGap: 16}} wrap>
            <Stack.Item>
                <ControlledTextField name={'name'}
                                     label={formatMessage({id: 'eventSummaryForm_label_eventName'})}
                                     multiline
                                     required
                                     disabled={auditQualificationPtnApproved}
                                     rules={{required: formatMessage({id: 'requiredVariableName'})}}
                                     control={control}/>
            </Stack.Item>
            <Stack horizontal tokens={{childrenGap: 16}} horizontalAlign="space-between" grow>
                <Stack.Item style={{width: '100%'}}>
                    <ControlledDropdown name={'commenceQuestion'}
                                        label={formatMessage({id: 'eventSummaryForm_label_commenceQuestion'})}
                                        options={questionOptions}
                                        disabled={auditQualificationPtnApproved}
                                        control={control}/>
                </Stack.Item>
                <Stack.Item style={{width: '100%'}}>
                    <ControlledDatePicker name={'date'}
                                          disabled={auditQualificationPtnApproved}
                                          label={formatMessage({id: 'eventSummaryForm_label_eventStartDate'})}
                                          control={control}/>
                </Stack.Item>
            </Stack>
            <Stack.Item>
                <ControlledTextField name={'description'}
                                     label={formatMessage({id: 'eventSummaryForm_label_description'})}
                                     multiline
                                     disabled={auditQualificationPtnApproved}
                                     control={control}/>
            </Stack.Item>
            <Stack horizontal tokens={{childrenGap: 16}} horizontalAlign="space-between" grow>
                <Stack.Item style={{width: '100%'}}>
                    <ControlledDropdown name={'contraventionBeenRectifiedQuestion'}
                                        label={formatMessage({id: 'eventSummaryForm_label_contraventionBeenRectifiedQuestion'})}
                                        options={questionOptions}
                                        disabled={auditQualificationPtnApproved}
                                        control={control}/>
                </Stack.Item>
                <Stack.Item style={{width: '100%'}}>
                    <ControlledDatePicker name={'completionDate'}
                                          disabled={auditQualificationPtnApproved}
                                          label={formatMessage({id: 'eventSummaryForm_label_completionDate'})}
                                          control={control}/>
                </Stack.Item>
            </Stack>
            <Stack.Item>
                <ControlledTextField name={'descriptionSteps'}
                                     label={formatMessage({id: 'eventSummaryForm_label_descriptionSteps'})}
                                     multiline
                                     disabled={auditQualificationPtnApproved}
                                     control={control}/>
            </Stack.Item>
            <Stack.Item>
                <EventSummaryContraventionTemplate control={control} disabled={auditQualificationPtnApproved} questions={generalQuestions} jobId={jobId}/>
            </Stack.Item>
        </Stack>
    );
});