import { DefaultButton, IColumn, Link, SelectionMode, Spinner, Stack, Dropdown, useTheme, DialogType, TooltipHost, StackItem, Dialog, PrimaryButton, DialogFooter, IconButton, Text } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import React, { FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import { useJobContext } from ".";
import { DataTable, Modal, Search } from "../../components";
import { SoftwareType } from "../../enums";
import { useGetFile } from "../../hooks";
import { useGetAmazonBucketName, useGetSoftwareUploads, useRemoveSoftwareUpload } from "./hooks"


interface ISoftwareUploadInfoProps {
    fileName: string;
    downloadUrl: string;
    storageFolder: string;
    path: string;
}

interface ISoftwareUploadsProps {
    storageFolder: string,
    path: string,
    fileName: string
}

interface ISoftwareUploadDownloadProps {
    downloadUrl: string;
    fileName: string
}

const SoftwareUploadDownload: FunctionComponent<ISoftwareUploadDownloadProps> = ({ downloadUrl, fileName }) => {
    const { getFile } = useGetFile();
    const tooltipId = useId('tooltip');


    return <StackItem align='center'>
        <TooltipHost
            content="Download"
            id={tooltipId}>
            <IconButton iconProps={{ iconName: 'Download' }}
                onClick={() => getFile({
                    url: downloadUrl,
                    fileName: fileName
                })} />
        </TooltipHost>
    </StackItem>
}

const SoftwareUploadDelete: FunctionComponent<ISoftwareUploadsProps> = ({ storageFolder, path, fileName }) => {
    const tooltipId = useId('tooltip');
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const { deleteSoftwareUpload } = useRemoveSoftwareUpload();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);


    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
        }),
        [labelId, subTextId],
    );

    const dialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: 'Deleting file',
        closeButtonAriaLabel: 'Close',
        subText: 'Do you want to delete this file?',
    };

    return <StackItem align='center'>
        <TooltipHost
            content={formatMessage({ id: 'delete' })}
            id={tooltipId}>
            <IconButton
                iconProps={{ iconName: 'Delete' }}
                styles={{ icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.redDark } }}

                onClick={toggleHideDialog} />
        </TooltipHost>
        <Dialog hidden={hideDialog} dialogContentProps={dialogContentProps}
            modalProps={modalProps}>

            <DialogFooter>
                <PrimaryButton onClick={() => {
                    deleteSoftwareUpload({fileName: fileName, path: path, storageFolder: storageFolder});
                    toggleHideDialog();
                }} text="Yes" />
                <DefaultButton onClick={toggleHideDialog} text="No" />
            </DialogFooter>
        </Dialog>
    </StackItem>
}

const SoftwareUploadInfo: FunctionComponent<ISoftwareUploadInfoProps> = ({
    fileName,
    downloadUrl,
    storageFolder,
    path
}) => {

    const tooltipId = useId('tooltip');
   
    return (
        <>
            <Stack horizontal grow verticalAlign='center' horizontalAlign='space-between'>
                <StackItem styles={{ root: { width: '75%' } }}>
                        <Text>{fileName}</Text>
                </StackItem>
                <Stack horizontal horizontalAlign='space-between'>
                    <SoftwareUploadDownload downloadUrl={downloadUrl} fileName={fileName} />
                    <SoftwareUploadDelete fileName={fileName} path={path} storageFolder={storageFolder} />
                </Stack>
            </Stack>
        </>
    )
};

const SoftwareUploadContent: FunctionComponent = () => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const dropdownStyles = {
        caretDown: {
            color: theme.schemes?.default?.semanticColors.bodyText
        },
        label: { color: theme.schemes?.default?.semanticColors.bodyText },
        dropdown: {
            color: theme.schemes?.default?.semanticColors.bodyText,
            selectors: {
                '.ms-Dropdown-title': { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40, color: (theme.schemes?.default?.semanticColors.bodyText) + ' !important' },
                '&:hover .ms-Dropdown-title': { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40, color: theme.schemes?.default?.semanticColors.bodyText },
                '&:focus .ms-Dropdown-title': { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40, color: theme.schemes?.default?.semanticColors.bodyText },
                '&:active .ms-Dropdown-title': { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40, color: theme.schemes?.default?.semanticColors.bodyText },
                '&:hover': { color: theme.schemes?.default?.semanticColors.bodyText },
                '&:hover .ms-Dropdown-caretDown': { color: theme.schemes?.default?.semanticColors.bodyText },
                '&:active .ms-Dropdown-caretDown': { color: theme.schemes?.default?.semanticColors.bodyText },
                '&:focus .ms-Dropdown-caretDown': { color: theme.schemes?.default?.semanticColors.bodyText },
                '.ms-Dropdown-title.ms-Dropdown-titleIsPlaceHolder': { color: theme.schemes?.default?.semanticColors.disabledBodyText },
            }
        },
        dropdownOptionText: {
            color: theme.schemes?.default?.semanticColors.bodyText
        }
    };
    const { job } = useJobContext();
    const [selectedSoftwareTypeKey, setSelectedSoftwareTypeKey] = useState(job.softwareType == SoftwareType.Supermate ? SoftwareType.Supermate.toString()
        : job.softwareType == SoftwareType.ClassSuper ? SoftwareType.ClassSuper.toString() : SoftwareType.BGL.toString());

    const { setting } = useGetAmazonBucketName();

    const { softwareUploadsData, isSoftwareUploadsLoading, isSoftwareUploadsFetching } = useGetSoftwareUploads({
        jobGuid: job.guid,
        fundGuid: job.fund.guid,
        clientGuid: job.client.guid,
        bucketName: setting?.data.value ?? '',
        softwareType: selectedSoftwareTypeKey
    });

    const [searchString, setSearchString] = useState('');

    const searchHandler = (searchString: any) => {
        setSearchString(searchString);
    };

    const clearHandler = (searchString: any) => {
        setSearchString('');
    };

    const softwareTypeSelectionHandler = (option: any) => {
        setSelectedSoftwareTypeKey(option.key);
    }

    const [columns] = useState<IColumn[]>([
        {
            key: 'name',
            name: formatMessage({ id: 'name' }),
            minWidth: 350,
            fieldName: 'name',
            onRender: (item) =>
                <SoftwareUploadInfo fileName={item.name} downloadUrl={item.downloadUrl} path={item.path} storageFolder={item.storageFolder}/>
        }
    ]);

    if (isSoftwareUploadsLoading) return <Stack horizontalAlign='center'><Spinner /></Stack>;
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 16 }}>
                <Stack style={{ width: '20%'}}>
                    <Dropdown styles={dropdownStyles} onChanged={softwareTypeSelectionHandler} selectedKey={selectedSoftwareTypeKey} dropdownWidth={100} options={[{ key: "ClassSuper", text: "Class Super PDF" }, { key: "Supermate", text: "Supermate" }, { key: "BGL", text: "BGL" }]} />
                </Stack>
                <Search width={100} onSearch={searchHandler} onClear={clearHandler} placeholder={formatMessage({ id: 'searchFiles' })} />
            </Stack>
            {softwareUploadsData?.data && <DataTable
                initialColumns={columns}
                columns={columns}
                items={searchString ? softwareUploadsData.data.filter(x => x.name.toLowerCase().includes(searchString.toLowerCase())) : softwareUploadsData.data}
                selectionMode={SelectionMode.none}
                enableShimmer={isSoftwareUploadsFetching}
                containerHeight='100%'
            />}
        </Stack>
    );
}

export const SoftwareUploads: FunctionComponent = () => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const [isOpenModal, { toggle: toggleOpenModal }] = useBoolean(false);
    

    const linkClickHandler = () => {
        if (!isOpenModal) {
            toggleOpenModal();
        }
    }

    return (
        <>
            <Link style={{ color: theme.schemes?.default?.semanticColors.bodyText }}
                onClick={linkClickHandler}>
                {formatMessage({ id: 'softwareUploads' })}
            </Link>
            <Modal
                title={formatMessage({ id: 'softwareUploads' })}
                isOpen={isOpenModal}
                onDismiss={() => {
                    toggleOpenModal()
                }}>
                <SoftwareUploadContent/>
            </Modal>
        </>);
}