import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from "react-intl";
import {
    DefaultButton,
    DialogType,
    Label,
    mergeStyleSets,
    Spinner,
    SpinnerSize,
    Stack,
    useTheme,
} from '@fluentui/react';
import {Card, ControlledDatePicker, DialogMessage, Modal, SanitizedText} from "../../../../../../../components";
import {
    AuditReportType,
    IAuditReportOptions,
    IDialogConfig,
    IInvoiceInfo,
    IPublishQueriesResult,
    PublishResponseType
} from "../interfaces";
import {useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../../../../constants";
import { Editor, EditorState } from 'draft-js';
import {useBoolean} from "@fluentui/react-hooks";
import {AdministrationInvoiceAddForm} from "./AdministrationInvoiceAddForm";
import {PublishInitialOptions, PublishInitialOptionsDefualts, useDispatchAudit, usePublishInitialAudit} from "../hooks";
import {useJobContext} from "../../../../../JobPortalLayoutPage";
import {useSectionContext} from "../../../../Section";
import moment from 'moment';
import { _GetWorkContextQueryKey, PortalRole, useTenantInfo, useWorkContext } from "../../../../../../../providers";
import {queryClient} from "../../../../../../../utils";
import { convertFromHTML } from 'draft-convert';

type AuditReportOptionsFormProps = {
    onClose: () => void;
    isDraftAuditReportRequired: boolean;
    invoiceEnabled: boolean;
    invoiceInfo?: IInvoiceInfo | null;
    options: AuditReportOptions;
}

type AuditReportOptions = Pick<IAuditReportOptions, 
    'auditReportType' | 'arDate' | 'isArDateVisible' | 'isArDateEnabled' | 'skipArGeneration' |
    'complianceQualificationDataReadOnly' | 'qualificationDataReadOnly' | 'includeComparativeInfo' |
    'qualificationDataOmls' | 'complianceQualificationOmls' | 'qualificationData' | 'complianceQualificationData'>;

type AuditReportOptionsForm = {
    auditReportDate: Date;
    financialReportQualification: EditorState,
    complianceQualification: EditorState
}

export const AuditReportOptionsForm: FunctionComponent<AuditReportOptionsFormProps> = ({
                                                                                           isDraftAuditReportRequired,
                                                                                           options,
                                                                                           onClose,
                                                                                           ...props
                                                                                       }: AuditReportOptionsFormProps) => {
    const theme = useTheme();
    const {formatMessage, formatNumber} = useIntl();

    const DefaultDialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        closeButtonAriaLabel: 'Close',
    };

    const {isBlackLabel, isAuditPortal} = useTenantInfo()

    const {section} = useSectionContext();
    const {jobId} = useJobContext();
    const { isInRoleOrSuperAdministrator } = useWorkContext();

    const {publish, isPublishing} = usePublishInitialAudit();
    const {dispatch, isDispatching} = useDispatchAudit();

    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const [showConfirmDialog, {toggle: toggleConfirmDialog, setFalse: setShowConfirmDialogFalse}] = useBoolean(false);
    const [confirmDialogConfig, setConfirmDialogConfig] = useState<IDialogConfig | null>(null);
    const [showInvoiceModal, {toggle: toggleShowInvoiceModal}] = useBoolean(false);

    const [invoiceEnabled, setInvoiceEnabled] = useState(props.invoiceEnabled);
    const [invoiceInfo, setInvoiceInfo] = useState<IInvoiceInfo | null>(props.invoiceInfo ?? null);

    const [dispatchResult, setDispatchResult] = useState<IPublishQueriesResult | null>(null);
    const [sendEmail, setSendEmail] = useState<boolean>(false);

    useEffect(() => {
        setSendEmail(!!section?.sectionAnswer?.sendEmail);
    }, [section.sectionAnswer]);
    
    const classNames = mergeStyleSets({
        from: {
            '.public-DraftEditor-content': {
                "minHeight": 120,
            },
        },
        editorContainer: {
            border: `1px solid ${theme.semanticColors.disabledBorder}`,
            '.public-DraftStyleDefault-ul': {
                margin: 0
            }
        },
        dialog: {
            '.ms-Dialog-main': {
                minWidth: '500px',
                backgroundColor: 'red',

                '.ms-Dialog-inner': {
                    marginLeft: 16
                }
            }
        }
    });

    const {control, getValues, handleSubmit} = useForm<AuditReportOptionsForm>({
        ...DefaultFormSettings,
        defaultValues: {
            auditReportDate: options.arDate,
            financialReportQualification: !(options.qualificationDataOmls ?? []).length ? EditorState.createEmpty() : EditorState.push(EditorState.createEmpty(), convertFromHTML(`<ul>${(options.qualificationDataOmls ?? []).map(x => '<li>' + x + '</li>')}</ul>`), 'insert-characters'),
            complianceQualification: !(options.complianceQualificationOmls ?? []).length ? EditorState.createEmpty() : EditorState.push(EditorState.createEmpty(), convertFromHTML(`<ul>${(options.complianceQualificationOmls ?? []).map(x => '<li>' + x + '</li>')}</ul>`), 'insert-characters')
        }
    });

    const auditReportName = useMemo<string>(() => {
        switch (options.auditReportType) {
            case AuditReportType.Qualified:
                return formatMessage({id: isDraftAuditReportRequired ? 'generateUnsignedQualifiedReport' : 'generateQualifiedReport'});
            case AuditReportType.Unqualified:
                return formatMessage({id: isDraftAuditReportRequired ? 'generateUnsignedUnqualifiedReport' : 'generateUnqualifiedReport'});
            case AuditReportType.PartAQualified:
                return formatMessage({id: isDraftAuditReportRequired ? 'generateUnsignedPartAQualifiedReport' : 'generatePartAQualifiedReport'});
            case AuditReportType.PartBQualified:
                return formatMessage({id: isDraftAuditReportRequired ? 'generateUnsignedPartBQualifiedReport' : 'generatePartBQualifiedReport'});
            default:
                return '';
        }
    }, [options.auditReportType, isDraftAuditReportRequired])

    const startProcess = () => {
        setIsProcessing(true);
    }

    const endProcess = () => {
        setDispatchResult(null)
        setIsProcessing(false);
        console.debug("[AR_OPTIONS::PROCESS_ENDED]");
        onClose()
    }

    const onOkClick = useCallback(() => {
        startProcess();
    }, []);

    const closeConfirmDialog = useCallback(() => {
        setShowConfirmDialogFalse();
        setConfirmDialogConfig(null);
    }, [])

    const onCancelWarningsClick = useCallback(async () => {
        await queryClient.invalidateQueries('dispatchAuditInfo');
        closeConfirmDialog();
        endProcess()
    }, []);

    const onCancelOverrideWarningsClick = useCallback(() => {
        console.debug("[DIALOG::CONFRIM_OVERRIDE_MESSAGES]");
        closeConfirmDialog();
        endProcess();
    }, []);

    const finalize = async () => {
        await queryClient.invalidateQueries(_GetWorkContextQueryKey);
    }

    useEffect(() => {
        if (isProcessing) {
            dispatch({
                jobId: jobId,
                sendEmail: sendEmail ?? false,

                auditReportType: options.auditReportType,
                includeComparativeInformation: options.includeComparativeInfo,
                aduitReportDate: moment(moment(getValues('auditReportDate')).format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:ss')).utc().unix(),
                qualification: options.qualificationData ?? '',
                complianceQualification: options.complianceQualificationData ?? '',
                skipArGeneration: options.skipArGeneration,
                isDraft: isDraftAuditReportRequired,
                isAuditReportDateVisible: options.isArDateVisible,
                isDraftAuditReportOverride: false // TODO: check value
            }, {
                onSuccess: (result: IPublishQueriesResult) => {
                    setDispatchResult(result);
                },
                onError: () => {
                    endProcess()
                }
            })
        }
    }, [isProcessing, options, sendEmail]);

    useEffect(() => {
        if (!dispatchResult) {
            console.debug("[AR_OPTIONS::DISPATCH_RESULT::NULL]");
            return;
        }

        if (isDraftAuditReportRequired) {
            if (dispatchResult.hasMessages) {
                const messageList = [...(dispatchResult.messages ?? [])];
                setConfirmDialogConfig(prev => ({
                    contentProps: {
                        ...DefaultDialogContentProps,
                        title: formatMessage({id: 'publishPreARAuditQueriesWarning'})
                    },
                    showOk: false,
                    okText: '',
                    onClick: () => {
                    },
                    onCancel: onCancelWarningsClick,
                    content: (
                        <Stack>
                            <Stack.Item>
                                {messageList.length && messageList.map(w => <li style={{ color: theme.schemes?.default?.semanticColors.bodyText }}>{w}</li>)}
                            </Stack.Item>
                        </Stack>
                    )
                }));
                toggleConfirmDialog();
            } else {
                publishInitialAudit({
                    includeInvoice: true,
                    ignoreNonWithholdingQueries: true,
                    isDraftArSentInsert: false,
                    // do not send publish queries email if draft AR is issued
                    sendEmail: false
                })
            }
        } else {
            // viewmodedl_administration.js:694
            console.debug("[AR_OPTIONS::DISPATCH:FINALIZE]");
            finalize();
            const messageList = [dispatchResult.message];
            setConfirmDialogConfig(prev => ({
                contentProps: {
                    ...DefaultDialogContentProps,
                    title: formatMessage({id: 'success'})
                },
                showOk: false,
                okText: '',
                onClick: () => {
                },
                onCancel: onCancelWarningsClick,
                content: (
                    <Stack>
                        <Stack.Item>
                            {messageList.length && messageList.map((w, i) => <li key={'messageKey_' + i} style={{ color: theme.schemes?.default?.semanticColors.bodyText }}>{w}</li>)}
                        </Stack.Item>
                    </Stack>
                )
            }));
            toggleConfirmDialog();
        }
    }, [dispatchResult, isDraftAuditReportRequired]);

    const publishInitialAudit = useCallback((options?: Partial<PublishInitialOptions>) => {
        publish({
            ...PublishInitialOptionsDefualts,
            sendEmail: sendEmail ?? false,
            ...(options ?? {}),
            jobId: jobId,
            isDraftArSentInsert: options?.isDraftArSentInsert ?? false
        }, {
            onSuccess: (result: IPublishQueriesResult) => {
                if (result.type === PublishResponseType.Invoice && !result.hasMessages) {
                    if (result.invoiceInfo && !result.invoiceInfo.useStandardWorkflow) {
                        if (isBlackLabel && result.invoiceInfo.isFeeCalculated) {
                            setInvoiceInfo(result.invoiceInfo);
                            toggleShowInvoiceModal()
                        }
                    }
                } else if (result.type === PublishResponseType.Ok && !result.hasMessages) {
                    if (result.invoiceInfo && !result.invoiceInfo.useStandardWorkflow && !result.message) {
                        if (isBlackLabel && result.invoiceInfo.isFeeCalculated) {
                            setInvoiceInfo(result.invoiceInfo);
                            toggleShowInvoiceModal()
                            return;
                        }
                        console.debug("[AR_PUBLISH::SUCCESS] Non conditions are met... Continue...");
                    }

                    console.debug("[AR_PUBLISH::SUCCESS]");
                    finalize();
                    const messageList = [result.message];
                    setConfirmDialogConfig(prev => ({
                        contentProps: {
                            ...DefaultDialogContentProps,
                            title: formatMessage({id: 'success'})
                        },
                        showOk: false,
                        okText: '',
                        onClick: () => {
                        },
                        onCancel: onCancelWarningsClick,
                        content: (
                            <Stack>
                                <Stack.Item>
                                    {messageList.length && messageList.map((w, i) => <li
                                        key={'messageKey_' + i}>{w}</li>)}
                                </Stack.Item>
                            </Stack>
                        )
                    }));
                    toggleConfirmDialog();
                } else {
                    if (result.ignoreNonWithholdingQueries && isInRoleOrSuperAdministrator(PortalRole.Administrator)) {
                        const messageList = [
                            ...(result.messages ?? [])
                        ]

                        setConfirmDialogConfig(prev => ({
                            contentProps: {
                                ...DefaultDialogContentProps,
                                title: formatMessage({id: 'warning'})
                            },
                            showOk: true,
                            okText: formatMessage({id: 'continue'}),
                            onClick: () => publishInitialAudit({
                                ignoreNonWithholdingQueries: true,
                                isDraftArSentInsert: true,
                                sendEmail: true
                            }),
                            onCancel: onCancelOverrideWarningsClick,
                            content: (
                                <Stack>
                                    <Stack.Item>
                                        {messageList.length && messageList.map((w, i) => <li
                                            key={'messageKey_' + i}>{w}</li>)}
                                    </Stack.Item>
                                    <Stack.Item>
                                        {formatMessage({id: 'publishOverrideMesasge'})}
                                    </Stack.Item>
                                </Stack>
                            )
                        }));
                        toggleConfirmDialog();

                    } else {
                        const messageList = [...(result.messages ?? [])];
                        setConfirmDialogConfig(prev => ({
                            contentProps: {
                                ...DefaultDialogContentProps,
                                title: formatMessage({id: 'publishPreARAuditQueriesWarning'})
                            },
                            showOk: false,
                            okText: '',
                            onClick: () => {
                            },
                            onCancel: onCancelWarningsClick,
                            content: (
                                <Stack>
                                    <Stack.Item>
                                        {messageList.length && messageList.map((w, i) => <li
                                            key={'messageKey_' + i}>{w}</li>)}
                                    </Stack.Item>
                                </Stack>
                            )
                        }));
                        toggleConfirmDialog();
                    }
                }
            },
            onError: () => endProcess()
        })
    }, [isBlackLabel, sendEmail]);

    const onInvoiceClick = () => {
        console.debug("[ON_INVOICE_CLICK]", invoiceEnabled, invoiceInfo);
        if (invoiceEnabled && invoiceInfo) {
            
            if (!invoiceInfo.hasErrorMessage1 && !invoiceInfo.hasErrorMessage2) {
                toggleShowInvoiceModal();
            } else {
                const messageList = [invoiceInfo.errorMessage1 ?? null, invoiceInfo.errorMessage2 ?? null].filter(x => !!x);
                const hasOverrideMessage = invoiceInfo.hasErrorMessage2;
                
                setConfirmDialogConfig(prev => ({
                    contentProps: {
                        ...DefaultDialogContentProps,
                        title: formatMessage({id: 'warning'})
                    },
                    showOk: hasOverrideMessage,
                    okText: formatMessage({id: hasOverrideMessage ? 'override' : 'ok'}),
                    onClick: () => {
                        closeConfirmDialog()
                        toggleShowInvoiceModal()
                    },
                    onCancel: () => {
                        setInvoiceEnabled(prev => false);
                        closeConfirmDialog()
                    },
                    content: (
                        <Stack>
                            <Stack.Item>
                                {messageList.length && messageList.map((w, i) => <li
                                    key={'messageKey_' + i}>{w}</li>)}
                            </Stack.Item>
                        </Stack>
                    )
                }))
                
                toggleConfirmDialog();
            }
        }
    }

    const onInvoiceCreated = () => {
        toggleShowInvoiceModal()
        setInvoiceEnabled(false)
    }

    const onInvoiceCreationFailed = () => {
        toggleShowInvoiceModal()
    }

    const onCloseClick = () => {
        onClose()
    }

    return (
        <>
            <Stack tokens={{childrenGap: 16}}>
                <Stack.Item>
                    <Card styles={{root: {padding: '8px 16px 20px 16px'}}}>
                        <Stack tokens={{childrenGap: 16}} wrap>
                            <Stack.Item>
                                <Stack>
                                    <Stack.Item>
                                        <SanitizedText data={auditReportName} format={'Bold'}></SanitizedText>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack className={classNames.from} tokens={{childrenGap: 16}}>
                                    {options.isArDateVisible && <Stack.Item styles={{root: {width: '25%'}}}>
                                        <ControlledDatePicker
                                            control={control}
                                            disabled={!options.isArDateEnabled}
                                            name='auditReportDate'
                                            label={formatMessage({id: 'auditReportDate'})}
                                        />
                                    </Stack.Item>}

                                    <Stack.Item>
                                        <Stack tokens={{childrenGap: 8}}>
                                            <Stack.Item>
                                                <Label styles={{ root: { color: theme.schemes?.default?.semanticColors.bodyText } }}>
                                                    {formatMessage({id: 'financialReportQualification'})}
                                                </Label>
                                            </Stack.Item>
                                            <Stack.Item className={classNames.editorContainer}>
                                                <Editor editorState={getValues('financialReportQualification')}
                                                        onChange={() => {
                                                        }}
                                                        readOnly={true}>
                                                </Editor>
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>


                                    <Stack.Item>
                                        <Stack tokens={{childrenGap: 8}}>
                                            <Stack.Item>
                                                <Label styles={{ root: { color: theme.schemes?.default?.semanticColors.bodyText } }}>
                                                    {formatMessage({id: 'complianceQualification'})}
                                                </Label>
                                            </Stack.Item>
                                            <Stack.Item className={classNames.editorContainer}>
                                                <Editor editorState={getValues('complianceQualification')}
                                                        onChange={() => {
                                                        }}
                                                        readOnly={true}>
                                                </Editor>
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>

                                    {options.skipArGeneration && <Stack.Item>
                                        <SanitizedText color={theme.semanticColors.errorText}
                                            data={formatMessage({id: 'skipArGenerationMessage'})}></SanitizedText>
                                    </Stack.Item>}
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Card>
                </Stack.Item>

                <Stack.Item>
                    <Stack horizontal tokens={{childrenGap: 16}} horizontalAlign={'end'}>
                        <Stack.Item>
                            <DefaultButton styles={{ root: { color: theme.schemes?.default?.semanticColors.bodyText } }}
                                           disabled={!invoiceEnabled} 
                                           text={formatMessage({id: 'generateInvoice'})}
                                           onClick={onInvoiceClick}/>
                        </Stack.Item>
                        <Stack.Item>
                            <DefaultButton styles={{ root: { color: theme.schemes?.default?.semanticColors.bodyText } }}
                                           disabled={isProcessing} onClick={onOkClick}>
                                           {isProcessing
                                                ? <Spinner size={SpinnerSize.small}/>
                                                : formatMessage({id: 'ok'})
                                           }
                            </DefaultButton>
                        </Stack.Item>
                        <Stack.Item>
                            <DefaultButton styles={{ root: { color: theme.schemes?.default?.semanticColors.bodyText } }}
                                disabled={false} text={formatMessage({id: 'close'})} onClick={onCloseClick}/>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>

            {confirmDialogConfig && <Stack className={classNames.dialog}>
                <DialogMessage onClick={confirmDialogConfig.onClick} onDismis={confirmDialogConfig.onCancel}
                               dialogContentProps={confirmDialogConfig.contentProps}
                               hidden={!showConfirmDialog}
                               showOk={confirmDialogConfig.showOk}
                               okText={confirmDialogConfig.okText}
                               cancelText={formatMessage({id: 'close'})}>
                    {confirmDialogConfig.content}
                </DialogMessage>
            </Stack>}

            {invoiceEnabled && invoiceInfo && <Modal isOpen={showInvoiceModal} onDismiss={toggleShowInvoiceModal}
                                                     title={formatMessage({id: 'generateInvoice'})}>
                <AdministrationInvoiceAddForm data={invoiceInfo}
                                              onClose={() => {
                                                  toggleShowInvoiceModal()
                                                  endProcess()
                                              }}
                                              onCreated={onInvoiceCreated}
                                              onError={onInvoiceCreationFailed}
                                              auditReportDate={getValues('auditReportDate')}>
                </AdministrationInvoiceAddForm>
            </Modal>}
        </>
    );
};