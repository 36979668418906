import React, { FunctionComponent, useCallback, useMemo } from 'react';
import {
    FontSizes,
    HoverCard,
    HoverCardType,
    IButtonStyles,
    IconButton,
    IHoverCard,
    IIconProps,
    IPlainCardProps,
    List,
    mergeStyleSets, Text,
    useTheme
} from "@fluentui/react";
import { IValidationItem, ValidationState } from '../../../interfaces';
import { SanitizedText } from '../../../../../components';
import { useJobRedirects } from '../shared';
import { ValidationType } from '../../../interfaces/IReportsInfo';
import { useIntl } from "react-intl";

export type ValidationIconName = 'CircleFill' | 'CheckMark';

interface IValidationIconProps {
    state: ValidationState,
    items?: IValidationItem[],
    type: ValidationType
}

interface IValidationIconState {
    isPositive: boolean,
    isManuallyPositive?: boolean,
    iconName: ValidationIconName,
    style?: React.CSSProperties | undefined
}

export const ValidationIcon: FunctionComponent<IValidationIconProps> = (props: IValidationIconProps) => {
    const { formatMessage } = useIntl();
    const {navigateToTableRow} = useJobRedirects()
    const validationHoverRef = React.useRef<IHoverCard>(null);
    const validationHoverPriorYearRef = React.useRef<IHoverCard>(null);
    
    const iconStates: { [key: number]: IValidationIconState } = {
        0: {
            iconName: 'CircleFill',
            isPositive: false
        },
        100: {
            iconName: 'CheckMark',
            isPositive: true,
            style: {
                fontSize: FontSizes.size20
            }
        },
        101: {
            iconName: 'CheckMark',
            isPositive: true,
            isManuallyPositive: true,
            style: {
                fontSize: FontSizes.size20
            }
        },
    }
    
    const prepareValidationItems = useCallback((items: IValidationItem[]): IValidationItem[] => {
        return items.reduce((acc: IValidationItem[], i: IValidationItem): IValidationItem[] => {
            i.validationTitle = (i.validationTitle || '');
            acc.push(i);
            return acc;
        }, [])
    }, []);
    
    const theme = useTheme();
    const state = useMemo<IValidationIconState>(() => iconStates[props.state], [props]);
    const items = useMemo<IValidationItem[]>(() => prepareValidationItems(props.items || []), [props])
    
    const iconProps: IIconProps = {
        iconName: state.iconName,
        style: {
            fontSize: FontSizes.size14,
            ...state.style
        }
    }

    const styles: IButtonStyles = {
        root: {
            color: state.isPositive ? state.isManuallyPositive ? theme.palette.themeTertiary : theme.palette.themePrimary : theme.palette.red,
        },
        rootHovered: {
            color: state.isPositive ?  state.isManuallyPositive ? theme.palette.themePrimary : theme.palette.themeDarker : theme.palette.redDark
        },
        rootPressed: {
            color: state.isPositive ?  state.isManuallyPositive ? theme.palette.themeTertiary : theme.palette.themePrimary : theme.palette.redDark,
        }
    }

    const validationHoverStyles = mergeStyleSets({
        plainCard: {
            padding: '1em',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        item: {
            padding: '2px 5px',
            border: '1px solid transparent',
            selectors: {
                ':hover': {
                    cursor: 'pointer',
                    borderColor: theme.palette.themePrimary
                }
            }
        }
    });

    const onValidationItemClicked = (item?: IValidationItem): void => {
        console.debug("[CLICK]", item);
        if (item) {
            navigateToTableRow({tabId: item.tabId, sectionId: item.sectionId, itemId: item.itemId})
        }
    };

    const onRenderCell = (item?: IValidationItem, index?: number, isScrolling?: boolean): JSX.Element => {
        return (
            <div className={validationHoverStyles.item} onClick={() => onValidationItemClicked(item)}>
                <SanitizedText theme={theme.schemes?.default} data={(index || 0) + 1 + '. ' + item?.validationTitle || ''} />
            </div>
        );
    };

    const onRenderValidationHover = (): JSX.Element => {
        return (
            <div className={validationHoverStyles.plainCard}>
                <List items={items} onRenderCell={onRenderCell}></List>
            </div>
        );
    };

    const plainCardProps: IPlainCardProps = {
        onRenderPlainCard: onRenderValidationHover,
    };
    
    const priorYearCardProps: IPlainCardProps = {
        onRenderPlainCard: (): JSX.Element => {
            return (
                <div className={validationHoverStyles.plainCard}>
                    <SanitizedText data={formatMessage({ id: 'priorYear' })} minWidth={'50'} />
                </div>
            )
        }
    }
    
    if (items?.length) {
        return (
            <>
                {items.length === 1
                    ? <IconButton iconProps={iconProps} styles={styles} onClick={() => onValidationItemClicked(items[0])}/>
                    : <HoverCard type={HoverCardType.plain} 
                                 instantOpenOnClick={true}
                                 cardOpenDelay={1000}
                                 componentRef={validationHoverRef} 
                                 plainCardProps={plainCardProps}>
                        <IconButton iconProps={iconProps} styles={styles}/>
                    </HoverCard>
                }
            </>
        )
    } else {
        if (props.type === ValidationType.PriorYear) {
            return (
                <HoverCard type={HoverCardType.plain}
                           instantOpenOnClick={true}
                           cardOpenDelay={1000}
                           componentRef={validationHoverPriorYearRef}
                           plainCardProps={priorYearCardProps}>
                    <IconButton iconProps={iconProps} styles={styles}/>
                </HoverCard>
            )
        }
        
        return (
            <>
                <IconButton iconProps={iconProps} styles={styles}/>
            </>
        ) 
    }
}