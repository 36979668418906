import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { OtherClientInvoice, useOtherClientsContext } from '../interfaces';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    DefaultButton,
    IColumn,
    IconButton,
    PrimaryButton,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    useTheme,
} from '@fluentui/react';
import {
    ControlledDatePicker,
    ControlledDropdown,
    ControlledNumberField,
    ControlledTextField,
    DataTable
} from "../../../../../components";
import { useFieldArray, useForm } from 'react-hook-form';
import { DefaultFormSettings } from '../../../../../constants';
import { InvoiceParagraphType, InvoiceStatusType } from '../../Invoices/enums';
import { _ParagraphDropdownOptions, calculateTotal } from '../../Invoices';
import { useCreateInvoice } from '../../Invoices/hooks';
import { useInvoicePortalContext } from '../../../InvoicePortalLayoutPage';
import { BillingEntityType } from '../../../enums';
import { NewInvoice } from '../../Invoices/interfaces';
import { useGetFees } from '../../../hooks';

type OtherClientsDataTableProps = {};

type OtherClientsJobsFormProps = {
    items: SimplifiedOtherClientInvoice[];
    paragraph: InvoiceParagraphType;
    invoiceDate: Date;
};

type SimplifiedOtherClientInvoice = Pick<OtherClientInvoice, 'invoiceJobs' | 'amount' | 'client' | 'jobId' | 'gst'> & {
    extraAmount: number;
    total: number;
    totalText: string;
};

export const OtherClientsDataTable: FunctionComponent<OtherClientsDataTableProps> = ({ ...props }: OtherClientsDataTableProps) => {
    const { formatMessage, formatNumber } = useIntl();
    const theme = useTheme();

    const { clientIdentifier } = useInvoicePortalContext();

    const [selectedJobIds, setSelectedJobIds] = useState<number[]>([]);

    const { selectedItems, setSelectedItems } = useOtherClientsContext();

    const { isCreating, create } = useCreateInvoice(clientIdentifier);
    const { fees, isFeesLoading, refetchFees } = useGetFees(clientIdentifier, selectedJobIds);

    const [totalAmount, setTotalAmount] = useState<number>(0);

    const { control, watch, setValue, getValues, handleSubmit, formState, reset } = useForm<OtherClientsJobsFormProps>({
        ...DefaultFormSettings,
        defaultValues: {
            items: [...selectedItems],
            paragraph: InvoiceParagraphType.DD,
        },
    });

    const { fields, append, update, remove } = useFieldArray({
        control,
        name: 'items',
    });

    const onDeleteClick = (item: SimplifiedOtherClientInvoice, index: number) => {
        remove(index);
    };

    const onAmountChanged = (item: SimplifiedOtherClientInvoice, index: number, newValue?: string) => {
        const extraAmount = +getValues(`items.${index}.extraAmount`) ?? 0;
        updateRowTotal(calculateTotal(+(newValue ?? 0), extraAmount), index);
        updateTotalAmount()
    };

    const onExtraAmountChanged = (item: SimplifiedOtherClientInvoice, index: number, newValue?: string) => {
        const amount = +getValues(`items.${index}.amount`) ?? 0;
        updateRowTotal(calculateTotal(+(newValue ?? 0), amount), index);
        updateTotalAmount()
    };
    
    const updateRowTotal = (value: number, index: number) => {
        setValue(`items.${index}.total`, value);
        setValue(`items.${index}.totalText`, formatNumber(value, { style: 'currency', currency: 'USD' }))
    }

    const updateTotalAmount = useCallback(() => {
        const sum = getValues('items').map(i => +i.total).reduce((acc, i) => {
            acc = acc + (+i || 0);
            return acc;
        }, 0)
        setTotalAmount(sum);
    }, []);

    const onSubmit = (formItem: OtherClientsJobsFormProps) => {
        create(
            {
                requests: [
                    ...formItem.items.map(
                        (i, index) =>
                            ({
                                billingEntityType: BillingEntityType.Client,
                                paragraph: formItem.paragraph,
                                gst: i.gst,
                                amount: i.amount,
                                extraAmounts: [{ amount: i.extraAmount, paragraph: 1 }],
                                jobId: i.invoiceJobs[0].jobId,
                                creationDate: new Date(formItem.invoiceDate),
                            } as NewInvoice)
                    ),
                ],
                applyCreditNotes: false,
                creationDate: formItem.invoiceDate,
                paragraph: formItem.paragraph,
            },
            {
                onSuccess: (resp: any) => {
                    reset();
                },
            }
        );
    };

    const onUpdateFeesClick = () => {
        setSelectedJobIds((prev) => (selectedItems ?? []).map((s) => s.jobId));
    };

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'clientName',
                name: formatMessage({ id: 'client' }),
                fieldName: 'clientName',
                minWidth: 100,
                maxWidth: 120,
                isMultiline: true,
                onRender: (item: SimplifiedOtherClientInvoice, index?: number) => <Text>{item.client.name}</Text>,
            },
            {
                key: 'jobName',
                name: formatMessage({ id: 'job' }),
                fieldName: 'jobName',
                minWidth: 100,
                maxWidth: 120,
                isMultiline: true,
                onRender: (item: SimplifiedOtherClientInvoice, index?: number) => <Text>{item.invoiceJobs[0]?.jobName}</Text>,
            },
            {
                key: 'fundName',
                name: formatMessage({ id: 'fund' }),
                fieldName: 'fundName',
                minWidth: 190,
                maxWidth: 500,
                isMultiline: true,
                onRender: (item: SimplifiedOtherClientInvoice, index?: number) => <Text>{item.invoiceJobs[0]?.fundName}</Text>,
            },
            {
                key: 'amount',
                name: formatMessage({ id: 'amount' }),
                fieldName: 'amount',
                minWidth: 140,
                maxWidth: 140,
                onRender: (item: SimplifiedOtherClientInvoice, index?: number) => (
                    <ControlledNumberField
                        name={`items.${index!}.amount`}
                        control={control}
                        onChanged={(newValue?: string) => onAmountChanged(item, index!, newValue)}
                        rules={{ required: formatMessage({ id: 'amountIsRequired' }) }}
                    />
                ),
            },
            {
                key: 'extraAmount',
                name: formatMessage({ id: 'extraAmount' }),
                fieldName: 'extraAmount',
                minWidth: 140,
                maxWidth: 140,
                onRender: (item: SimplifiedOtherClientInvoice, index?: number) => (
                    <ControlledNumberField
                        name={`items.${index!}.extraAmount`}
                        control={control}
                        onChanged={(newValue?: string) => onExtraAmountChanged(item, index!, newValue)}
                    />
                ),
            },
            {
                key: 'total',
                name: formatMessage({ id: 'total' }),
                fieldName: 'total',
                minWidth: 140,
                maxWidth: 140,
                isMultiline: true,
                onRender: (item: SimplifiedOtherClientInvoice, index?: number) => (
                    <ControlledTextField
                        name={`items.${index!}.totalText`}
                        control={control}
                        readonly
                        styles={{
                            fieldGroup: { 
                                border: 0,
                                ':hover': { border: 0 },
                                ':focus': { border: 0 }
                            }
                        }}
                    />
                ),
            },
            {
                key: 'actions',
                name: '',
                fieldName: 'actions',
                minWidth: 52,
                maxWidth: 52,
                onRender: (item: SimplifiedOtherClientInvoice, index?: number) => (
                    <Stack horizontal>
                        <Stack.Item>
                            <IconButton
                                iconProps={{ iconName: 'Delete' }}
                                styles={{ icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.redDark } }}

                                onClick={() => {
                                    onDeleteClick(item, index!);
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                ),
            },
        ],
        [formatMessage]
    );

    useEffect(() => {
        setSelectedItems((prev) => [...(fields as any[])]);
    }, [fields.length]);

    useEffect(() => {
        setValue('items', selectedItems as any[]);
    }, [selectedItems]);

    useEffect(() => {
        if (!!fees?.length && !!selectedItems.length) {
            for (let i = 0; i < selectedItems.length; i++) {
                const fee = fees.find((x) => x.jobId === selectedItems[i].jobId);
                if (!fee) continue;
                setValue(`items.${i}.amount`, fee.feeQuote);
                setValue(`items.${i}.extraAmount`, fee.additionalCharges);
                setValue(`items.${i}.total`, fee.feeQuote + fee.additionalCharges);
            }
        }
    }, [fees]);

    return (
        <>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    <DataTable
                        initialColumns={columns}
                        items={fields}
                        hideIfEmpty={false}
                        selectionMode={SelectionMode.none}
                       
                        disableDragDrop={true}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal horizontalAlign='end' styles={{ root: { paddingRight: 16 } }}>
                        <FormattedMessage
                            id={'total{value}'}
                            values={{ value: formatNumber(totalAmount, { style: 'currency', currency: 'USD' }) }}
                        />
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 16 }}>
                        <Stack.Item>
                            <ControlledDatePicker
                                control={control}
                                name='invoiceDate'
                                rules={{ required: formatMessage({ id: 'creationDateRequired' }) }}
                                label={formatMessage({ id: 'creationDate' })}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <ControlledDropdown
                                name='paragraph'
                                control={control}
                                label={formatMessage({ id: 'invoiceParagraph' })}
                                options={_ParagraphDropdownOptions}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>

                <Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 16 }}>
                        <Stack.Item>
                            <PrimaryButton
                                disabled={!formState.isDirty || !formState.isValid || isCreating}
                                onClick={handleSubmit(onSubmit)}>
                                {isCreating ? <Spinner size={SpinnerSize.small}></Spinner> : formatMessage({ id: 'create' })}
                            </PrimaryButton>
                        </Stack.Item>
                        <Stack.Item>
                            <PrimaryButton disabled={!selectedItems.length} onClick={onUpdateFeesClick}>
                                {isFeesLoading ? <Spinner size={SpinnerSize.small}></Spinner> : formatMessage({ id: 'updateFees' })}
                            </PrimaryButton>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
};
