import { Callout, DirectionalHint, mergeStyleSets, Spinner, Stack, StackItem, useTheme } from "@fluentui/react";
import { Target } from "@fluentui/react-hooks";
import { FunctionComponent } from "react";
import { SanitizedText } from "../../../../../../components";
import { useAttachmentsContext } from "./AttachmentsContext";

interface IAttachmentsPreviewProps {
    target?: Target;
}

export const AttachmentsPreview: FunctionComponent<IAttachmentsPreviewProps> = ({
    target,
}) => {
    const theme = useTheme();
    const attachmentsContext = useAttachmentsContext();

    const styles = mergeStyleSets({
        header: {
        },
        headerText: {
            fontWeight: "bold",
        },
        callout: {
            minWidth: 350,
            maxWidth: 500,
            padding: '20px 24px',
        },
        item: {
            wordWrap: 'break-word'
        }
    });

    return (
        <>
            <Callout
                className={styles.callout}
                target={`#${target}`}
                onDismiss={() => {}}
                gapSpace={3}
                role='alert'
                directionalHint={DirectionalHint.rightCenter}>
                {attachmentsContext.isAttachmentsLoading ? (
                    <Spinner />
                ) : (
                    <Stack grow tokens={{ childrenGap: 16 }}>
                        <StackItem>
                            <ul>
                                {attachmentsContext.attachments?.map((x) => {
                                    return (
                                        <li key={x.id} className={styles.item}>
                                            <SanitizedText color={theme.schemes?.default?.semanticColors.bodyText} 
                                                           data={x.fileName} />
                                        </li>
                                    )
                                })}
                            </ul>
                        </StackItem>
                    </Stack>
                )}
            </Callout>
        </>
    );
};