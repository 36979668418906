import {useProcedureContext} from "../../../ProceduresContent";
import {ITableColumn, Loading, SanitizedText} from "../../../../../../components";
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import {IAuditQualificationItem} from "./interfaces";
import {AuditQualificationPanelTemplate} from "./AuditQualificationPanelTemplate";
import {List, mergeStyleSets, normalize, Stack, useTheme} from "@fluentui/react";
import {ProceduresDataTable} from "../../../ProceduresDataTable";
import {JobComment} from "../../../JobComment";
import {JobOmls, useSectionContext} from "pages/JobPortal/components";
import {TableType} from "../../../../../../enums";
import {IJobOmlItem} from "../../../OmlComponent/interfaces/IJobOmlItem";
import { useTabContext } from "../../../../JobPortalPage";
import { queryClient } from "../../../../../../utils";
import { useJobContext } from "../../../../JobPortalLayoutPage";

export const _QualificationProcedureRef = 'Is there a qualification?';

export const AuditQualificationTemplate = forwardRef(({}, ref) => {
    const {items, isLoading} = useProcedureContext();
    const {job} = useJobContext();
    const {refresh, enableWarningMessage, setApprovingDisable, section} = useSectionContext();
    const {sections, refreshSectionById} = useTabContext()
    const theme = useTheme();
    const styles = mergeStyleSets({
        container: {
            overflow: 'auto',
            maxHeight: 400,
            '.ms-List-cell': {
                minHeight: 'fit-content',
                marginBottom: 6
            },
            '.ms-List-page': {
                borderBottom: 'none'
            }
        },
        itemContent: [
            theme.fonts.medium,
            normalize,
            {
                position: 'relative',
                boxSizing: 'border-box',
                display: 'block',
                borderRadius: '0px 2px 2px 0px',
                borderLeft: '2px solid ' + theme.palette.themeTertiary,
                paddingLeft: 16,
                whiteSpace: 'normal', 
                wordWrap: 'break-word',
            },
        ],
    });

    const [tableItems, setTableItems] = useState<IAuditQualificationItem[]>(items.filter(x => x.isGridItem));
    const [panelItems, setPanelItems] = useState<IAuditQualificationItem[]>(items.filter(x => !x.isGridItem));
    
    const [isQualificationAnswerNegative, setIsQualificationAnswerNegative] = useState<boolean>(panelItems.find(x => x.title === _QualificationProcedureRef)?.answerText === 'No');

    const hasGridItemsOmls: boolean = useMemo(() => (tableItems ?? []).some(x => x.hasOml), [tableItems]);
    
    const renderListItem = (item?: string, index?: number, isScrollabel?: boolean) => {
        return (
            <div key={`audit-qualification-${index}`} className={styles.itemContent}>
                {item}
            </div>
        );
    };
    const columns: ITableColumn[] = useMemo(() => {
        return [
            {
                key: 'actions',
                name: '',
                fieldName: '',
                onRender: (item: IAuditQualificationItem) => {
                    return (
                        <Stack horizontal horizontalAlign='center'>
                            {item.showComment && <JobComment itemId={item.id} hasComments={item.hasComments} />}
                            {item.showOML && (
                                <JobOmls
                                    tableType={TableType.AuditQualification}
                                    itemId={item.id}
                                    onAfterSubmit={(_) => {
                                        refresh?.();
                                    }}
                                    showRaisedItems={false}
                                    showNonWitholdingColumn={false}
                                    disabled={isQualificationAnswerNegative}
                                    formDisabled={isQualificationAnswerNegative || !!section?.sectionAnswer?.partnerApproval}
                                    hasOmls={item.hasOml}
                                />
                            )}
                        </Stack>
                    );
                },
                minWidth: 70,
                maxWidth: 70,
            },
            {
                key: 'title',
                name: '',
                fieldName: 'title',
                minWidth: 150,
	            maxWidth: 180,
	            onRender: (item: IAuditQualificationItem) => {
		            return (
			            <SanitizedText data={item.title} format={'Bold'}></SanitizedText>
		            )
	            }
            },
            {
                key: 'answerText',
                name: '',
                fieldName: 'answerText',
                minWidth: 150,
                onRender: (item: IAuditQualificationItem) => {
                    return (
                        <Stack grow className={styles.container}>
                            <List
                                onShouldVirtualize={() => false}
                                items={item.omlItems || []}
                                ignoreScrollingState
                                onRenderCell={renderListItem}
                            />
                        </Stack>
                    );
                },
            },
        ];
    }, [section?.sectionAnswer?.partnerApproval, isQualificationAnswerNegative]);

    const updateSectionAnswers = (arr: any[]) => {
        const hasAnswers = arr.every(x => !!x.answerText);
        setApprovingDisable(!hasAnswers)
        enableWarningMessage(!hasAnswers, true);
    }
    
    const handleAnswer = useCallback((index, answer) => {
        panelItems[index].answerText = answer;
        updateSectionAnswers(panelItems);
        
        if (panelItems[index].title === _QualificationProcedureRef) {
            setIsQualificationAnswerNegative(answer === 'No');
        }
    }, [])

    useEffect(() => {
        setTableItems(items.filter(x => x.isGridItem));
        const pancelItems = items.filter(x => !x.isGridItem);
        setPanelItems(pancelItems);
        
        const timeout = setTimeout(() => {
            // console.debug("[AQ::EFFECT:TIMEOUT]");
            updateSectionAnswers(pancelItems);
        }, 300)
        
        
        return () => {
            clearTimeout(timeout);
        }
    }, [items]);

    const [isPanelItemDisabled, setIsPanelItemDisabled] = useState<boolean>(false);
    useEffect(() => {
        // console.debug("[ACR::EFFECT]", section.sectionAnswer);
        setIsPanelItemDisabled(!!section.sectionAnswer?.managerApproval && !!section?.sectionAnswer.partnerApproval)
    }, [section.sectionAnswer]);

    useEffect(() => {
        console.debug("[TAB SECCTIONS]", sections, job);
        const contravensionSection = (sections ?? []).find(x => x.reference === 'Contravention Report');
        if (contravensionSection) {
            refreshSectionById(contravensionSection.id)
        }
    }, [section.sectionAnswer?.partnerApproval]);

    if (isLoading)
        return <Loading/>;

    return (
        <>
            <Stack horizontal tokens={{childrenGap: 16}} grow horizontalAlign={"space-between"}>
                {panelItems.map((value, index) => {
                    if (value.title === _QualificationProcedureRef) {
                        return <AuditQualificationPanelTemplate key={`qualification-panel-${index}`}
                                                                item={value}
                                                                ref={ref}
                                                                disabled={isPanelItemDisabled}
                                                                onAnswer={(answer) => handleAnswer(index, answer)}
                                                                gridHasOmls={hasGridItemsOmls}
                        />;
                    } else {
                        return <AuditQualificationPanelTemplate key={`qualification-panel-${index}`}
                                                                item={value}
                                                                disabled={isPanelItemDisabled}
                                                                onAnswer={(answer) => handleAnswer(index, answer)}
                                                                gridHasOmls={hasGridItemsOmls}
                        />;
                    }
                })}
            </Stack>
            <ProceduresDataTable items={tableItems}
                                 columns={columns}
                                 isHeaderVisible={false}/>
        </>
    );
});