import { AnimationStyles, Icon, Stack, useTheme } from '@fluentui/react';
import { SanitizedText } from 'components';
import { isValidElement, useEffect, useMemo, useState } from 'react';

type ImportStatusPanelProps = {
    success: boolean;
    message?: string | React.ReactNode;
    autoHide?: boolean;
    autoHideProps?: AutoHideProps;
};

type AutoHideProps = {
    after?: number;
    destroyOnHide?: boolean;
    hideOnError?: boolean;
};

const defaultAutoHideProps: AutoHideProps = {
    after: 10000,
    destroyOnHide: true,
    hideOnError: false,
};

export const ImportStatusPanel = ({ success, message, autoHide, autoHideProps }: ImportStatusPanelProps) => {
    const theme = useTheme();
    const [show, setShow] = useState<boolean>(true);
    const [destroy, setDestroy] = useState<boolean>(false);
    const hideProps = {
        ...defaultAutoHideProps,
        ...autoHideProps,
    };

    const animation = useMemo(() => (show ? AnimationStyles.fadeIn100 : AnimationStyles.fadeOut100), [show]);

    useEffect(() => {
        if (!autoHide || (!success && !hideProps.hideOnError)) return;

        const timer = setTimeout(() => setShow(false), hideProps.after); // Adjust the time to start out animation

        return () => clearTimeout(timer);
    }, [success, hideProps.hideOnError, autoHide]);

    useEffect(() => {
        if (show) return;

        const removeTimer = setTimeout(() => setDestroy(true), 100); // Adjust the time to match the animation duration
        return () => clearTimeout(removeTimer);
    }, [show]);

    if (destroy) return null;

    return (
        <Stack
            horizontal
            horizontalAlign='center'
            tokens={{ childrenGap: 16 }}
            styles={{
                root: {
                    padding: theme.spacing.m,
                    marginTop: theme.spacing.m,
                    borderRadius: theme.effects.roundedCorner2,
                    background: success ? theme.semanticColors.successBackground : theme.semanticColors.errorBackground,
                    ...animation,
                },
            }}
            onTransitionEnd={() => console.log('onTransitionEnd')}
            onAnimationEndCapture={() => console.log('onAnimationEndCapture')}
            onAnimationEnd={() => console.log('onAnimationEnd')}>
            <Stack.Item>
                <Icon
                    iconName={success ? 'Completed' : 'Error'}
                    style={{
                        fontSize: theme.fonts.xLarge.fontSize,
                        color: success ? theme.semanticColors.successIcon : theme.semanticColors.errorIcon,
                    }}
                />
            </Stack.Item>
            <Stack.Item style={{ color: theme.schemes?.default?.semanticColors.bodyText }}>
                {isValidElement(message) && message}
                {!isValidElement(message) && (
                    <SanitizedText
                        variant='mediumPlus'
                        data={message as string}
                    />
                )}
            </Stack.Item>
        </Stack>
    );
};
