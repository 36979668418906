import { Stack, useTheme, Text, PrimaryButton, Dropdown, SpinnerSize, Spinner, Icon } from '@fluentui/react';
import { FunctionComponent, useEffect, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { SoftwareType } from '../../../../../../enums';
import { useGetAmazonBucketName } from '../../hooks/useGetAmazonBucketName';
import { useGetOauthInfo } from '../../hooks/useGetOAuthInfo';
import { useUpdateFundCode } from '../../hooks/useUpdateFundCode';
import { useUpdateSelectedSoftwareType } from '../../hooks/useUpdateSelectedSoftwareType';
import { SoftwareUploadsBlock } from './SoftwareUploadsBlock';
import { Select } from 'components';

interface ISoftwareTypeInformationProps {
    job: any;
    updateSoftwareFilesState: (state: boolean) => void;
    updateIsFundCodeNeeded: (state: boolean) => void;
    updateSelectedSoftwareType: (softwareType: SoftwareType) => void;
}

interface ISoftwareTypeState {
    isApiSoftwareType: boolean;
    isUploadableSoftwareType: boolean;
    selectedSoftwareTypeKey: number | undefined;
    selectedBusinessCode: string | undefined;
    oAuthBusinessCode: string | undefined;
    selectedFundCode: string | undefined;
    selectedFundId: string | undefined;
}

const softwareTypeOptions = [
    { key: 100, text: "Class Super API", softwareType: SoftwareType.ClassApiInCp },
    { key: 3, text: "Supermate", softwareType: SoftwareType.Supermate },
    { key: 4, text: "BGL Simple Fund", softwareType: SoftwareType.BGL },
    { key: 5, text: "BGL 360", softwareType: SoftwareType.BGL360 },
    { key: -1, text: "Other", softwareType: SoftwareType.Nothing },
    { key: 101, text: "Class Super PDF", softwareType: SoftwareType.ClassPdfInCp }];

const softwareTypeReducer = (state: ISoftwareTypeState, action: {type: string, value?: string}): ISoftwareTypeState => {
    switch (action.type) {
        case "CHANGE_SELECTED_BUSINESS_CODE":
            return { ...state, selectedBusinessCode: action.value };
        case "CHANGE_SELECTED_FUND_CODE":
            return { ...state, selectedFundCode: action.value };
        case "CHANGE_SELECTED_FUND_ID":
            return { ...state, selectedFundId: action.value };
        case "CHANGE_OAUTH_BUSINESS_CODE":
            return { ...state, oAuthBusinessCode: action.value };
        case "CHANGE_SOFTWARE_TYPE":
            return {
                ...state,
                selectedSoftwareTypeKey: softwareTypeOptions.find(x => x.softwareType === action.value)?.key,
                isApiSoftwareType: action.value === SoftwareType.BGL360 || action.value === SoftwareType.ClassApiInCp,
                isUploadableSoftwareType: action.value === SoftwareType.BGL || action.value === SoftwareType.ClassPdfInCp || action.value == SoftwareType.Supermate
            };
        default:
            return state;
    }
}

export const SoftwareTypeInformation: FunctionComponent<ISoftwareTypeInformationProps> = ({ job, updateSoftwareFilesState, updateIsFundCodeNeeded, updateSelectedSoftwareType }) => {
    const initialState = {
        selectedSoftwareTypeKey: undefined,
        selectedBusinessCode: undefined,
        oAuthBusinessCode: undefined,
        isApiSoftwareType: false,
        isUploadableSoftwareType: false,
        selectedFundCode: undefined,
        selectedFundId: undefined,
    };

    const [state, dispatch] = useReducer(softwareTypeReducer, initialState);

    const { formatMessage, formatDate } = useIntl();
    const { update, isUpdating } = useUpdateSelectedSoftwareType();
    const { update: updateFundcode, isUpdating: isFundCodeUpdating } = useUpdateFundCode();
    const { response, isLoading } = useGetOauthInfo(job?.id, state.oAuthBusinessCode);
    const { setting, isLoading: isBucketNameLoading } = useGetAmazonBucketName();
    const theme = useTheme();

    useEffect(() => {
        dispatch({ type: "CHANGE_SOFTWARE_TYPE", value: response?.softwareType });
        updateIsFundCodeNeeded(response?.softwareType === SoftwareType.ClassApiInCp || response?.softwareType === SoftwareType.BGL360);

        if (response?.softwareType) {
            updateSelectedSoftwareType(response?.softwareType);
        }
        
        if (response?.softwareType === SoftwareType.ClassApiInCp || response?.softwareType === SoftwareType.BGL360 || response?.softwareType === SoftwareType.Nothing) {
            updateSoftwareFilesState(true);
        }
    }, [response?.softwareType]);

    useEffect(() => {
        dispatch({ type: "CHANGE_SELECTED_FUND_CODE", value: job?.fundBusinessName });
    }, [job?.fundBusinessName]);

    useEffect(() => {
        dispatch({ type: "CHANGE_SELECTED_FUND_ID", value: response?.funds?.find(x => x.fundCode === job?.fundCode || x.fundId === job?.fundCode)?.fundId});
    }, [response?.funds, job?.fundCode]);

    useEffect(() => {
        dispatch({ type: "CHANGE_SELECTED_BUSINESS_CODE", value: response?.businessCode });
    }, [response?.businessCode]);

    const softwareTypeSelectionHandler = (option: any) => {
        dispatch({ type: "CHANGE_SOFTWARE_TYPE", value: option.softwareType });
        update({ manualSoftwareType: option.key, jobGuid: job.guid })
    }

    const businessCodeSelectionHandler = (option: any) => {
        dispatch({ type: "CHANGE_OAUTH_BUSINESS_CODE", value: option.key });
    }

    const fundCodeSelectionHandler = (option: any) => {
        dispatch({ type: "CHANGE_SELECTED_FUND_CODE", value: option.text });
        dispatch({ type: "CHANGE_SELECTED_FUND_ID", value: option.key });
    }

    const updateButtonClickHandler = (option: any) => {
        update({ manualSoftwareType: state.selectedSoftwareTypeKey ?? -1, jobGuid: job.guid });
        updateFundcode({ jobGuid: job?.guid, fundCode: state.selectedFundId ?? '', fundCodeName: state.selectedFundCode ?? '', businessCode: state.selectedBusinessCode ?? '' });
    }

    return (
        <Stack tokens={{ childrenGap: 12 }} styles={{ root: { width:'100%'}}}>
            <Stack tokens={{ childrenGap: 0 }} styles={{ root: { margin: '0.7rem', marginRight: '10%' } }} horizontal horizontalAlign={'end'}>
                    <Stack.Item styles={{ root: { width: '35%' } }} align={'center'}>
                        <Text theme={theme.schemes?.default}>{formatMessage({ id: 'accountingSoftwareType' })}</Text>
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: '35%' } }} align={'center'}>
                    <Dropdown
                        styles={{ dropdownOptionText: {color: theme.schemes?.default?.semanticColors.bodyText}}}
                        disabled={isUpdating} theme={theme?.schemes?.default} calloutProps={{
                        styles: {
                            root: { width: 'max-content' } }
                    }}
                            onChanged={softwareTypeSelectionHandler} selectedKey={state.selectedSoftwareTypeKey} dropdownWidth={100} options={softwareTypeOptions} />
                </Stack.Item>
                <Stack.Item styles={{ root: { width: '10%' } }} align={'center'}>
                    {isUpdating && <Spinner />}
                </Stack.Item>
            </Stack>
            {state.isApiSoftwareType && (isLoading || isUpdating ? <Spinner style={{ height: '100%' }} size={SpinnerSize.large} /> :
                <>
                    <Stack tokens={{ childrenGap: 0 }} styles={{ root: { margin: '0.7rem', marginRight: '10%' } }} horizontal horizontalAlign={'end'}>
                        <Stack.Item styles={{ root: { width: '35%' } }} align={'center'}>
                            <Text></Text>
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '35%' } }} align={'center'}>
                            <Dropdown
                                styles={{ dropdownOptionText: { color: theme.schemes?.default?.semanticColors.bodyText } }}
                                disabled={isUpdating} theme={theme?.schemes?.default}
                                calloutProps={{
                                    styles: { root: { width: 'max-content' } }
                                }}
                                onChanged={businessCodeSelectionHandler} selectedKey={state.selectedBusinessCode} dropdownWidth={100} options={response?.businessCodes?.map(x => ({ key: x, text: x })) ?? []} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '10%' } }} align={'center'}>
                            <Text></Text>
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 0 }} styles={{ root: { margin: '0.7rem', marginRight: '10%' } }} horizontal horizontalAlign={'end'}>
                        <Stack.Item styles={{ root: { width: '35%' } }} align={'center'}>
                            <Text></Text>
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '35%' } }} align={'center'}>
                            <Select
                                disabled={isUpdating || !response?.businessCodes}
                                allowFreeform
                                onChange={fundCodeSelectionHandler} 
                                selectedKey={state.selectedFundId} 
                                dropdownWidth={100}
                                options={response?.funds?.map(x => ({ key: x.fundId, text: x.fundCode, selected: x.fundCode === job.fundCode || x.fundId === job.fundCode })) ?? []} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '10%' } }} align={'center'}>
                            <Text></Text>
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 0 }} styles={{ root: { margin: '0.7rem', marginRight: '10%' } }} horizontal horizontalAlign={'end'}>
                        <Stack.Item styles={{ root: { width: '35%' } }} align={'center'}>
                            <Text></Text>
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '35%' } }} align={'center'}>
                            <PrimaryButton disabled={isFundCodeUpdating || !response?.isBusinessCodeAuthenticated} text={formatMessage({ id: 'update' })} onClick={updateButtonClickHandler} />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { width: '10%' } }} align={'center'}>
                            {job?.fundCode && <Icon styles={{ root: { color: theme?.schemes?.default?.palette.green, marginLeft: '10%' } }} iconName={'accept'} />}
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 12 }} styles={{ root: { margin: '0.7rem', marginRight: '20%' } }} horizontalAlign={'end'}>
                        {!response?.isClientAuthenticated && <Text style={{ color: theme?.schemes?.default?.palette.red }}>{formatMessage({ id: "pleaseAuthorizeApiAccess" })}</Text>}
                        {(response?.isClientAuthenticated && !response?.isBusinessCodeAuthenticated) && <Text style={{ color: theme?.schemes?.default?.palette.red }}>{formatMessage({ id: "pleaseAuthorizeSelectedBusinessCode" })}</Text>}
                        {(!state.selectedFundCode || !state.selectedFundId || !job?.fundCode) && <Text style={{ color: theme?.schemes?.default?.palette.red }}>{formatMessage({ id: "pleaseSelectTheFundCodeAndUpdate" })}</Text>}
                        {(!!state.selectedFundId && !!job?.fundCode && (response?.softwareType === SoftwareType.BGL360 || response?.softwareType === SoftwareType.Nothing)) &&
                            <Text style={{ color: theme?.schemes?.default?.palette.red }}>{formatMessage({ id: "pleaseAttachDraft" })}</Text>}
                    </Stack>
                </>
            )}
            {(state.isUploadableSoftwareType && !isBucketNameLoading && !isUpdating && !isLoading) &&
                <>
                    <Stack tokens={{ childrenGap: 12 }} styles={{ root: { margin: '0.7rem', marginRight: '10%' } }} horizontalAlign={'end'}>
                    <SoftwareUploadsBlock job={job} bucketName={setting?.data.value ?? ''} selectedSoftwareType={softwareTypeOptions.find(x=>x.key === state.selectedSoftwareTypeKey)?.softwareType} updateSoftwareFilesState={updateSoftwareFilesState} />
                    </Stack>
                </>
            }
        </Stack>
    );
}