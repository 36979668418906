import { CalculatorTemplateProps } from '../../types';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useUpdateCalculatorData } from '../../hooks';
import { useFieldArray, useForm } from 'react-hook-form';
import { DefaultFormSettings } from '../../../../../../constants';
import { Stack, TextField, useTheme } from '@fluentui/react';
import { CalculatorType } from '../../CalculatorType';
import { ControlledTextField, ITableColumn, SanitizedText } from '../../../../../../components';
import { IContributionTaxCalculatorData } from './IContributionTaxCalculatorData';
import { CalculatorDataTable } from '../shared';
import { toCurrencyFormat } from 'utils/stringHelper';
import { useTabContext } from 'pages/JobPortal';

type ContributionTaxCalculatorProps = CalculatorTemplateProps;

export const ContributionTaxCalculator = forwardRef(({ data }: ContributionTaxCalculatorProps, ref) => {
    const { formatMessage } = useIntl();
    const { isTabEnabled } = useTabContext();
    const [editingIndex, setEditingIndex] = useState<number>();
    const { update, isUpdating } = useUpdateCalculatorData();
    const [contributionTaxCalculatorData, setContributionTaxCalculatorData] = useState<IContributionTaxCalculatorData[]>(
        data?.listData as IContributionTaxCalculatorData[]
    );
    const theme = useTheme();
    const { handleSubmit, control, getValues, setValue } = useForm<{ items: IContributionTaxCalculatorData[] }>({
        ...DefaultFormSettings,
        defaultValues: { items: contributionTaxCalculatorData },
    });

    const { fields } = useFieldArray({
        control,
        name: 'items',
    });

    const updateCalcData = (calculatorData: any) => {
        update({
            saveComments: false,
            calculatorType: CalculatorType.Contribution,
            isCustom: false,
            answer: data?.data,
            answers: calculatorData.items,
            jobId: data?.jobId || 0,
        });
    };
    const onEditClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
            if (!isTabEnabled) return;

            setEditingIndex(index);
            event.stopPropagation();
        },
        [isTabEnabled]
    );

    const columns = useMemo<ITableColumn[]>(() => {
        return [
            {
                key: 'memberName',
                name: formatMessage({ id: 'calculator_contribution_title_memberName' }),
                fieldName: 'memberName',
                minWidth: 140,
                maxWidth: 140,
                onRender: (item) => <SanitizedText data={item.memberName} />,
            },
            {
                key: 'concessionalContribution',
                name: formatMessage({ id: 'calculator_contribution_title_concessionalContribution' }),
                fieldName: 'concessionalContribution',
                minWidth: 140,
                maxWidth: 140,
                onRender: (item, index) => (
                    <Stack
                        grow
                        styles={{
                            root: {
                                overflow: 'visible',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                            },
                        }}
                    >
                        {index !== undefined && editingIndex !== index && (
                            <>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        opacity: 0,
                                        zIndex: 100,
                                    }}
                                    onClick={(event) => onEditClick(event, index)}></div>
                                <TextField
                                    disabled
                                    multiline
                                    resizable={false}
                                    styles={{
                                        root: { width: '100%' },
                                        field: {
                                            color:
                                                contributionTaxCalculatorData[index]?.isConcessionalContributionsChanged ||
                                                contributionTaxCalculatorData[index]?.concessionalContributions !=
                                                    getValues(`items.${index}.concessionalContributions`)
                                                    ? theme.schemes?.default?.palette.blue
                                                    : theme.schemes?.default?.semanticColors.bodyText,
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                            overflow: 'visible',
                                        },
                                    }}
                                    value={toCurrencyFormat((getValues(`items.${index}.concessionalContributions`) || 0)?.toString())}
                                />
                            </>
                        )}
                        {index !== undefined && editingIndex === index && (
                            <ControlledTextField
                                autoFocus
                                name={`items.${index}.concessionalContributions`}
                                control={control}
                                onChange={(event) => {
                                    setValue(`items.${index}.isConcessionalContributionsChanged`, true);
                                }}
                                multiline
                            />
                        )}
                    </Stack>
                ),
            },
            {
                key: 'contributionsTax',
                name: formatMessage({ id: 'calculator_contribution_title_contributionsTax' }),
                fieldName: 'contributionsTax',
                minWidth: 140,
                maxWidth: 140,
                onRender: (item) => (
                    <SanitizedText data={(item.contributionsTax || 0).toString()} textAlign={'right'} numberFormat={'fractional'} />
                ),
            },
            {
                key: 'equalsContributionsTax',
                name: formatMessage({ id: 'calculator_contribution_title_equalsContributionsTax' }),
                fieldName: 'equalsContributionsTax',
                minWidth: 140,
                maxWidth: 140,
                onRender: (item) => (
                    <SanitizedText
                        data={
                            item.concessionalContributions
                                ? ((item.concessionalContributions * item.contributionsTax) / 100).toString()
                                : ''
                        }
                        textAlign={'right'}
                        numberFormat={'fractional'}
                    />
                ),
            },
        ];
    }, [editingIndex]);

    useImperativeHandle(ref, () => ({
        onSubmit() {
            handleSubmit(updateCalcData)();
        },
    }));

    useEffect(() => {
        setContributionTaxCalculatorData(data?.listData as IContributionTaxCalculatorData[]);
    }, [data?.listData]);

    const handleClickOutside = (event: any) => {
        if ((event.srcElement.id as string).includes('TextField')) return;

        setEditingIndex(undefined);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <CalculatorDataTable
                initialColumns={columns}
                columns={columns}
                header={{
                    rowHeight: 80,
                    horizontalAlign: 'center',
                }}
                shimmerLines={getValues().items?.length || 3}
                enableShimmer={isUpdating}
                items={fields || []}
            />
        </Stack>
    );
});
