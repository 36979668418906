import {FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";
import {Editor, EditorState} from "draft-js";
import {convertFromHTML, convertToHTML} from "draft-convert";
import {DefaultButton, mergeStyles, PrimaryButton, Spinner, Stack, useTheme} from "@fluentui/react";
import {useUpdateAuditQueryComment} from "../hooks/useUpdateAuditQueryComment";
import {useJobContext} from "../../../../../JobPortalLayoutPage";

interface IQueryCommentFormProps {
    itemId: number;
    data?: string;
    disabled: boolean;
    isUpdating: boolean;
    onSave: (newDate?: string) => void;
    onCancel: Function;
}

interface IQueryCommentFormState {
    value: EditorState;
}

const LINK_REGEX = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

export const QueryCommentForm: FunctionComponent<IQueryCommentFormProps> = ({ 
                                                                                  data,
                                                                                  disabled,
                                                                                  isUpdating,
                                                                                  onSave,
                                                                                  onCancel,
                                                                                  ...otherProps
                                                                              }: IQueryCommentFormProps) => {
    const {formatMessage} = useIntl();
    const theme = useTheme();
    
    const [formState, setFormState] = useState<IQueryCommentFormState>({
        value: EditorState.push(EditorState.createEmpty(), convertFromHTML(data ?? ''), 'insert-characters'),
    });

    const replaceToHTMLLinks = (text: string): string => {
        if (!text) {
            return text;
        }
    
        const matches = text.match(LINK_REGEX);
        if (!matches) {
            return text;
        }
    
        const uniqueMatches = Array.from(new Set(matches));
        uniqueMatches.sort((a, b) => b.length - a.length);
        const placeholders: Map<string, string> = new Map();
    
        let textWithPlaceholders = text;
        uniqueMatches.forEach((match, index) => {
            const placeholder = `__LINK_PLACEHOLDER_${index}__`;
            placeholders.set(placeholder, match);
            textWithPlaceholders = textWithPlaceholders.replaceAll(match, placeholder);
        });
    
        placeholders.forEach((originalText, placeholder) => {
            let href = originalText;
            if (!href.includes('://')) {
                href = `https://${href}`;
            }
            textWithPlaceholders = textWithPlaceholders.replaceAll(
                placeholder,
                `<a href="${href}" style="color:#61b0e9" target="_blank">${originalText}</a>`
            );
        });
    
        return textWithPlaceholders;
    };
    
    const onSaveClick = () => {
        const commentContent = formState.value.getCurrentContent();
        const commentHtmlText = convertToHTML(commentContent);
        const htmlText = replaceToHTMLLinks(commentHtmlText);
        onSave(htmlText);
    }

    const className = mergeStyles({
        selectors: {
            '.public-DraftEditor-content': {
                minHeight: 120
            },
        },
    });
    
    return (
        <Stack tokens={{childrenGap: 16}} className={className}>
            <Stack.Item>
                <Stack styles={{
                    root: {
                        border: `1px solid ${disabled ? theme.palette.neutralSecondaryAlt : theme.palette.neutralPrimary}`,
                        borderRadius: 2,
                        overflow: 'hidden',
                        padding: 8,
                        color: theme.schemes?.default?.semanticColors.bodyText
                    }
                }}>
                    <Editor
                        editorState={formState.value}
                        onChange={(v) => setFormState(prev => ({...prev, value: v}))}
                        readOnly={disabled || isUpdating}
                    />
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal horizontalAlign='end' tokens={{childrenGap: 16}}>
                    {isUpdating && <Spinner />}
                    <PrimaryButton text={formatMessage({id: 'save'})}
                                   onClick={onSaveClick}
                                   disabled={disabled || isUpdating}
                    />
                    <DefaultButton onClick={() => onCancel()}
                                   text={formatMessage({id: 'cancel'})}
                    />
                </Stack>
            </Stack.Item>
        </Stack>
    )
}