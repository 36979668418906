import { forwardRef, FunctionComponent, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {IAuditQualificationItem} from "./interfaces";
import {Card} from "../../../../../../components";
import { DefaultButton, Dialog, DialogFooter, DialogType, Stack, Text, useTheme } from '@fluentui/react';
import {WorkDoneSwitchAnswer} from "../../answers";
import {ColumnInfo} from "../../../../enums";
import {useTabContext} from "../../../../JobPortalPage";
import {_QualificationProcedureRef} from "./AuditQualificationTemplate";
import { useIntl } from 'react-intl';
import { useSectionContext } from "../../../Section";
import { useProcedureContext } from "../../../ProceduresContent";
import { useId } from '@fluentui/react-hooks';

type AuditQualificationPanelProps = {
    item: IAuditQualificationItem;
    onAnswer?: (answer: string | null) => void,
    gridHasOmls: boolean,
    disabled: boolean
}

const cardStyles = {
    root: {
        width: '100%'
    }
};

export const AuditQualificationPanelTemplate = forwardRef(({item, onAnswer, gridHasOmls, disabled}: AuditQualificationPanelProps, ref) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const [showAlert, setShowAlert] = useState<boolean>(false);

    const {items} = useProcedureContext();
    
    const {disableSection} = useTabContext();

    const handleOnContraventionAnswer = (answer: string | null, itemReference: string) => {
        if (itemReference === 'Is a contravention report required?') {
            disableSection('Contravention Report', !answer || answer === 'No');
        }

    };

    useImperativeHandle(ref, () => ({
        onApprove(approved: boolean, type: 'manager' | 'partner' | 'sendEmail', update: Function) {
            if (item.title === _QualificationProcedureRef && item.answerText === 'Yes' && items.every(x => !x.omlItems?.length) && type !== 'sendEmail' && approved) {
                setShowAlert(true);
                return;
            } else {
                update(approved, type, true);
            }
        }
    }), [item.title]);

    const hideAlert = () => {
        setShowAlert(false);
    };

    useEffect(() => {
        handleOnContraventionAnswer(item.answerText, item.title);
    },[]);

    const dialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: formatMessage({id: 'warning'}),
        closeButtonAriaLabel: 'Close',
        subText: formatMessage({id: 'jobPortal_aq_warning'}),
    };

    const modalProps = useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,
        }),
        [labelId, subTextId],
    );
    
    const disabledPartialy: any = useMemo(() => {
        if (item.title === _QualificationProcedureRef && gridHasOmls) {
            return 'invalid'
        }
        
        return null;
    }, [item, gridHasOmls])

    return (
        <>
            <Card styles={cardStyles}>
                <Stack horizontal verticalAlign="center" horizontalAlign='space-between' grow>
                    <Stack.Item>
                        <Text theme={theme.schemes?.default}>{item.title}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <WorkDoneSwitchAnswer itemId={item.id}
                                              tableType={item.tableType!}
                                              iconTypes="character"
                                              reverse
                                              disabled={disabled}
                                              onUpdate={(answer) => {
                                                  handleOnContraventionAnswer(answer, item.title);
                                                  onAnswer?.(answer);
                                              }}
                                              disabledPartialy={disabledPartialy}
                                              value={item.answerText || ''}
                                              columnInfo={ColumnInfo.Text}/>
                    </Stack.Item>
                </Stack>
            </Card>
            <Dialog
                hidden={!showAlert}
                onDismiss={hideAlert}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <DefaultButton onClick={hideAlert} text={formatMessage({id: 'close'})}/>
                </DialogFooter>
            </Dialog>
        </>
    );
});