import React, { PropsWithChildren } from 'react';
import { Icon, Stack, Text, useTheme } from '@fluentui/react';
import { FormattedMessage } from 'react-intl';

export type EmptyProps = {
    description?: React.ReactNode;
    image?: React.ReactNode;
    iconName?: string;
    imageStyle?: React.CSSProperties;
};



const DefaultEmptyImage = ({ imageStyle, iconName }: EmptyProps) => {
    const { palette } = useTheme();

    return <Icon style={{ fontSize: '3em', color: palette.themeDarker, opacity: 0.5, ...imageStyle }} iconName={iconName || 'SearchIssue'} />;
};

const DefaultEmptyDescription = () => {
    const theme = useTheme();
    return (
        <Text styles={{ root: { color: theme.schemes?.default?.semanticColors.bodyText } }} variant='mediumPlus'>
            <FormattedMessage id={'notFound_items'} />
        </Text>
    );
};

export const Empty = ({ image, description, imageStyle, iconName, children }: PropsWithChildren<EmptyProps>) => {
    return (
        <Stack verticalAlign='center' horizontalAlign='center' tokens={{ childrenGap: 6 }}>
            <Stack.Item>{image ?? <DefaultEmptyImage imageStyle={imageStyle} iconName={iconName} />}</Stack.Item>
            <Stack.Item>{description ?? <DefaultEmptyDescription />}</Stack.Item>
            <Stack.Item>{children}</Stack.Item>
        </Stack>
    );
};
