import * as React from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { CalculatorTemplateProps } from '../../types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUpdateCalculatorData } from '../../hooks';
import { useFieldArray, useForm } from 'react-hook-form';
import { DefaultFormSettings } from '../../../../../../constants';
import { CalculatorType } from '../../CalculatorType';
import { ControlledTextField, DialogMessage, ITableColumn, Modal, ModalFooter, SanitizedText } from '../../../../../../components';
import { ActionButton, DialogType, IColumn, IconButton, PrimaryButton, Stack, TextField, useTheme } from '@fluentui/react';
import { IProfitLossAllocationItemData } from './IProfitLossAllocationItemData';
import { useJobContext } from '../../../../JobPortalLayoutPage';
import { useBoolean } from '@fluentui/react-hooks';
import { CalculatorDataTable } from '../shared';
import { toCurrencyFormat } from 'utils/stringHelper';
import { useTabContext } from 'pages/JobPortal';

type ProfitLossAllocationCalculatorProps = CalculatorTemplateProps;

export const ProfitLossAllocationCalculator = forwardRef(({ data }: ProfitLossAllocationCalculatorProps, ref) => {
    const { formatMessage } = useIntl();
    const { isTabEnabled } = useTabContext();
    const { jobId } = useJobContext();
    const theme = useTheme();
    const [editingField, setEditingField] = useState<{ index: number; columnName: string } | undefined>(undefined);
    const [profitEditingField, setProfitEditingField] = useState<{ index: number; columnName: string } | undefined>(undefined);
    const [showCommentsDialog, setShowCommentsDialog] = useState<boolean>(false);
    const [commentItemIndex, setCommentItemIndex] = useState<number>();

    const { update, isUpdating } = useUpdateCalculatorData();
    const [profitLossAllocationItemData, setProfitLossAllocationItemData] = useState<IProfitLossAllocationItemData>(
        data?.data as IProfitLossAllocationItemData
    );
    const { handleSubmit, control, watch, getValues, setValue } = useForm<IProfitLossAllocationItemData>({
        ...DefaultFormSettings,
        defaultValues: profitLossAllocationItemData,
    });

    const [currentItem, setCurrentItem] = useState<number | null>();
    const [showDeleteDialog, { toggle: toggleDeleteDialog }] = useBoolean(false);

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'members',
    });
    const watchFields = watch();
    const adjusted = useMemo(() => {
        return (
            (+watchFields.beforeTax || 0) -
            (watchFields.contributions || 0) -
            (watchFields.rollOversIN || 0) +
            (+watchFields.benefits || 0) +
            (+watchFields.rollOversOUT || 0) +
            (+watchFields.memberExpenses || 0)
        );
    }, [watchFields]);
    //Total Adjusted Member Balance
    const totalAdjustedMemberBalance = useMemo(() => {
        let sum = 0;

        watchFields.members?.forEach((value, index) => {
            sum += +value.adjustedMemberBalance;
        });

        return sum;
    }, [watchFields]);

    //Total Allocated Profit / Loss as per calculator
    const totalAllocatedProfitLossCalculator = useMemo(() => {
        let sum = 0;

        watchFields.members?.forEach((value, index) => {
            sum += +(value.allocatedPLCalculator || 0);
        });

        return sum;
    }, [watchFields]);

    //Total Allocated Profit / Loss as per Member Statement
    const totalAllocatedPLMemberStatement = useMemo(() => {
        let sum = 0;

        watchFields.members?.forEach((value, index) => {
            sum += +(value.allocatedPLMemberStatement || 0);
        });

        return sum;
    }, [watchFields]);

    //Total variance
    const totalVariance = useMemo(() => {
        let sum = 0;

        watchFields.members?.forEach((value, index) => {
            sum += +(value.variance || 0);
        });

        return sum;
    }, [watchFields]);

    const allocatedProfitLossCalculator = useCallback(
        (index: number) => {
            if (!watchFields.members[index]) {
                return 0;
            }
            if (watchFields.totalAdjustedMemberBalance == 0) {
                return 0;
            }
            return (
                (watchFields.members[index].adjustedMemberBalance * watchFields.adjustedProfitLoss) / watchFields.totalAdjustedMemberBalance
            );
        },
        [watchFields]
    );

    const variance = useCallback(
        (index: number) => {
            if (!watchFields.members[index]) {
                return 0;
            }
            return allocatedProfitLossCalculator(index) - watchFields.members[index].allocatedPLMemberStatement;
        },
        [watchFields]
    );

    const profitAllocatedCalculator = useCallback(
        (index: number) => {
            if (totalAllocatedProfitLossCalculator == 0) {
                return 0;
            }
            return (100 * allocatedProfitLossCalculator(index)) / totalAllocatedProfitLossCalculator;
        },
        [watchFields]
    );

    const profitAllocatedMemberStatement = useCallback(
        (index: number) => {
            if (!watchFields.members[index]) {
                return 0;
            }
            if (totalAllocatedProfitLossCalculator == 0) {
                return 0;
            }
            return (100 * watchFields.members[index].allocatedPLMemberStatement) / totalAllocatedPLMemberStatement;
        },
        [watchFields]
    );
    const updateCalcData = (calculatorData: IProfitLossAllocationItemData) => {
        if (!isTabEnabled) return;

        update({
            saveComments: false,
            calculatorType: CalculatorType.ProfitLossAllocation,
            isCustom: false,
            answer: calculatorData,
            answers: undefined,
            jobId: jobId,
        });
    };

    const handleOnRemoveClick = (index: number) => {
        if (!isTabEnabled) return;

        remove(index);
    };
    const handleOnAddClick = () => {
        if (!isTabEnabled) return;

        append({
            id: 0,
            isCustom: true,
            adjustedMemberBalance: 0,
            profitAllocatedCalculator: 0,
            profitAllocatedMemberStatement: 0,
            allocatedPLMemberStatement: 0,
            memberOpeningBalance: 0,
            memberName: '',
        });
    };

    const handleOnCommentIconClick = (index?: number) => {
        setCommentItemIndex(index);
        setShowCommentsDialog(true);
    };

    const handleOnEditingCommentDismiss = () => {
        setCommentItemIndex(undefined);
        setShowCommentsDialog(false);
    };

    const onEditProfitClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, column: IColumn) => {
            if (!isTabEnabled) return;

            setEditingField(undefined);
            setProfitEditingField({ index: index!, columnName: column?.name! });
            event.preventDefault();
        },
        [isTabEnabled]
    );

    const onEditClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, column: IColumn) => {
            if (!isTabEnabled) return;

            setProfitEditingField(undefined);
            setEditingField({ index: index!, columnName: column?.name! });
            event.preventDefault();
        },
        [isTabEnabled]
    );

    const columns = useMemo<ITableColumn[]>(() => {
        return [
            {
                key: 'profitLossPerOperatingStatement',
                name: formatMessage({ id: 'calculator_profitLoss_title_profitLossPerOperatingStatement' }),
                fieldName: 'beforeTax',
                minWidth: 160,
                onRender: (item, index, column) => (
                    <Stack grow>
                        {index !== undefined &&
                            (!profitEditingField ||
                                profitEditingField.index !== index ||
                                profitEditingField.columnName !== column?.name) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => onEditProfitClick(event, index, column!)}></div>
                                    <TextField
                                        disabled
                                        styles={{
                                            field: {
                                                color:
                                                    profitLossAllocationItemData.isBeforeTaxChanged ||
                                                    profitLossAllocationItemData.beforeTax != getValues('beforeTax')
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                            },
                                        }}
                                        value={toCurrencyFormat((getValues(`beforeTax`) || 0)?.toString())}
                                    />
                                </>
                            )}
                        {index !== undefined && profitEditingField?.index === index && profitEditingField.columnName === column?.name && (
                            <ControlledTextField
                                autoFocus
                                name={`beforeTax`}
                                control={control}
                                onChange={(event) => setValue('isBeforeTaxChanged', true)}
                            />
                        )}
                    </Stack>
                ),
            },
            {
                key: 'contributions',
                name: formatMessage({ id: 'calculator_profitLoss_title_contributions' }),
                fieldName: 'contributions',
                minWidth: 160,
                maxWidth: 160,
                onRender: (item, index, column) => (
                    <Stack grow>
                        {index !== undefined &&
                            (!profitEditingField ||
                                profitEditingField.index !== index ||
                                profitEditingField.columnName !== column?.name) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => onEditProfitClick(event, index, column!)}></div>
                                    <TextField
                                        disabled
                                        styles={{
                                            field: {
                                                color:
                                                    profitLossAllocationItemData.isContributionsChanged ||
                                                    profitLossAllocationItemData.contributions != getValues('contributions')
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                            },
                                        }}
                                        value={toCurrencyFormat((getValues(`contributions`) || 0)?.toString())}
                                    />
                                </>
                            )}
                        {index !== undefined && profitEditingField?.index === index && profitEditingField.columnName === column?.name && (
                            <ControlledTextField
                                autoFocus
                                name={`contributions`}
                                control={control}
                                onChange={(event) => setValue('isContributionsChanged', true)}
                            />
                        )}
                    </Stack>
                ),
            },
            {
                key: 'rollersOver',
                name: formatMessage({ id: 'calculator_profitLoss_title_rollOversIN' }),
                fieldName: 'rollOversIN',
                minWidth: 160,
                maxWidth: 160,
                onRender: (item, index, column) => (
                    <Stack grow>
                        {index !== undefined &&
                            (!profitEditingField ||
                                profitEditingField.index !== index ||
                                profitEditingField.columnName !== column?.name) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => onEditProfitClick(event, index, column!)}></div>
                                    <TextField
                                        disabled
                                        styles={{
                                            field: {
                                                color:
                                                    profitLossAllocationItemData.isRollOversINChanged ||
                                                    profitLossAllocationItemData.rollOversIN != getValues('rollOversIN')
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                            },
                                        }}
                                        value={toCurrencyFormat(getValues(`rollOversIN`) || 0)?.toString()}
                                    />
                                </>
                            )}
                        {index !== undefined && profitEditingField?.index === index && profitEditingField.columnName === column?.name && (
                            <ControlledTextField
                                autoFocus
                                name={`rollOversIN`}
                                control={control}
                                onChange={(event) => setValue('isRollOversINChanged', true)}
                            />
                        )}
                    </Stack>
                ),
            },
            {
                key: 'benefits',
                name: formatMessage({ id: 'calculator_profitLoss_title_benefits' }),
                fieldName: 'benefits',
                minWidth: 160,
                maxWidth: 160,
                onRender: (item, index, column) => (
                    <Stack grow>
                        {index !== undefined &&
                            (!profitEditingField ||
                                profitEditingField.index !== index ||
                                profitEditingField.columnName !== column?.name) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => onEditProfitClick(event, index, column!)}></div>
                                    <TextField
                                        disabled
                                        styles={{
                                            field: {
                                                color:
                                                    profitLossAllocationItemData.isBenefitsChanged ||
                                                    profitLossAllocationItemData.benefits != getValues('benefits')
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                            },
                                        }}
                                        value={toCurrencyFormat((getValues(`benefits`) || 0)?.toString())}
                                    />
                                </>
                            )}
                        {index !== undefined && profitEditingField?.index === index && profitEditingField.columnName === column?.name && (
                            <ControlledTextField
                                autoFocus
                                name={`benefits`}
                                control={control}
                                onChange={(event) => setValue('isBenefitsChanged', true)}
                            />
                        )}
                    </Stack>
                ),
            },
            {
                key: 'rollOversOUT',
                name: formatMessage({ id: 'calculator_profitLoss_title_rollOversOUT' }),
                fieldName: 'rollOversOUT',
                minWidth: 160,
                maxWidth: 160,
                onRender: (item, index, column) => (
                    <Stack grow>
                        {index !== undefined &&
                            (!profitEditingField ||
                                profitEditingField.index !== index ||
                                profitEditingField.columnName !== column?.name) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => onEditProfitClick(event, index, column!)}></div>
                                    <TextField
                                        disabled
                                        styles={{
                                            field: {
                                                color:
                                                    profitLossAllocationItemData.isRollOversOUTChanged ||
                                                    profitLossAllocationItemData.rollOversOUT != getValues('rollOversOUT')
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                            },
                                        }}
                                        value={toCurrencyFormat((getValues(`rollOversOUT`) || 0)?.toString())}
                                    />
                                </>
                            )}
                        {index !== undefined && profitEditingField?.index === index && profitEditingField.columnName === column?.name && (
                            <ControlledTextField
                                autoFocus
                                name={`rollOversOUT`}
                                control={control}
                                onChange={(event) => setValue('isRollOversOUTChanged', true)}
                            />
                        )}
                    </Stack>
                ),
            },
            {
                key: 'memberExpenses',
                name: formatMessage({ id: 'calculator_profitLoss_title_memberExpenses' }),
                fieldName: 'memberExpenses',
                minWidth: 160,
                maxWidth: 160,
                onRender: (item, index, column) => (
                    <Stack grow>
                        {index !== undefined &&
                            (!profitEditingField ||
                                profitEditingField.index !== index ||
                                profitEditingField.columnName !== column?.name) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => onEditProfitClick(event, index, column!)}></div>
                                    <TextField
                                        disabled
                                        styles={{
                                            field: {
                                                color:
                                                    profitLossAllocationItemData.isMemberExpensesChanged ||
                                                    profitLossAllocationItemData.memberExpenses != getValues('memberExpenses')
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                            },
                                        }}
                                        value={toCurrencyFormat((getValues(`memberExpenses`) || 0)?.toString())}
                                    />
                                </>
                            )}
                        {index !== undefined && profitEditingField?.index === index && profitEditingField.columnName === column?.name && (
                            <ControlledTextField
                                autoFocus
                                name={`memberExpenses`}
                                control={control}
                                onChange={(event) => setValue('isMemberExpensesChanged', true)}
                            />
                        )}
                    </Stack>
                ),
            },
            {
                key: 'adjusted',
                name: formatMessage({ id: 'calculator_profitLoss_title_adjusted' }),
                fieldName: 'adjusted',
                minWidth: 160,
                maxWidth: 160,
                onRender: (item, index) => <SanitizedText numberFormat={'fractional'} textAlign={'right'} data={adjusted.toString()} />,
            },
            {
                key: 'actions',
                name: '',
                onRender: (item, index) => null,
                minWidth: 70,
                maxWidth: 70,
            },
        ];
    }, [profitEditingField]);

    const membersColumns = useMemo<ITableColumn[]>(() => {
        return [
            {
                key: 'comments',
                name: '',
                fieldName: 'comments',
                minWidth: 35,
                maxWidth: 35,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <Stack horizontalAlign={'center'} grow>
                            <IconButton
                                onClick={() => handleOnCommentIconClick(index)}
                                styles={
                                    getValues(`members.${index}.comments`)?.length
                                        ? {
                                              icon: { color: theme.palette.red },
                                              iconHovered: { color: theme.palette.redDark },
                                          }
                                        : undefined
                                }
                                iconProps={{ iconName: 'comment' }}
                            />
                        </Stack>
                    ) : null,
            },
            {
                key: 'memberName',
                name: formatMessage({ id: 'calculator_profitLoss_title_memberName' }),
                fieldName: 'memberName',
                minWidth: 150,
                maxWidth: 150,
                onRender: (item, index, column) => (
                    <Stack
                        grow
                        styles={{
                            root: {
                                overflow: 'visible',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                            },
                        }}
                    >
                        {index !== undefined &&
                            (!editingField || editingField.index !== index || editingField.columnName !== column?.name) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => onEditClick(event, index, column!)}
                                    ></div>
                                    <TextField
                                        disabled
                                        multiline
                                        resizable={false}
                                        styles={{
                                            root: { width: '100%' },
                                            field: {
                                                color:
                                                    profitLossAllocationItemData.members[index]?.isMemberNameChanged ||
                                                    profitLossAllocationItemData.members[index]?.memberName !=
                                                        getValues(`members.${index}.memberName`)
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                                whiteSpace: 'normal',
                                                wordBreak: 'break-word',
                                                overflow: 'visible',
                                            },
                                        }}
                                        value={getValues(`members.${index}.memberName`) || ''}
                                    />
                                </>
                            )}
                        {index !== undefined && editingField?.index === index && editingField.columnName === column?.name && (
                            <ControlledTextField
                                autoFocus
                                name={`members.${index}.memberName`}
                                control={control}
                                onChange={(event) => setValue(`members.${index}.isMemberNameChanged`, true)}
                                multiline
                            />
                        )}
                    </Stack>
                ),
            },
            {
                key: 'adjusted',
                name: formatMessage({ id: 'calculator_profitLoss_title_adjusted' }),
                fieldName: 'adjusted',
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText numberFormat={'fractional'} textAlign={'right'} data={adjusted?.toString()} />
                    ) : null,
            },
            {
                key: 'memberOpeningBalance',
                name: formatMessage({ id: 'calculator_profitLoss_title_memberOpeningBalance' }),
                fieldName: 'memberOpeningBalance',
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText
                            numberFormat={'fractional'}
                            textAlign={'right'}
                            data={(getValues(`members.${index}.memberOpeningBalance`) || 0)?.toString()}
                        />
                    ) : null,
            },
            {
                key: 'adjustedMemberBalance',
                name: formatMessage({ id: 'calculator_profitLoss_title_adjustedMemberBalance' }),
                fieldName: 'adjustedMemberBalance',
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index, column) => (
                    <Stack grow>
                        {index !== undefined &&
                            (!editingField || editingField.index !== index || editingField.columnName !== column?.name) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => onEditClick(event, index, column!)}></div>
                                    <TextField
                                        disabled
                                        styles={{
                                            field: {
                                                color:
                                                    profitLossAllocationItemData.members[index]?.isAdjustedChanged ||
                                                    profitLossAllocationItemData.members[index]?.adjustedMemberBalance !=
                                                        getValues(`members.${index}.adjustedMemberBalance`)
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                            },
                                        }}
                                        value={toCurrencyFormat((getValues(`members.${index}.adjustedMemberBalance`) || 0)?.toString())}
                                    />
                                </>
                            )}
                        {index !== undefined && editingField?.index === index && editingField.columnName === column?.name && (
                            <ControlledTextField
                                autoFocus
                                name={`members.${index}.adjustedMemberBalance`}
                                control={control}
                                onChange={(event) => setValue(`members.${index}.isAdjustedChanged`, true)}
                            />
                        )}
                    </Stack>
                ),
            },
            {
                key: 'allocatedPLCalculator',
                name: formatMessage({ id: 'calculator_profitLoss_title_allocatedPLCalculator' }),
                fieldName: 'allocatedPLCalculator',
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText
                            numberFormat={'fractional'}
                            textAlign={'right'}
                            data={allocatedProfitLossCalculator(index)?.toString()}
                        />
                    ) : null,
            },
            {
                key: 'allocatedPLMemberStatement',
                name: formatMessage({ id: 'calculator_profitLoss_title_allocatedPLMemberStatement' }),
                fieldName: 'allocatedPLMemberStatement',
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index, column) => (
                    <Stack grow>
                        {index !== undefined &&
                            (!editingField || editingField.index !== index || editingField.columnName !== column?.name) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => onEditClick(event, index, column!)}></div>
                                    <TextField
                                        disabled
                                        styles={{
                                            field: {
                                                color:
                                                    profitLossAllocationItemData.members[index]?.isAllocatedMemberChanged ||
                                                    profitLossAllocationItemData.members[index]?.allocatedPLMemberStatement !=
                                                        getValues(`members.${index}.allocatedPLMemberStatement`)
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                            },
                                        }}
                                        value={toCurrencyFormat(
                                            (getValues(`members.${index}.allocatedPLMemberStatement`) || 0)?.toString()
                                        )}
                                    />
                                </>
                            )}
                        {index !== undefined && editingField?.index === index && editingField.columnName === column?.name && (
                            <ControlledTextField
                                autoFocus
                                name={`members.${index}.allocatedPLMemberStatement`}
                                control={control}
                                onChange={(event) => setValue(`members.${index}.isAllocatedMemberChanged`, true)}
                            />
                        )}
                    </Stack>
                ),
            },
            {
                key: 'variance',
                name: formatMessage({ id: 'calculator_profitLoss_title_variance' }),
                fieldName: 'variance',
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText numberFormat={'fractional'} textAlign={'right'} data={variance(index)?.toString()} />
                    ) : null,
            },
            {
                key: 'profitAllocatedCalculator',
                name: formatMessage({ id: 'calculator_profitLoss_title_profitAllocatedCalculator' }),
                fieldName: 'profitAllocatedCalculator',
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText
                            numberFormat={'percentage-fractional'}
                            textAlign={'right'}
                            data={(profitAllocatedCalculator(index) / 100).toString()}
                        />
                    ) : null,
            },
            {
                key: 'profitAllocatedMemberStatement',
                name: formatMessage({ id: 'calculator_profitLoss_title_profitAllocatedMemberStatement' }),
                fieldName: 'profitAllocatedMemberStatement',
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText
                            numberFormat={'percentage-fractional'}
                            textAlign={'right'}
                            data={(profitAllocatedMemberStatement(index) / 100).toString()}
                        />
                    ) : null,
            },
            {
                key: 'actions',
                name: '',
                onRender: (item, index) => null,
                minWidth: 70,
                maxWidth: 70,
            },
        ];
    }, [profitEditingField, editingField, profitLossAllocationItemData]);

    const totalColumns = useMemo<ITableColumn[]>(() => {
        return [
            {
                key: 'comments',
                name: '',
                fieldName: 'comments',
                minWidth: 35,
                maxWidth: 35,
                onRender: () => null,
            },
            {
                key: 'memberName',
                name: formatMessage({ id: 'calculator_profitLoss_title_memberName' }),
                fieldName: 'memberName',
                minWidth: 150,
                maxWidth: 150,
                onRender: () => null,
            },
            {
                key: 'adjusted',
                name: formatMessage({ id: 'calculator_profitLoss_title_adjusted' }),
                fieldName: 'adjusted',
                minWidth: 120,
                maxWidth: 120,
                onRender: () => null,
            },
            {
                key: 'memberOpeningBalance',
                name: formatMessage({ id: 'calculator_profitLoss_title_memberOpeningBalance' }),
                fieldName: 'memberOpeningBalance',
                minWidth: 120,
                maxWidth: 120,
                onRender: () => null,
            },
            {
                key: 'adjustedMemberBalance',
                name: formatMessage({ id: 'calculator_profitLoss_title_adjustedMemberBalance' }),
                fieldName: 'adjustedMemberBalance',
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText numberFormat={'fractional'} textAlign={'right'} data={totalAdjustedMemberBalance?.toString()} />
                    ) : null,
            },
            {
                key: 'allocatedPLCalculator',
                name: formatMessage({ id: 'calculator_profitLoss_title_allocatedPLCalculator' }),
                fieldName: 'allocatedPLCalculator',
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText
                            numberFormat={'fractional'}
                            textAlign={'right'}
                            data={totalAllocatedProfitLossCalculator?.toString()}
                        />
                    ) : null,
            },
            {
                key: 'allocatedPLMemberStatement',
                name: formatMessage({ id: 'calculator_profitLoss_title_allocatedPLMemberStatement' }),
                fieldName: 'allocatedPLMemberStatement',
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText numberFormat={'fractional'} textAlign={'right'} data={totalAllocatedPLMemberStatement?.toString()} />
                    ) : null,
            },
            {
                key: 'variance',
                name: formatMessage({ id: 'calculator_profitLoss_title_variance' }),
                fieldName: 'variance',
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText numberFormat={'fractional'} textAlign={'right'} data={totalVariance?.toString()} />
                    ) : null,
            },
            {
                key: 'profitAllocatedCalculator',
                name: formatMessage({ id: 'calculator_profitLoss_title_profitAllocatedCalculator' }),
                fieldName: 'profitAllocatedCalculator',
                minWidth: 120,
                maxWidth: 120,
                onRender: () => null,
            },
            {
                key: 'profitAllocatedMemberStatement',
                name: formatMessage({ id: 'calculator_profitLoss_title_profitAllocatedMemberStatement' }),
                fieldName: 'profitAllocatedMemberStatement',
                minWidth: 120,
                maxWidth: 120,
                onRender: () => null,
            },
            {
                key: 'actions',
                name: '',
                onRender: () => null,
                minWidth: 70,
                maxWidth: 70,
            },
        ];
    }, [watchFields]);

    useImperativeHandle(ref, () => ({
        onSubmit() {
            handleSubmit(updateCalcData)();
        },
    }));

    useEffect(() => {
        setProfitLossAllocationItemData(data?.data as IProfitLossAllocationItemData);
    }, [data?.listData]);

    const dialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: formatMessage({ id: 'removingItemDialogTitle' }),
        closeButtonAriaLabel: 'Close',
        subText: formatMessage({ id: 'removingItemDialogMessage' }),
    };

    const handleClickOutside = (event: any) => {
        if ((event.srcElement.id as string).includes('TextField')) return;

        setEditingField(undefined);
        setProfitEditingField(undefined);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <Stack tokens={{ childrenGap: 16 }} horizontalAlign={'center'} grow>
                <CalculatorDataTable
                    initialColumns={columns}
                    columns={columns}
                    header={{
                        rowHeight: 80,
                        horizontalAlign: 'center',
                    }}
                    shimmerLines={profitLossAllocationItemData.members.length || 3}
                    enableShimmer={isUpdating}
                    items={[profitLossAllocationItemData] || []}
                />
                <Stack.Item>
                    <CalculatorDataTable
                        initialColumns={membersColumns}
                        columns={membersColumns}
                        header={{
                            rowHeight: 100,
                            horizontalAlign: 'center',
                        }}
                        shimmerLines={fields.length || 3}
                        enableShimmer={isUpdating}
                        items={fields || []}
                    />
                    <CalculatorDataTable
                        initialColumns={totalColumns}
                        isHeaderVisible={false}
                        columns={totalColumns}
                        enableShimmer={isUpdating}
                        header={{
                            rowHeight: 100,
                            horizontalAlign: 'center',
                        }}
                        items={[{}]}
                    />
                </Stack.Item>
                <Stack.Item>
                    {isTabEnabled && (
                        <ActionButton
                            styles={{ textContainer: { color: theme.schemes?.default?.semanticColors.bodyText } }}
                            text={formatMessage({ id: 'clickToAddRow' })}
                            iconProps={{ iconName: 'add' }}
                            onClick={handleOnAddClick}
                        />
                    )}
                </Stack.Item>
            </Stack>
            <Modal isOpen={showCommentsDialog} onDismiss={handleOnEditingCommentDismiss} title={formatMessage({ id: 'comments' })}>
                <Stack tokens={{ childrenGap: 16 }}>
                    <ControlledTextField
                        disabled={!isTabEnabled}
                        name={`members.${commentItemIndex!}.comments`}
                        multiline
                        control={control}
                    />
                    <ModalFooter horizontalAlign={'end'}>
                        <PrimaryButton disabled={!isTabEnabled} onClick={handleOnEditingCommentDismiss}>
                            <FormattedMessage id='save' />
                        </PrimaryButton>
                    </ModalFooter>
                </Stack>
            </Modal>

            <DialogMessage
                onClick={() => {
                    toggleDeleteDialog();
                    handleOnRemoveClick(currentItem!);
                }}
                dialogContentProps={dialogContentProps}
                hidden={!showDeleteDialog}
                onDismis={() => {
                    toggleDeleteDialog();
                    setCurrentItem(null);
                }}
            />
        </>
    );
});
