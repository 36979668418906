import { createContext, forwardRef, useImperativeHandle, useContext, useState, useEffect, useMemo } from 'react';
import { useGetReportContent } from '../hooks/useGetReports';
import { ISectionInfo, IValidationError } from '../interfaces';
import { Loading } from '../../../components';
import { ReportTemplateTypeProps } from './templates/reports/Types';
import { getReportTemplate } from '../factories';
import { useJobContext } from '../JobPortalLayoutPage';
import { IReport } from '../interfaces/IReportsInfo';
import { AnswerControlType, TableType } from '../../../enums';
import { useGetReportValidations } from './templates/reports/hooks';

interface Props {
    section: ISectionInfo;
    jobId?: number;
}

interface IReportContext {
    answers?: ReportAnswer[];
    updateAnswers?: (answers: { id: any; answerText: any }[]) => void;
}

const ReportContext = createContext<IReportContext>({});

export interface IReportValidationContext {
    reportValidations: IValidationError[];
}

type AutoIndex = '' | '1' | '2' | '3';
type ReportAnswer = {
    id: number;
    answerText?: string;
    isDisabled?: boolean;
    answerText1?: string;
    answerText2?: string;
    answerText3?: string;
    autoIndexes: AutoIndex[];
};

export const ReportValidationContext = createContext<IReportValidationContext>({ reportValidations: [] });

export const useReportContext = () => useContext(ReportContext);

export const useReportValidationsContext = () => useContext(ReportValidationContext);

export const ReportContent = forwardRef(({ section, jobId }: Props, ref) => {
    const { job } = useJobContext();
    const { reportContent, isReportContentFetching, isReportContentLoading, refetchReport } = useGetReportContent(jobId, section.tableType);
    const { validations, isValidationsFetching, isValidationsLoading } = useGetReportValidations(jobId!);
    const [answers, setAnswers] = useState<ReportAnswer[]>([]);
    const updateAnswers = (answers: any[]) => setAnswers(answers);

    const getAnswers = (reports: IReport[]) => {
        let result: ReportAnswer[] = [];
        for (let report of reports) {
            if (
                report.answerControlType === AnswerControlType.WorkDoneSwitch ||
                AnswerControlType[report.answerControlType as keyof typeof AnswerControlType] === AnswerControlType.WorkDoneSwitch
            ) {
                const { id, answerText, isDisabled: any } = report;
                const autoIndexes: Array<AutoIndex | null> = [
                    report.isAuto === true ? '' : null,
                    report.isAuto1 === true ? '1' : null,
                    report.isAuto2 === true ? '2' : null,
                    report.isAuto3 === true ? '3' : null,
                ];
                result.push({
                    id: id,
                    answerText: answerText as string | undefined,
                    answerText1: report.answerText1 as string | undefined,
                    answerText2: report.answerText2 as string | undefined,
                    answerText3: report.answerText3 as string | undefined,
                    autoIndexes: autoIndexes.filter((ai) => ai !== null) as AutoIndex[],
                });
            }
            if (report.children && report.children.length > 0) {
                result = result.concat(getAnswers(report.children));
            }
        }
        return result;
    };

    useEffect(() => {
        const reports = reportContent?.data?.reports?.length
            ? getAnswers(
                  ((reportType: TableType, report: IReport[]) => {
                      if (reportType === TableType.ClassSuperRealisedCapitalGainsReport) {
                          return [...(report[0].details as any[]), ...(report[0].summary as any[])];
                      }
                      return [...report];
                  })(reportContent?.data?.reports[0].reportType, reportContent?.data?.reports[0].report)
              )
            : [];
        setAnswers(reports);
    }, [reportContent?.data?.reports]);

    useImperativeHandle(ref, () => ({
        refresh() {
            refetchReport();
        },
    }));

    const isReportsLoading = useMemo(() => {
        return isReportContentLoading || isReportContentFetching;
    }, [isReportContentLoading, isReportContentFetching]);

    if (isReportsLoading) return <Loading />;

    const templateProps = {
        items: reportContent?.data?.reports[0].report || [],
        isFetching: isReportContentFetching,
    } as ReportTemplateTypeProps;

    const Template = getReportTemplate(job.clientPortalSoftwareType, section.tableType);

    if (Template)
        return (
            <ReportContext.Provider
                value={{
                    answers: answers,
                    updateAnswers: updateAnswers,
                }}>
                <ReportValidationContext.Provider value={{ reportValidations: validations }}>
                    <Template {...templateProps} />
                </ReportValidationContext.Provider>
            </ReportContext.Provider>
        );

    return <div></div>;
});
