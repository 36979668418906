import axios, { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context } from 'utils';
import { IAttachment } from './useGetAttachments';

interface IGetDisplayDocumentsParams {
  fundId: number;
  jobId: number;
  tabId?: number;
  year: number;
  getAll: boolean;
  onlyPermanent: boolean;
}

function useGetDisplayDocuments({ fundId, jobId, tabId, year, getAll, onlyPermanent }: IGetDisplayDocumentsParams) {
  const context = React.useContext(Context);

  const { data: attachmentsData, isLoading: isAttachmentsLoading, refetch: refetchAttachments, isFetching: isAttachmentsFetching } = useQuery(
    ['getDisplayDocuments', fundId, jobId, tabId, year, getAll, onlyPermanent],
    () => axios.get<IAttachment[]>(
      `${context?.config?.annotationsUrl}/api/1.0/funds/${fundId}/jobs/${jobId}/displayDocuments?getAll=${getAll}&year=${year}&onlyPermanent=${onlyPermanent}&tabId=${tabId}`,
      commonHeaders(),
    ),
    {
      enabled: !!context?.config?.annotationsUrl && !!context.tenantId,
    
      retry: false,
    }
  );

  return { attachmentsData, isAttachmentsLoading, refetchAttachments, isAttachmentsFetching };
}

function useGetDisplayAllDocuments({ fundId, jobId, year }: Pick<IGetDisplayDocumentsParams, 'jobId' | 'fundId' | 'year'>) {
  const context = React.useContext(Context);

  const { data: attachmentsData, isLoading: isAttachmentsLoading, refetch: refetchAttachments, isFetching: isAttachmentsFetching } = useQuery(
      ['getDisplayAllDocuments', fundId, jobId],
      () => axios.get<IAttachment[]>(
          `${context?.config?.annotationsUrl}/api/1.0/funds/${fundId}/jobs/${jobId}/displayDocuments?getAll=true&year=${year}`,
          commonHeaders(),
      ),
      {
        enabled: !!context?.config?.annotationsUrl && !!context.tenantId,

        retry: false,
      }
  );

  return { attachmentsData, isAttachmentsLoading, refetchAttachments, isAttachmentsFetching };
}

export { useGetDisplayDocuments, useGetDisplayAllDocuments };