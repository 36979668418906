import {useGetOmlItems, useGetRaisedOmlItems} from "./hooks/useGetOmlItems";
import {useJobContext} from "../../JobPortalLayoutPage";
import {TableType} from "../../../../enums";
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {IJobOmlItem} from "./interfaces/IJobOmlItem";
import {
    ControlledCheckbox,
    ControlledTextField,
    DataTable,
    DialogMessage,
    ITableColumn,
    Loading
} from "../../../../components";
import { ActionButton, IGroup, Label, PrimaryButton, Stack, TextField, useTheme, Text, mergeStyleSets, DialogType } from '@fluentui/react';
import {FieldValue, useFieldArray, useForm} from "react-hook-form";
import {DefaultFormSettings} from "../../../../constants";
import {useIntl} from "react-intl";
import {useUpdateJobOmlItems} from "./hooks/useUpdateJobOmlItems";
import {ProceduresDataTable} from "../ProceduresDataTable";
import {fi} from "date-fns/locale";
import {useCreateJobOmlItems} from "./hooks/useCreateJobOmlItems";
import {useSectionContext} from "../Section";
import { Editor, EditorState } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { IDialogConfig } from "../templates/procedures/Administration/interfaces";
import { useBoolean } from '@fluentui/react-hooks';

type JobOmlFormProps = {
    itemId: number;
    tableType: TableType;
    onAfterSubmit: (items: IJobOmlItem[]) => void;
    showRaisedItems: boolean;
    showNonWitholdingColumn: boolean;
    disabled?: boolean
}
export const JobOmlForm: FunctionComponent<JobOmlFormProps> = ({   itemId,
                                                                   tableType, 
                                                                   onAfterSubmit, showRaisedItems = true, 
                                                                   showNonWitholdingColumn = true,
                                                                   disabled = false
                                                               }) => {
    const {jobId} = useJobContext();
    const {formatMessage} = useIntl();
    const theme = useTheme();

    const DefaultDialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        closeButtonAriaLabel: 'Close',
    };
    
    const {section} = useSectionContext();
    const [editingIndex, setEditingIndex] = useState<number>();
    const {response, isLoading, isFetching} = useGetOmlItems(jobId, itemId, tableType);
    const {response: raisedResponse, isLoading: isRaisedLoading, isFetching: isRaisedFetching} = useGetRaisedOmlItems(jobId, itemId, tableType);
    
    const {updateAsync, isUpdating} = useUpdateJobOmlItems();
    const {createAsync, isCreating} = useCreateJobOmlItems();

    const [showConfirmDialog, {toggle: toggleConfirmDialog, setFalse: setShowConfirmDialogFalse}] = useBoolean(false);
    const [confirmDialogConfig, setConfirmDialogConfig] = useState<IDialogConfig | null>(null);
    
    const [touchedOmls, setTouchedOmls] = useState<number[]>([]);
    const [touchedRaisedOmls, setTouchedRaisedOmls] = useState<number[]>([]);
    const refs = useRef<any>([]);

    const {control, setValue, handleSubmit, getValues, watch} = useForm<{ items: IJobOmlItem[] }>({
        ...DefaultFormSettings
    });
    const {fields, append} = useFieldArray({
        control,
        name: 'items'
    });
    
    const {control: raisedControl, setValue: setRaisedValue, handleSubmit: handleRaisedSubmit} = useForm<{ items: IJobOmlItem[] }>({
        ...DefaultFormSettings
    });
    const {fields: raisedFields} = useFieldArray({
        control: raisedControl,
        name: 'items'
    });
    
    const onNonWitholdingChange = (newValue: boolean, index: number) => {
        if (newValue) {
            setValue(`items.${index}.isSelected`, newValue)
        }
    }
    
    const closeConfirmDialog = useCallback(() => {
        setConfirmDialogConfig(null);
        setShowConfirmDialogFalse()
    }, [showConfirmDialog, confirmDialogConfig])
    
    const onSelectionChange = useCallback((newValue: boolean, index: number, callback?: Function) => {
        const _callback = (value: boolean) => {
            setValue(`items.${index}.isNonFundamental`, value);
            callback?.()
        }
        
        if (!newValue) {
            const isLinkedToMultiple = getValues(`items.${index}.isLinkedToMultipleItems`)
            const queryItemId = getValues(`items.${index}.itemId`)

            if (isLinkedToMultiple && itemId === queryItemId) {
                setConfirmDialogConfig((prev) => ({
                    contentProps: {
                        ...DefaultDialogContentProps,
                        title: formatMessage({ id: 'warning' }),
                    },
                    showOk: true,
                    okText: formatMessage({ id: 'ok' }),
                    onClick: () => {
                        _callback(newValue);
                        closeConfirmDialog();
                    },
                    onCancel: () => {
                        setValue(`items.${index}.isSelected`, !newValue);
                        closeConfirmDialog();
                    },
                    content: (
                        <Stack>
                            <Stack.Item>
                                <Text>{formatMessage({ id: 'queryLinkedToMultipleWarning' })}</Text>
                            </Stack.Item>
                        </Stack>
                    ),
                }));
                toggleConfirmDialog();
            } else {
                _callback(newValue);
            }
        } else {
            callback?.();
        }
    }, [itemId])
    
    const onOmlTouch = (id: number) => {
        if (!id) {
            return;
        }
        
        setTouchedOmls(prev => {
            if (!prev.includes(id)) {
                // console.debug("[ON_TOUCH]", id);
                return [...prev, id];
            }
            return prev;
        })
    }

    const onRaisedOmlTouch = (id: number) => {
        if (!id) {
            return;
        }

        setTouchedRaisedOmls(prev => {
            if (!prev.includes(id)) {
                // console.debug("[ON_TOUCH_RAISED]", id);
                return [...prev, id];
            }
            return prev;
        })
    }
    
    const [editorState, setEditorState] = useState<{ [key: string]: EditorState }>({})
    
    const setEditMode = (index: number) => {
        setEditingIndex(index);
    }

    const columns: ITableColumn[] = useMemo(() => {
        const result: ITableColumn[] = [
            {
                key: 'selection',
                name: '',
                minWidth: 60,
                maxWidth: 60,
                onRender: (field, index) => {
                    return (
                        <Stack horizontalAlign={'center'} grow>
                            {index !== undefined ? (
                                <ControlledCheckbox name={`items.${index}.isSelected`} 
                                                    onValueChange={(newValue: boolean) => {
                                                        onSelectionChange(newValue, index, () => onOmlTouch(field.dbId))
                                                    }}
                                                    control={control}
                                                    disabled={field.isPublished || disabled}
                                />
                            ) : null}
                        </Stack>
                    )
                }
            },
            {
                key: 'text',
                name: '',
                fieldName: 'text',
                minWidth: 150,
                onRender: (field, index) => {
                    return (
                        <Stack
                            grow
                            onClick={() => setEditMode(index!)}
                            styles={{
                                root: {
                                    '.ms-TextField-fieldGroup, .ms-TextField-fieldGroup:hover': {
                                        'border-color': 'transparent',
                                        '> textarea': {
                                            resize: 'vertical',
                                        },
                                    },
                                },
                            }}>
                            {index !== undefined ? (
                                <ControlledTextField
                                    elementRef={(el) => {
                                        refs.current[index!] = el;
                                        const textAreaElements = el?.getElementsByTagName('textarea');
                                        if (el && textAreaElements?.length) {
                                            textAreaElements[0].setAttribute('style', `height: ${textAreaElements[0].scrollHeight}px;`)
                                        }
                                    }}
                                    name={`items.${index}.text`}
                                    control={control}
                                    onChange={() => {
                                        onOmlTouch(field.dbId);
                                    }}
                                    multiline={true}
                                    disabled={field.isPublished || disabled}
                                />
                            ) : (
                                <></>
                            )}
                        </Stack>
                    );
                }
            }
        ]
        
        if (showNonWitholdingColumn) {
            result.push({
                key: 'nonWitholding',
                name: formatMessage({id: 'nonWithholdingQueries'}),
                fieldName: 'text',
                minWidth: 150,
                onRender: (field, index) => {
                    return (
                        <Stack horizontalAlign={'center'} grow>
                            {index !== undefined ? (
                                <ControlledCheckbox name={`items.${index}.isNonFundamental`} 
                                                    onValueChange={(newValue: boolean) => {
                                                        onNonWitholdingChange(newValue, index)
                                                        onOmlTouch(field.dbId)
                                                    }} 
                                                    control={control}
                                                    disabled={field.isPublished || disabled || field.itemId !== itemId}
                                />
                            ) : null}
                        </Stack>
                    )
                }
            })
        }
        
        return result;
    }, [formatMessage, showNonWitholdingColumn, editingIndex, editorState, disabled]);

    const raisedColumns: ITableColumn[] = useMemo(() => [
        {
            key: 'selection',
            name: '',
            minWidth: 60,
            maxWidth: 60,
            onRender: (field, index) => {
                return (
                    <Stack horizontalAlign={'center'} grow>
                        {index !== undefined ? (
                            <ControlledCheckbox name={`items.${index}.isSelected`} onValueChange={() => onRaisedOmlTouch(field.dbId)} disabled={field.itemId === itemId || disabled} control={raisedControl}/>) : null}
                    </Stack>
                )
            }
        },
        {
            key: 'order',
            name: '',
            fieldName: 'order',
            maxWidth: 30,
            minWidth: 30,
            onRender: (field, index) => {
                return (
                    <Stack grow horizontalAlign={'center'}>
                        {index !== undefined && <Label theme={theme.schemes?.default}>{field.uiOrder}</Label>}
                    </Stack>
                )
            }
        },
        {
            key: 'text',
            name: '',
            fieldName: 'text',
            minWidth: 150,
            onRender: (field, index) => {
                return (
                    <Stack grow>
                        {index !== undefined && <Label theme={theme.schemes?.default} style={{ whiteSpace: 'pre-wrap' }}>{field.text}</Label>}
                    </Stack>
                )
            }
        },
    ], [formatMessage, disabled]);
    
    const onSubmit = useCallback(() => {
        handleSubmit((items: {items: Array<IJobOmlItem & any>}) => {
            updateItems(items, touchedOmls, 'ORDINARY', () => {
                handleRaisedSubmit((raisedItems: {items: Array<IJobOmlItem & any>}) => {
                    updateItems(raisedItems, touchedRaisedOmls, 'RAISED', () => onAfterSubmit(raisedItems.items))
                })()
            });
        })();
    }, [touchedOmls.length, touchedRaisedOmls.length])

    const updateItems = (items: { items: Array<IJobOmlItem & any> }, touched: number[], type: 'ORDINARY' | 'RAISED', onSuccess?: Function) => {
        const toUpdate = items.items.filter(x => x.id !== 0 && touched.includes(x.dbId));
        const toCreate = items.items.filter(x => x.id === 0);
        
        const onSuccessInternal = () => {
            console.debug("[ON SUCCESS]", type);
            onSuccess?.();
        }
        const toCreateFn = () => {
            if (toCreate.length) {
                console.debug("[CREATE]", type);
                createAsync({
                    jobId: jobId,
                    omls: toCreate.map(x => { return {...x, sectionId: section.id, tableType: tableType} })
                }, {
                    onSuccess: () => {
                        console.debug("[CREATE COMPLETED]", type);
                        onSuccessInternal()
                    }
                })
            } else {
                onSuccessInternal();
            }
        }
        
        if (toUpdate.length) {
            console.debug("[UPDATE]", type);
            updateAsync({
                jobId: jobId,
                omls: toUpdate.map(x => ({...x, forItemId: itemId, tableType: tableType}))
            }, {
                onSuccess: () => {
                    console.debug("[UPDATE COMPLETED]", type);
                    toCreateFn()
                }
            });
        } else {
            toCreateFn();
        }
    }

    const globalLoading = useMemo(() => isLoading || isUpdating || isCreating || isFetching || isRaisedLoading || isRaisedFetching, 
        [isLoading, isUpdating, isCreating, isFetching, isRaisedLoading, isRaisedFetching]);

    const groups = useMemo<IGroup[]>(() => {
        return [
            {
                key: 'items',
                name: formatMessage({id: 'standardPoints'}),
                startIndex: 0,
                count: fields.filter(x => !x.isEditing).length,
                hasMoreData: false,
            },
            {
                key: 'customItems',
                hasMoreData: false,
                name: formatMessage({id: 'customPoints'}),
                startIndex: fields.filter(x => !x.isEditing).length,
                count: fields.filter(x => x.isEditing).length
            }
        ]
    }, [fields]);

    const raisedGroup = useMemo<IGroup[]>(() => {
        return [
            {
                key: 'raised',
                name: formatMessage({id: 'raisedQueries'}),
                startIndex: 0,
                count: raisedFields.length,
                hasMoreData: false,
            }
        ]
    }, [raisedFields]);
    
    const handleOnAdd = () => {
        append({
            id: 0,
            jobId: jobId,
            itemId: itemId,
            isCustom: true,
            isSelected: true,
            tableType: tableType,
            order: fields.length ? fields[fields.length - 1].order + 1 : 0,
            text: '',
            isEditing: true
        });
    }
    
    useEffect(() => {
        const items = [...(response || []),];
        setValue('items', items.map(x => ({...x, dbId: x.id})));

        refs.current = refs.current.slice(0, items?.length ?? 0);
    }, [response]);
    
    useEffect(() => {
        const items = [...(raisedResponse || []),];
        setRaisedValue('items', items.map(x => ({...x, dbId: x.id})));
    }, [raisedResponse]);

    const handleClickOutside = (event: any) => {
        if((event.srcElement.id as string).includes('TextField')) {
            return;
        }
        setEditingIndex(undefined);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
   
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    
    const classNames = mergeStyleSets({
        standardPointTable: {
            '.ms-DetailsRow-cell': {
                'white-space': 'pre-wrap',
                'min-height': 44
            }
        },
        footer: {
            ':last-child': {
                'margin-top': 'auto'
            }
        },
        content: {
            'margin-bottom': '32px',
            'flex': 1,
            'display': 'flex',
            'flex-direction': 'column'
        },
        container: {
            'sapn:last-child': {
                'margin-top': 'auto'
            }
        },
        stdPoints: {
            
        },
        customStdPoints: {
            'margin-top': 'auto'
        },
        loader: {
            margin: 'auto'
        },
        dialog: {
            '.ms-Dialog-main': {
                minWidth: '500px',
                backgroundColor: 'red',

                '.ms-Dialog-inner': {
                    marginLeft: 16
                }
            }
        }
    })
    
    if (isFetching || isRaisedFetching)
        return (
            <Stack horizontal horizontalAlign='center' className={classNames.loader}>
                <Stack.Item>
                    <Loading/>
                </Stack.Item>
            </Stack>
        )

    return (
        <Stack tokens={{childrenGap: 32}} grow={1} className={classNames.container}>
            <Stack.Item className={classNames.content}>
                <Stack.Item className={classNames.stdPoints}>
                    <ProceduresDataTable className={classNames.standardPointTable} items={fields} hideIfEmpty={false} groups={groups} enableShimmer={globalLoading}
                                         columns={columns}/>
                </Stack.Item>
                <Stack.Item className={classNames.customStdPoints}>
                    <Stack>
                        
                        <Stack horizontalAlign={"center"}>
                            {!fields.some(x => x.isEditing) && (
                                <ActionButton theme={theme.schemes?.default} iconProps={{iconName: 'add'}}
                                              disabled={globalLoading || disabled}
                                              onClick={handleOnAdd}>{formatMessage({id: 'clickToAddCustomPoint'})}</ActionButton>)}
                        </Stack>
                {raisedFields.length && showRaisedItems &&
                    <Stack.Item>
                        <ProceduresDataTable items={raisedFields} isHeaderVisible={false} groups={raisedGroup} enableShimmer={globalLoading}
                                             columns={raisedColumns}/>
                    </Stack.Item>
                }
                        
                        
                    </Stack>
                </Stack.Item>
            </Stack.Item>
            <span className={classNames.footer}>
                <Stack horizontal horizontalAlign='end' tokens={{childrenGap: 16}}>
                    <PrimaryButton disabled={isUpdating || disabled} text={formatMessage({id: 'save'})}
                                   onClick={onSubmit}/>
                </Stack>
            </span>

            {confirmDialogConfig && <Stack className={classNames.dialog}>
                <DialogMessage onClick={confirmDialogConfig.onClick} onDismis={confirmDialogConfig.onCancel}
                               dialogContentProps={confirmDialogConfig.contentProps}
                               hidden={!showConfirmDialog}
                               showOk={confirmDialogConfig.showOk}
                               okText={confirmDialogConfig.okText}
                               cancelText={formatMessage({id: 'close'})}>
                    {confirmDialogConfig.content}
                </DialogMessage>
            </Stack>}

        </Stack>
    );
}