import axios, { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { commonHeaders, Context,  queryClient } from 'utils';
import * as localStorageService from "../../../../../services/localStorageService";
import { REFRESH_TOKEN_KEY } from "../../../../../constants/authorization";
import { useLocation } from 'react-router-dom';

type TitleRes = {
    companyTitle: string;
};

/**Query for get general Title data in Portal Settings*/

function useGetGeneralTitle() {
    const context = React.useContext(Context);

    const { data: generalTitle, isLoading } = useQuery(
        ['getGeneralTitle'],
        () => axios.get<TitleRes>(`${context?.config?.settingsUrl}/api/1.0/Settings/web`, commonHeaders()),
        {
            enabled: !!context?.config?.settingsUrl && !!context.tenantId,
        }
    );

    return { generalTitle, isLoading };
}

function useGetGeneralTitleByTenant(url: string, tenantId: string) {
    const refreshToken: string = localStorageService.getData(REFRESH_TOKEN_KEY);
    const location = useLocation();

    const { data: generalTitle, isLoading } = useQuery(
        ['getGeneralTitle'],
        () => axios.get<TitleRes>(`${url}/api/1.0/Settings/web`, commonHeaders()),
        {
            enabled: !!url && !!tenantId && !!refreshToken && refreshToken !== 'undefined' && (!location.search.includes('?Code=') || !location.search.includes('&Code=')),
        }
    );

    return { generalTitle, isLoading };
}

export { useGetGeneralTitle, useGetGeneralTitleByTenant };