import { PrimaryButton, Stack } from "@fluentui/react";
import { FunctionComponent, useCallback, useRef } from "react";
import Dropzone from "react-dropzone";
import { useIntl } from "react-intl";
import { TableType } from "../../../../../../enums";
import { useCreateAttachment } from "../../hooks/useCreateAttachment";
import { useAttachmentsContext } from "./AttachmentsContext";

export const UploadAttachment: FunctionComponent = () => {
    const attachmentsContext = useAttachmentsContext();
    const { createAttachment, isLoading: isCreateLoading } = useCreateAttachment();
    const ref = useRef<HTMLInputElement>(null);
    const { formatMessage } = useIntl();

    const upload = useCallback((selectedFile) => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('fileName', selectedFile.name);
        createAttachment({
            fundId: attachmentsContext.fundId,
            jobId: attachmentsContext.jobId,
            clientId: attachmentsContext.clientId,
            itemId: attachmentsContext.itemId,
            tableType: attachmentsContext.tableType,
            data: formData,
        });
    }, [])

    const onUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (!fileList?.length) return;

        Array.from(fileList).forEach(file => {
            upload(file);
        });
    }, []);

    const onDrop = useCallback(files => {
        if (!files?.length) return;

        Array.from(files).forEach(file => {
            upload(file);
        });
    }, [])

    return (
        <Dropzone onDrop={onDrop} noDragEventsBubbling={true}>
            {({ getRootProps, getInputProps, inputRef }) => (
                <div {...getRootProps()}>
                    <Stack styles={{ root: { width: 'max-content' } }}>
                        <PrimaryButton
                            disabled={isCreateLoading}
                            iconProps={{ iconName: 'Upload' }}
                            width='100%'
                            text={formatMessage({ id: 'uploadAttachment' })}
                        >
                        </PrimaryButton>
                        <input
                            name='attachmentBlob'
                            ref={inputRef}
                            style={{ display: 'none' }}
                            type='file'
                            onChange={onUpload}
                            disabled={isCreateLoading}
                            {...getInputProps()}
                        />
                    </Stack>

                </div>
            )}
        </Dropzone>

    );
};
