import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { mergeStyleSets, useTheme } from "@fluentui/react";
import { useIntl } from "react-intl";
import { ProceduresDataTable, useProcedureContext } from "pages/JobPortal/components";
import { SanitizedText } from "components";
import { ColumnWithSize } from "../../../interfaces";
import { WorkDoneSwitchAnswer } from "../answers";
import { ColumnInfo } from "../../../enums";
import { useTabContext } from "../../../JobPortalPage";
import { useGetProcedures, useUpdateJobRiskLevel } from "../../../hooks";
import { useJobContext } from "../../../JobPortalLayoutPage";
import { ControlType } from "../../../../../enums/ControlType";
import { useTenantInfo } from "../../../../../providers";
import { references } from "./RiskMatrix/RiskCalculator";
import { useUpdateItemAnswer } from "../../../hooks/answers";
import { TableType } from "enums";
import { useGetFeeCalculationSection, useSaveFeeCalculationItem } from "../feeCalculation/hooks";
import { FeeCalculationAnswer } from "../feeCalculation/Types";

type RiskCategorizationItem = {
    id: number;
    riskName: string;
    description?: string;
    answerText?: string | null;
    defaultAnswerText?: string;
    jobId: number;
    yesComment: string;
    noComment: string;
    order: number;
    tableType: number;
    
    hasAnswerControl: boolean;
    isAnswerSet: boolean;
    isPositiveAnswer: boolean;
    isNegativeAnswer: boolean;
} & { enabled: boolean };

enum RiskLevelOption {
    Yes,
    No
}

export const RiskCategorizationProcedureTemplate: FunctionComponent = () => {
    const _RiskAssessmentSectionReference: string = 'Risk assessment';
    const _RmSectionReference: string = 'Risk matrix';
    const _PositiveAnswerValue: string = 'yes';
    const _NegativeAnswerValue: string = 'no';
    
    const { formatMessage } = useIntl();
    const theme = useTheme();
    
    const { isBlackLabel } = useTenantInfo();
    const { job } = useJobContext()
    const { sections, refreshSection } = useTabContext();
    
    const riskMatrixSection = useMemo(() => {
        return ((sections ?? []).find(s => s.reference?.toLowerCase() === _RiskAssessmentSectionReference.toLowerCase())?.children ?? []).find(s => s.reference?.toLowerCase() === _RmSectionReference.toLowerCase())
    }, [sections])
    
    const { proceduresData } = useGetProcedures({ jobId: job.id, jobYear: job.year, templateId: job.templateId, controlType: ControlType.RiskMatrix, sectionId: riskMatrixSection!.id, fundId: job.fund.id })
    const relatedRmProcedure = useMemo(() => {
        const ref = isBlackLabel ? references.areAllOfInvestmentIdentifiedForCRM : references.areAllOfInvestmentIdentifiedForWL;
        return (proceduresData?.data?.items ?? []).find(x => x.reference === ref);
    }, [proceduresData, isBlackLabel]);

    const { feeCalculationSectionData } = useGetFeeCalculationSection({ jobId: job.id, jobGuid: job.guid, feeMatrixVersion: 2 });
    const relatedFmProcedure = useMemo(() => {
        return (feeCalculationSectionData?.data?.feeCalculationLineItems ?? []).at(0);
    }, [relatedRmProcedure]);
    
    const { items, isLoading } = useProcedureContext();

    const answerRefs = useRef<{ [index: number]: any }>({});
    
    const [updateTick, setUpdateTick] = useState<number | null>(null);

    const [riskLevelId, setRiskLevelId] = useState<number>();
    const [riskLevelOption, setRiskLevelOption] = useState<RiskLevelOption>();

    const {update, isUpdating} = useUpdateItemAnswer();
    const {update: updateFmItem} = useSaveFeeCalculationItem();
    const { update: updateJobRiskLevel } = useUpdateJobRiskLevel(job.guid);
    
    const mapItem = (item: RiskCategorizationItem, index: number, arr: RiskCategorizationItem[]): RiskCategorizationItem => {
        
        const newItem = {
            ...item,
            isAnswerSet: !!item.answerText,
            isPositiveAnswer: item.answerText?.toLowerCase() === _PositiveAnswerValue,
            isNegativeAnswer: item.answerText?.toLowerCase() === _NegativeAnswerValue,
        }
        
        if (index === 0) {
            return { ...newItem, enabled: true }
        }
        
        const prevRiskItem = arr
            .filter(x => x.hasAnswerControl && x.id < newItem.id)
            .at(-1);
        
        return { ...newItem, enabled: !!prevRiskItem?.isNegativeAnswer }
    }

    const tableItems = useMemo(() => {
        const result = items.map(mapItem);
        
        if (isBlackLabel && answerRefs.current) {
            // calculate and update risk level is needed
            let riskId: number = -1;
            let option: RiskLevelOption = RiskLevelOption.Yes;

            if (result[0].answerText?.toLowerCase() === _PositiveAnswerValue) {
                riskId = result[0].id;
            } else if (result[1].answerText?.toLowerCase() === _PositiveAnswerValue) {
                riskId = result[1].id;
            } else if (result[2].answerText?.toLowerCase() === _PositiveAnswerValue) {
                riskId = result[2].id;
            } else if (result[11].answerText?.toLowerCase() === _PositiveAnswerValue) {
                riskId = result[11].id;
            } else if (result[12].answerText?.toLowerCase() === _PositiveAnswerValue) {
                riskId = result[12].id;
            } if (result[12].answerText?.toLowerCase() === _NegativeAnswerValue) {
                riskId = result[12].id;
                option = RiskLevelOption.No;
            }
            
            if (riskId !== riskLevelId || option !== riskLevelOption) {
                updateJobRiskLevel({
                    riskLevelId: riskId,
                    riskLevelOption: option
                }, {
                    onSuccess: () => {
                        setRiskLevelId(riskId);
                        setRiskLevelOption(option)
                    }
                })
            }
        }
        
        return result;
    }, [items, updateTick, isBlackLabel, answerRefs.current, riskLevelId, riskLevelOption]);

    const onAnswerUpdated = useCallback((answer: string | null, index: number, item: RiskCategorizationItem) => {
        const isPositiveAnswer = answer?.toLowerCase() === _PositiveAnswerValue;
        const isNegativeAnswer = answer?.toLowerCase() === _NegativeAnswerValue;
        
        items[index] = {
            ...items[index],
            answerText: answer,
            isAnswerSet: !!answer,
            isPositiveAnswer: isPositiveAnswer,
            isNegativeAnswer: isNegativeAnswer,
        }
        
        setUpdateTick(Date.now())
        
        const upperKey = Object
            .keys(answerRefs.current)
            .filter(k => +k > index)
            .map(k => +k)
            .at(0);
        
        if (upperKey && items[upperKey].isAnswerSet) {
            answerRefs.current[upperKey].reset()
        }
        
        if (index === 0 && relatedRmProcedure) {
            // to update related RM procedure answer
            update({
                jobId: job.id,
                tableType: TableType.AuditProcedure,
                itemId: relatedRmProcedure.id,
                text: answer,
                columnInfo: ColumnInfo.Text
            }, {
                onSuccess: () => {
                    refreshSection?.(_RiskAssessmentSectionReference);
                }
            })
        }
        
        if (index === 0 && relatedFmProcedure && isBlackLabel) {
            // to update related FM procedure answer
            updateFmItem({
                jobId: job.id,
                jobGuid: job.guid,
                feeCalculationId: relatedFmProcedure.feeCalculationModel.id,
                feeAmount: null,
                howManyQuantity: null,
                manualFeeAmount: null,
                answer: isPositiveAnswer ? FeeCalculationAnswer.Yes : isNegativeAnswer ? FeeCalculationAnswer.No : null
            })
        }
        
    }, [items, relatedRmProcedure, relatedFmProcedure, isBlackLabel]);

    useEffect(() => {
        answerRefs.current = items.reduce((acc: { [index: number]: any }, i: RiskCategorizationItem, index: number) => {
            if (i.hasAnswerControl) {
                acc[index] = null;
            }
            return acc;
        }, {})
    }, [items])

    const columns = useMemo<ColumnWithSize[]>(() => {
        return [
            {
                key: 'risk',
                name: formatMessage({ id: 'risk' }),
                fieldName: 'risk',
                size: 1,
                onRender: (item: RiskCategorizationItem) => <SanitizedText data={item.riskName} />,
            },
            {
                key: 'description',
                name: formatMessage({ id: 'description' }),
                fieldName: 'description',
                size: 7,
                onRender: (item: RiskCategorizationItem) => (
                    <SanitizedText
                        color={item.enabled ? theme.schemes?.default?.semanticColors.bodyText: theme.semanticColors.disabledBodySubtext }
                        data={item.description ?? ''}
                    />
                ),
            },
            {
                key: 'riskDone',
                name: formatMessage({ id: 'riskDone' }),
                fieldName: 'riskDone',
                size: 1,
                onRender: (item: RiskCategorizationItem, index?: number) => 
                    item.hasAnswerControl
                        ? <WorkDoneSwitchAnswer ref={el => answerRefs.current[index!] = el}
                                                value={item.answerText ?? undefined}
                                                itemId={item.id}
                                                tableType={item.tableType}
                                                columnInfo={ColumnInfo.Text}
                                                disabled={!item.enabled}
                                                onUpdate={answer => onAnswerUpdated(answer, index!, item)}
                        />
                        : <></>,
            },
            {
                key: 'comments',
                name: formatMessage({ id: 'comments' }),
                fieldName: 'comments',
                size: 4,
                onRender: (item: RiskCategorizationItem) => 
                    item.hasAnswerControl && item.isAnswerSet
                        ? <SanitizedText color={ item.isPositiveAnswer ? theme.palette.red : theme.schemes?.default?.semanticColors.bodyText }
                                         data={item.isPositiveAnswer ? item.yesComment : item.noComment} />
                        : <></>,
            },
        ]
    }, [formatMessage]);
    
    const classNames = mergeStyleSets({
        table: {
            'div.ms-DetailsRow': {
                minHeight: '44px'
            }
        }
    })
    
    return (
        <ProceduresDataTable
            className={classNames.table}
            items={tableItems}
            isLoading={isLoading}
            columns={columns}
        />
    );
};
