import { DefaultButton, IColumn, Link, PrimaryButton, SelectionMode, Spinner, Stack, Text, Checkbox, useTheme } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { JobPortalExternalPageContent, useJobContext } from ".";
import { DataTable, DisabledChildren, Modal, Search } from '../../components';
import { AttachmentInfo } from './components';
import { useGetDisplayAllDocuments, useGetDisplayDocuments, useUpdateAttachmentMarkAsPerm, useUpdateAttachmentUnmarkAsPerm } from './hooks';
import { useWorkContext } from 'providers';

interface IDisplayDocumentsProps {
    onlyPermanent: boolean;
    disabled?: boolean;
}

const DisplayAllDocumentContent: FunctionComponent<IDisplayDocumentsProps> = ({ onlyPermanent, disabled }) => {
    const { markAsPerm } = useUpdateAttachmentMarkAsPerm();
    const { unmarkAsPerm } = useUpdateAttachmentUnmarkAsPerm();
    const { formatMessage } = useIntl();
    const { job } = useJobContext();
    const [getAll, setGetAll] = useState(false);

    const { workContext } = useWorkContext();
    
    const [isEditDisabled, setIsEditDisabled] = useState<boolean>(!!workContext?.job?.isDispatched)

    const { attachmentsData, isAttachmentsLoading, isAttachmentsFetching } = useGetDisplayAllDocuments({
        jobId: job.id,
        fundId: job.fund?.id,
        year: job.year
    });

    const toggleMarkAsPerm = (id: number, itemId: number, isPermanent: boolean) => {
        const markProps = {
            jobId: job.id,
            fundId: job.fund?.id,
            itemId,
            id,
            year: job.year,
        };

        if (isPermanent) {
            markAsPerm(markProps);
        } else {
            unmarkAsPerm(markProps);
        }
    };

    const [searchString, setSearchString] = useState('');

    const searchHandler = (searchString: any) => {
        setSearchString(searchString);
    };

    const clearHandler = (searchString: any) => {
        setSearchString('');
    };

    const [columns] = useState<IColumn[]>([
        {
            key: 'name',
            name: formatMessage({ id: 'name' }),
            minWidth: 350,
            fieldName: 'name',
            onRender: (item) => (
                <AttachmentInfo
                    id={item.id}
                    isPermanent={item.isPermanent}
                    attachmentJobId={item.jobId}
                    fileName={item.fileName}
                    originalFileName={item.originalFileName}
                    isEdited={item.isEdited}
                    downloadUrl={item.downloadUrl}
                    jobId={job.id}
                    editingDisabled={isEditDisabled}
                />
            ),
        },
        {
            key: 'perm',
            name: formatMessage({ id: 'perm' }),
            minWidth: 180,
            maxWidth: 180,
            fieldName: 'perm',
            onRender: (item) => (
                <Stack horizontal styles={{ root: { width: '100%' } }}>
                    <DisabledChildren disabled={!!disabled}>
                        <PrimaryButton
                            toggle
                            primary={item.isPermanent}
                            minLength={100}
                            styles={{ root: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
                            checked={item.isPermanent}
                            text={item.isPermanent ? 'Marked' : 'Mark'}
                            onClick={() => toggleMarkAsPerm(item.id, item.itemId, true)}
                        />
                        <DefaultButton
                            toggle
                            minLength={100}
                            styles={{ root: { borderBottomLeftRadius: 0, borderTopLeftRadius: 0 } }}
                            checked={!item.isPermanent}
                            text={item.isPermanent ? 'Unmark' : 'Unmarked'}
                            onClick={() => toggleMarkAsPerm(item.id, item.itemId, false)}
                        />
                    </DisabledChildren>
                </Stack>
            ),
        },
    ]);

    if (isAttachmentsLoading)
        return (
            <Stack horizontalAlign='center'>
                <Spinner />
            </Stack>
        );
    if (!attachmentsData?.data) return <Stack>No data</Stack>;
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack horizontal horizontalAlign='space-between'>
                <Stack horizontalAlign='end'>
                    <Search onSearch={searchHandler} onClear={clearHandler} placeholder={formatMessage({ id: 'searchFiles' })} />
                </Stack>
            </Stack>
            <Stack tokens={{ childrenGap: 16 }}>
                <DataTable
                    initialColumns={columns}
                    columns={columns}
                    items={
                        searchString
                            ? attachmentsData.data.filter((x) => x.fileName.toLowerCase().includes(searchString.toLowerCase()))
                            : attachmentsData.data
                    }
                    selectionMode={SelectionMode.none}
                    enableShimmer={isAttachmentsFetching}
                    containerHeight='100%'
                    emptyProps={{
                        iconName: 'ComplianceAudit',
                    }}
                />
            </Stack>
        </Stack>
    );
};

export const DisplayAllDocumentsExternalTemplate: FunctionComponent<{}> = ({}) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const { job, jobTabs } = useJobContext();
    const { isJobPortalEnabled } = useWorkContext();
    const [isTabEnabled, setIsTabEnabled] = useState<boolean>(true);

    useEffect(() => {
        setIsTabEnabled(isJobPortalEnabled);
    }, [isJobPortalEnabled]);

    const canBeMarkedAsPermant = job.dateARSent == null;

    return (
        <>
            <>
                <JobPortalExternalPageContent
                    title={formatMessage({id: 'allDocuments'})}
                    content={
                        <DisplayAllDocumentContent onlyPermanent={false} disabled={!(isTabEnabled && canBeMarkedAsPermant)} />
                    }
                    isLoading={false}
                    topStackStyles={{
                                root: {
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                    marginTop: '16px',
                                    boxShadow: theme.effects.elevation4,
                                    width: 'fit-content',
                                    minWidth: '50%'
                                }
                    }}
                />
            </>
        </>
    );
};
