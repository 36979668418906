import {FunctionComponent} from 'react';
import {FontWeights, ITextProps, Text, useTheme} from '@fluentui/react';
import * as DOMPurify from 'dompurify';

export type NumberFormatType = 'fractional' | 'percentage' | 'round' | 'percentage-fractional' | 'default' | 'text';
export type FormatType = 'Bold' | 'Normal' | 'Italic';

export interface ISanitizedTextProps extends ITextProps {
    data: string;
    format?: FormatType;
    isHeadingRow?: boolean;
    numberFormat?: NumberFormatType;
    numberFormatOptions?: Partial<Intl.NumberFormatOptions>,
    textAlign?: 'left' | 'right' | 'center';
    textWhiteSpace?: 'pre-wrap' | 'nowrap';
    minWidth?: number | string | 'auto'
}

const handleData = (data: string, numberFormat?: NumberFormatType, numberFormatOptions?: Partial<Intl.NumberFormatOptions>) => {
    if (numberFormat && (data || `${data}` === '0') && !isNaN(+data)) {
        switch (numberFormat) {
            case 'fractional':
                return (+data).toLocaleString('au-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    ...(numberFormatOptions ?? {})
                });
            case 'percentage':
                return (+data * 100).toFixed() + '%';
            case 'round':
                return Math.round(+data).toString();
            case 'percentage-fractional':
                return (+data * 100).toFixed(2) + '%';
            case 'default':
                return `${+data}`;
            case 'text':
                return `${data}`
        }
    }
    return data;
};

export const SanitizedText: FunctionComponent<ISanitizedTextProps> = ({
                                                                          data = '',
                                                                          format,
                                                                          styles = {root: {}},
                                                                          isHeadingRow,
                                                                          numberFormat = 'default',
                                                                          numberFormatOptions,
                                                                          textAlign, 
                                                                          textWhiteSpace = 'pre-wrap',
                                                                          color, 
                                                                          minWidth,
                                                                          ...props
                                                                      }: ISanitizedTextProps) => {
    const isBold = isHeadingRow || format === 'Bold';
    const theme = useTheme();
    const mergedStyles = {
        root: {
            fontWeight: isBold ? FontWeights.bold : FontWeights.regular,
            fontStyle: format === 'Italic' ? 'italic' : 'inherit',
            minWidth: minWidth ?? '100%',
            textAlign,
            color : color ?? theme.schemes?.default?.semanticColors.bodyText,
            ...(styles as any).root
        }
    }
    
    const allowedSanitizedAttrs = ['href', 'target', 'class'];
    
    return (
        <Text styles={mergedStyles} {...props}>
            <span style={{whiteSpace: textWhiteSpace, minWidth: minWidth ?? '100%', color: color}}
                  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.prefix ? `${props.prefix} ${handleData(data, numberFormat, numberFormatOptions)}` : handleData(data, numberFormat, numberFormatOptions), { ALLOWED_ATTR: allowedSanitizedAttrs})}}/>
        </Text>
    );
};