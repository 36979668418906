import React, {createRef, FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import {JobPortalExternalPageContent} from "../../JobPortalExternalLayoutPage";
import {useIntl} from "react-intl";
import {useParams} from "react-router-dom";
import {useJobContext} from "../../JobPortalLayoutPage";
import {useGetSectionById} from "./hooks";
import {ISectionContext, SectionContext} from "./context";
import {Stack} from "@fluentui/react";
import {SectionContent} from "./components";
import {Section} from "./SectionTemplate";
import {ITabContext, TabContext} from "../../JobPortalPage";
import {useUpdateSectionAnswerPositivity} from "../../hooks/answers/useUpdateSectionAnswerPositivity";
import { useWorkContext } from "../../../../providers";

type SectionExternalTemplateProps = {}

export const SectionExternalTemplate: FunctionComponent<SectionExternalTemplateProps> = ({...props}: SectionExternalTemplateProps) => {
    const {formatMessage} = useIntl();
    const {sectionId} = useParams();
    const {job} = useJobContext();
    const {isJobPortalEnabled} = useWorkContext();

    const {section, isSectionLoading, refetch, isRefetching} = useGetSectionById({
        sectionId: +sectionId!,
        jobTemplateId: job.templateId,
        jobId: job.id,
        includeSectionAnswer: true
    })

    const {update} = useUpdateSectionAnswerPositivity();

    const [isPositiveSectionAnswer, setIsPositiveSectionAnswer] = useState<boolean | null>(null);
    const childRef = createRef<any>();

    const updateSectionAnswer = useCallback((isPositive: boolean | null) => {
        if (!section) {
            return;
        }

        update({
            jobId: job.id,
            sectionId: section.id,
            isPositive: isPositive,
        }, {
            onSuccess: () => {
                setIsPositiveSectionAnswer(prev => isPositive);
            }
        });
    }, [section]);

    const updateSectionAnswerFn = () => {
        // ignoring parent section answer since there is no relative external sections yet.
        return updateSectionAnswer;
    }

    const updateSectionAnswerIcon = (isPositive: boolean | null) => {
        if (isPositive === isPositiveSectionAnswer) {
            return;
        }

        setIsPositiveSectionAnswer(isPositive);
        return;
    };

    const handleRefresh = useCallback(() => {
        (childRef?.current?.refresh ?? refetch)()
    }, [childRef])

    const sectionContextValues: ISectionContext = useMemo(() => ({
        section: section!,
        isOpened: true,
        open: () => {
        },
        close: () => {
        },
        refresh: handleRefresh,
        fullScreen: () => {
        },
        setApprovingDisable: () => {
        },
        isPositiveSectionAnswer: isPositiveSectionAnswer,
        forcePositiveSectionAnswer: (isPositive) => setIsPositiveSectionAnswer(isPositive),
        updateSectionAnswer: updateSectionAnswerFn(),
        updateSectionAnswerIcon: updateSectionAnswerIcon,
        hide: () => {
        },
        refreshSectionAnswers: () => {
        },
        sectionAnswersRefreshedTick: 0,
        enableWarningMessage: () => {
        },
        isWarningMessageForced: false,
        isWarningMessageEnabled: false,
        updateLoadedState: () => {
        },
        isLoaded: false,
        openProcedures: {
            ids: [],
            setIds: () => {},
            clear: () => {}
        },
    }), [section, isPositiveSectionAnswer]);

    const tabContextValues: ITabContext = {
        tabId: section?.tabId ?? 0,
        closeSection: () => {
        },
        closeSectionById: () => {
        },
        openSection: () => {
        },
        disableSection: () => {
        },
        refreshSection: () => {
        },
        refreshSectionById: () => {
        },
        updateSectionAnswerById: () => {
        },
        isTabEnabled: isJobPortalEnabled
    };

    useEffect(() => {
        setIsPositiveSectionAnswer(prev => !section || !section.sectionAnswer ? null : !!section!.sectionAnswer!.isPositive)
    }, [section]);

    const content = useMemo(() => {
        return (
            <>
                {section &&
                    <TabContext.Provider value={tabContextValues}>
                        <SectionContext.Provider value={sectionContextValues}>
                            <Stack tokens={{childrenGap: 16}}
                                   styles={{root: {marginLeft: 10, marginBottom: 10}}}>
                                <SectionContent childRef={childRef}/>
                                {section.children && section.children.length && section.children.map((childSection) => (
                                    <Section section={childSection}
                                             key={`job-portal-ext-section-${section.id}-${childSection.id}`}/>
                                ))}
                            </Stack>
                        </SectionContext.Provider>
                    </TabContext.Provider>

                }
            </>
        )
    }, [section, isPositiveSectionAnswer]);

    return (
        <>
            <JobPortalExternalPageContent title={section?.caption ?? ''}
                                          isLoading={isSectionLoading || isRefetching}
                                          content={content}
                                          handleRefresh={handleRefresh}
            />
        </>
    )
}