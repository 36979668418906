import {ReactElement} from "react";
import {orderSearchProcedureRowRender} from "./TrustDeed/OrderSearch";
import {IDetailsRowProps} from "@fluentui/react";
import {
    trusteeMemberProcedureRowRenderFactory,
    trusteeNameProcedureRowRender,
    trusteeTypeProcedureRowRender
} from "./TrustDeed/Trustee";
import {memberNameProcedureRowRender} from "./TrustDeed/Member";
import {
    trusteeCorporateConfirmationProcedureRowRender
} from "./TrustDeed/Trustee/TrusteeCorporateConfirmationProcedureRowRender";
import {
    trusteeIndividualConfirmationProcedureRowRender
} from "./TrustDeed/Trustee/TrusteeIndividualConfirmationProcedureRowRender";
import { ensureAlighWithStrategyProcedureRowRender } from "./InvestmentStrategy/EnsureAlignWithStrategyProcedureRowRender";

export type CustomProcedureRowRender = (
    props?: IDetailsRowProps,
    defaultRender?: (props?: IDetailsRowProps) => ReactElement | null,
    rows?: any[],
    onRowItemUpdate?: (newItem: any) => void
) => ReactElement | null;

const mapByTemplateName: { [key: string]: CustomProcedureRowRender } = {
    'tmplOrderSearchButton': orderSearchProcedureRowRender,
    'tmplMemberName': memberNameProcedureRowRender,
}

const mapByReference: { [key: string]: CustomProcedureRowRender } = (() => {
    const map: { [key: string]: CustomProcedureRowRender } = {
        'TrusteeType': trusteeTypeProcedureRowRender,
        'CorporateTrusteeName': trusteeNameProcedureRowRender,
        'Confirmalldirectorsaremembersofthefund.Annotateanydiscrepancies': trusteeCorporateConfirmationProcedureRowRender,
        'DateofASICCompanyStatement': trusteeCorporateConfirmationProcedureRowRender,
        'DateoflastchangedrecordedonASICfreesearch': trusteeCorporateConfirmationProcedureRowRender,
        'Ifindividualtrustees,confirmallindividualsaremembersofthefund.Annotateanydiscrepancies.': trusteeIndividualConfirmationProcedureRowRender,
        '<ahref=\'#6\'><div>EnsurethattheinvestmentsalignwiththeInvestmentStrategybycheckingtheanalyticalreviewinholdingssection</div></a>' : ensureAlighWithStrategyProcedureRowRender
    }
    Array(6).fill(0).forEach((_, i) => map[`NameofDirectors${i + 1}:`] = trusteeMemberProcedureRowRenderFactory('Director'));
    Array(6).fill(0).forEach((_, i) => map[`NameofTrustees${i + 1}:`] = trusteeMemberProcedureRowRenderFactory('Trustee'));
    
    return map;
})()

export const getCustomProcedureRowRender = (name: string): CustomProcedureRowRender | null => {
    name = name.replace(/\s+/g, '');
    return Object.keys(mapByTemplateName).includes(name)
        ? mapByTemplateName[name]
        : Object.keys(mapByReference).includes(name)
            ? mapByReference[name]
            : null;
}