import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
	DialogType, IDialogContentProps,
	mergeStyleSets,
	PrimaryButton,
	Spinner,
	SpinnerSize,
	Stack,
	StackItem,
	useTheme
} from "@fluentui/react";
import { PortalRole, useTenantInfo, useWorkContext } from "../../../../../../../providers";
import { useJobContext } from "../../../../../JobPortalLayoutPage";
import { useSectionContext } from "../../../../Section";
import {
	PublishInitialOptions,
	PublishInitialOptionsDefualts,
	useGetIsPaymentReceived,
	usePublishInitialAudit
} from "../hooks";
import { useBoolean } from "@fluentui/react-hooks";
import { IAuditReportOptions, IInvoiceInfo, IPublishQueriesResult } from "../interfaces";
import { IAdministrationControlProps, PublishResponseType } from "../interfaces";
import { DialogMessage, Modal } from "../../../../../../../components";
import { AuditReportOptionsForm } from "./AuditReportOptionsForm";
import { AdministrationInvoiceAddForm } from "./AdministrationInvoiceAddForm";

interface IAdministrationPublishButtonProps extends IAdministrationControlProps {

}

type OverrideDialogConfig = {
	onConfirm: () => void;
	onDismiss: () => void;
	props: IDialogContentProps;
	message: string;
}

export const AdministrationPublishInitialButton: FunctionComponent<IAdministrationPublishButtonProps> = ({disabled}: IAdministrationPublishButtonProps) => {
	const {formatMessage} = useIntl();
	const theme = useTheme();

	const {isBlackLabel} = useTenantInfo()

	const {jobId} = useJobContext();
	const {section} = useSectionContext();
	const {isInRoleOrSuperAdministrator} = useWorkContext();

	const {publish, isPublishing} = usePublishInitialAudit();
	const {isPaymentRecievedResult, isRefetching: isFetchingIsPaymentReceived, refetch: getIsPaymentReceived} = useGetIsPaymentReceived(jobId);

	const [showWarningsDialog, {toggle: toggleWarningsDialog}] = useBoolean(false);
	const [showInvoiceConfirmationDialog, {toggle: toggleInvoiceConfirmationDialog}] = useBoolean(false);
	const [showOverrideDialog, {toggle: toggleOverrideDialog}] = useBoolean(false);

	const [showAuditReportOptionsModal, {toggle: toggleAuditReportOptionsModal}] = useBoolean(false);
	const [showInvoiceModal, {toggle: toggleInvoiceModal}] = useBoolean(false);

	const [publishResultWarnings, setPublishResultWarnings] = useState<string[]>([]);

	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	
	const [isDraftAuditReportRequired, setIsDraftAuditReportRequired] = useState<boolean | null>(null);
	const [isInvoiceEnabled, setIsInvoiceEnabled] = useState<boolean>(false);
	const [auditReportOptions, setAuditReportOptions] = useState<IAuditReportOptions | undefined | null>(null);
	const [invoiceInfo, setInvoiceInfo] = useState<IInvoiceInfo | undefined | null>(null);

	const [publishInitialResult, setPublishInitialResult] = useState<IPublishQueriesResult | null>(null);
	
	const [sendEmail, setSendEmail] = useState<boolean>(false);

	const _DefaultDialogContentProps: IDialogContentProps = {
		theme: theme.schemes?.default,
		type: DialogType.normal,
		closeButtonAriaLabel: 'Close'
	}
	
	const [overrideDialogConfig, setOverrideDialogConfig] = useState<OverrideDialogConfig | null>(null);

	const isButtonDisabled = useMemo(() => {
		return !!disabled || isProcessing || !isInRoleOrSuperAdministrator(PortalRole.Administrator, PortalRole.PowerUser)
	}, [disabled, isProcessing])

	useEffect(() => {
		setSendEmail(!!section?.sectionAnswer?.sendEmail);
	}, [section.sectionAnswer]);

	useEffect(() => {
		resetProcess()
		return () => {}
	}, []);
	
	const resetProcess = () => {
		setPublishResultWarnings([])
		setPublishInitialResult(null)
		setIsDraftAuditReportRequired(null);
		setAuditReportOptions(null);
		setInvoiceInfo(null);
		setIsInvoiceEnabled(false);
		setOverrideDialogConfig(null);
		setIsProcessing(false);
	}
	
	const startProcess = () => {
		setPublishResultWarnings([])
		setIsProcessing(true);
	}
	const endProcess = () => {
		setIsDraftAuditReportRequired(null);
		setAuditReportOptions(null);
		setInvoiceInfo(null);
		setOverrideDialogConfig(null);
		setIsProcessing(false);
	}

	const handleOnClick = useCallback(() => {
		startProcess()
	}, [])

	useEffect(() => {
		if (isProcessing) {
			publish({
				...PublishInitialOptionsDefualts,
				jobId: jobId,
				sendEmail: sendEmail ?? false
			}, {
				onSuccess: (result: IPublishQueriesResult) => setPublishInitialResult(result),
				onError: () => endProcess()
			})
		}
	}, [isProcessing, sendEmail]);

	useEffect(() => {
		if (!publishInitialResult) {
			return;
		}
		
		setIsDraftAuditReportRequired(prev => publishInitialResult.isDraftAuditReportRequired);
		setInvoiceInfo(publishInitialResult.invoiceInfo);
		setIsInvoiceEnabled(isBlackLabel && !!publishInitialResult.invoiceInfo?.invoiceEnabled);
		setAuditReportOptions(publishInitialResult.auditReportOptions);
		
		if (publishInitialResult.isDraftAuditReportRequired) {
			if (publishInitialResult.type === PublishResponseType.Ok && !publishInitialResult.hasMessages) {
				toggleAuditReportOptionsModal()
			} else {
				if (publishInitialResult.ignoreNonWithholdingQueries && isInRoleOrSuperAdministrator(PortalRole.Administrator)) {
					setPublishResultWarnings(publishInitialResult.messages ?? [])
					setOverrideDialogConfig({
						message: formatMessage({id: 'overrideAndContinurePublishingPreArQueries'}),
						onDismiss: onDismissOverrideDialog,
						onConfirm: () => toggleAuditReportOptionsModal(),
						props: {..._DefaultDialogContentProps, title: formatMessage({id: 'warning'})}
					})
					toggleOverrideDialog()
				} else if (publishInitialResult.isPreviousYearOverride && isInRoleOrSuperAdministrator(PortalRole.Administrator)) {
					setPublishResultWarnings(publishInitialResult.messages ?? [])
					setOverrideDialogConfig({
						message: formatMessage({id: 'overrideAndContinueUnsignedAr'}),
						onDismiss: onDismissOverrideDialog,
						onConfirm: onConfirmOverrideDialog,
						props: {..._DefaultDialogContentProps, title: formatMessage({id: 'warning'})}
					});
					toggleOverrideDialog();
				} else {
					if (publishInitialResult.messages?.length) {
						setPublishResultWarnings(publishInitialResult.messages!);
						endProcess();
						toggleWarningsDialog()
					}
				}
			}
		} else {
			getIsPaymentReceived().catch(() => endProcess());
		}
	}, [publishInitialResult]);

	useEffect(() => {
		if (isPaymentRecievedResult === null) {
			return;
		}

		if (isPaymentRecievedResult.isPaymentReceived) {
			toggleInvoiceConfirmationDialog();
		} else {
			publishInitialAudit()
		}
	}, [isPaymentRecievedResult]);

	const publishInitialAudit = useCallback((options?: Partial<PublishInitialOptions>) => {
		publish({
			...PublishInitialOptionsDefualts,
			...(options ?? {}),
			jobId: jobId,
			sendEmail: sendEmail ?? false,
			isDraftArSentInsert: options?.isDraftArSentInsert ?? false
		}, {
			onSuccess: (result: IPublishQueriesResult) => {
				if (result.type === PublishResponseType.Invoice && !result.hasMessages) {
					if (result.invoiceInfo && !result.invoiceInfo.useStandardWorkflow) {
						if (isBlackLabel && result.invoiceInfo.isFeeCalculated) {
							setInvoiceInfo(result.invoiceInfo);
							toggleInvoiceModal()
						}
					}
				} else if (result.type === PublishResponseType.Ok && !result.hasMessages) {
					if (result.invoiceInfo && !result.invoiceInfo.useStandardWorkflow && !result.message) {
						if (isBlackLabel && result.invoiceInfo.isFeeCalculated) {
							setInvoiceInfo(result.invoiceInfo);
							toggleInvoiceModal()
						}

						if (result.message) {
							setPublishResultWarnings([result.message]);
						}
						console.debug('[INITIAL:ON_SUCCESS]', 'Nothing to do.. finishing.');
						endProcess();
						toggleWarningsDialog()
					} else if (result.message) {
						setPublishResultWarnings([result.message]);
						endProcess();
						toggleWarningsDialog()
					} else {
						console.debug('[INITIAL:ON_SUCCESS]', 'Nothing to do.. finishing.');
						endProcess();
					}
				} else {
					if (result.ignoreNonWithholdingQueries && isInRoleOrSuperAdministrator(PortalRole.Administrator)) {
						setPublishResultWarnings(result.messages ?? [])
						setOverrideDialogConfig({
                            message: formatMessage({ id: 'dispatchOverrideMessage' }),
                            onDismiss: onDismissOverrideDialog,
                            onConfirm: () => {
								toggleOverrideDialog()
                                publishInitialAudit({
                                    ignoreNonWithholdingQueries: true,
                                    isDraftArSentInsert: false,
                                    sendEmail: true,
                                });
                            },
                            props: { ..._DefaultDialogContentProps, title: formatMessage({ id: 'warning' }) },
                        });
						toggleOverrideDialog();
					} else {
						if (result.messages?.length) {
							setPublishResultWarnings(result.messages!);
							endProcess();
							toggleWarningsDialog()
						}
					}
				}
			},
			onError: () => endProcess()
		})
	}, [sendEmail])

	const classNames = mergeStyleSets({
		dialog: {
			'.ms-Dialog-main': {
				minWidth: '500px',
				backgroundColor: 'red',

				'> .ms-Dialog-inner': {
					marginLeft: 16
				}
			}
		}
	})

	const onConfirmInvoiceConfirmationDialog = () => {
		toggleInvoiceConfirmationDialog()
		publishInitialAudit()
	}

	const onDismissInvoiceConfirmationDialog = () => {
		toggleInvoiceConfirmationDialog()
		endProcess();
	}

	const onConfirmOverrideDialog = () => {
		toggleOverrideDialog()
		publishInitialAudit({ ignoreChecks: true })
	}

	const onDismissOverrideDialog = () => {
		toggleOverrideDialog();
		endProcess()
	}
	
	const onAuditReportOptionsClose = () => {
		toggleAuditReportOptionsModal();
		endProcess();
	}
	
	const onInvoiceCreated = () => {
		toggleInvoiceModal();
		publishInitialAudit()
	}
	
	const onInvoiceSkipped = () => {
		toggleInvoiceModal();
		publishInitialAudit({ includeInvoice: false, ignoreNonWithholdingQueries: true, isDraftArSentInsert: false, sendEmail: true })
	}

	const warningsDialogContentProps = {
		theme: theme.schemes?.default,
		type: DialogType.normal,
		title: formatMessage({id: 'publishPreARAuditQueriesWarning'}),
		closeButtonAriaLabel: 'Close',
	};

	const invoiceConfirmationDialogContentProps = {
		theme: theme.schemes?.default,
		type: DialogType.normal,
		title: formatMessage({id: 'warning'}),
		closeButtonAriaLabel: 'Close'
	};

	return (
		<>
			<Stack>
				<Stack.Item>
					<PrimaryButton onClick={handleOnClick} disabled={isButtonDisabled || isProcessing}>
						{isProcessing
							? <Spinner size={SpinnerSize.small}/>
							: formatMessage({id: 'publishPreARAuditQueries'})
						}
					</PrimaryButton>
				</Stack.Item>
			</Stack>

			<Stack className={classNames.dialog}>
				<DialogMessage onClick={toggleWarningsDialog} onDismis={toggleWarningsDialog}
				               dialogContentProps={warningsDialogContentProps}
				               hidden={!showWarningsDialog}
				               showOk={false}
				               cancelText={formatMessage({id: 'close'})}>
					{publishResultWarnings.length && publishResultWarnings.map(w => <li style={{ color: theme.schemes?.default?.semanticColors.bodyText }}>{w}</li>)}
				</DialogMessage>
			</Stack>

			{overrideDialogConfig && <Stack className={classNames.dialog}>
				<DialogMessage onClick={overrideDialogConfig.onConfirm} onDismis={overrideDialogConfig.onDismiss}
				               dialogContentProps={overrideDialogConfig.props}
				               hidden={!showOverrideDialog}
				               showOk={true}
				               okText={formatMessage({id: 'override'})}
				               cancelText={formatMessage({id: 'close'})}>
					<Stack tokens={{childrenGap: 8}}>
						<StackItem>
							{publishResultWarnings.length && publishResultWarnings.map(w => <li>{w}</li>)}
						</StackItem>
						<StackItem>
							{overrideDialogConfig.message}
						</StackItem>
					</Stack>
				</DialogMessage>
			</Stack>}

			<Stack className={classNames.dialog}>
				<DialogMessage onClick={onConfirmInvoiceConfirmationDialog} onDismis={onDismissInvoiceConfirmationDialog}
				               dialogContentProps={invoiceConfirmationDialogContentProps}
				               hidden={!showInvoiceConfirmationDialog}
				               showOk={true}
				               okText={formatMessage({id: 'dispatchInvoiceConfigmrationConfirmMessage'})}
				               cancelText={formatMessage({id: 'close'})}>
					{formatMessage({id: 'dispatchInvoiceConfirmationMessage'})}
				</DialogMessage>
			</Stack>

			{auditReportOptions && <Modal isOpen={showAuditReportOptionsModal}
			       title={formatMessage({ id: 'auditReportOptions' })} 
			       onDismiss={onAuditReportOptionsClose}
			>
				<AuditReportOptionsForm options={auditReportOptions}
				                        invoiceInfo={invoiceInfo}
				                        isDraftAuditReportRequired={isDraftAuditReportRequired!} 
				                        invoiceEnabled={isInvoiceEnabled}
				                        onClose={onAuditReportOptionsClose} />	
			</Modal>}

			{invoiceInfo && <Modal isOpen={showInvoiceModal} onDismiss={toggleInvoiceModal} title={formatMessage({id: 'generateInvoice'})}>
                <AdministrationInvoiceAddForm data={invoiceInfo}
                                              enableOverride={!invoiceInfo.overrideIncludeInvoice}
                                              onCreated={onInvoiceCreated}
                                              onSkip={onInvoiceSkipped}
                                              onClose={() => {
	                                              toggleInvoiceModal();
												  endProcess()
											  }} 
                                              auditReportDate={new Date()}>
                </AdministrationInvoiceAddForm>
            </Modal>}
		</>
	)
}