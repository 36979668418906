import {ISearchBoxProps, SearchBox, useTheme} from "@fluentui/react";
import React from "react";
import {useIntl} from "react-intl";

export interface ISearchProps extends  ISearchBoxProps{
    underline?: boolean;
    minWidth?: number;
    placeholder?: string;
}

export const Search=(props:ISearchProps)=>{
    const { formatMessage } = useIntl();
    const theme = useTheme();
    
    return (
        <SearchBox styles={{
            root: { 
                minWidth: props.minWidth || 480,
                border: `1px solid ${theme.schemes?.default?.palette.blackTranslucent40}`,
                selectors: {
                    "&:hover": {
                        borderColor: theme.schemes?.default?.semanticColors.inputBorderHovered,
                    },
                    "&:focus-within": {
                        borderColor: theme.schemes?.default?.semanticColors.inputFocusBorderAlt,
                    },
                },
            },
            field: {
                color: theme.schemes?.default?.semanticColors.inputText,
                selectors: {
                    '&::placeholder': { color: theme.schemes?.default?.semanticColors.disabledBodyText }
                }
            },
            clearButton: {
                selectors: {
                    '.ms-Button-icon': { color: theme.schemes?.default?.semanticColors.bodyText },
                    '&:hover .ms-Button-icon': { color: theme.schemes?.default?.semanticColors.bodyText }
                }
            }
        }}
                   placeholder={props.placeholder || formatMessage({id: 'searchByName'})}
                   underlined={props.underline || true}
                   onSearch={(newValue) => props.onSearch && props.onSearch(newValue)}
                   onClear={() => props.onClear && props.onClear()}
        />
    );
}
