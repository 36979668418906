import axios from 'axios';
import React from 'react';
import {useQuery} from 'react-query';
import { Context, commonHeaders } from 'utils';


type Props = {
    url: string;
};

export type DownloadUrlModel = {
    downloadUrl: string;
}

/**Mutation query for downloading file*/

function useGetDownloadUrl({
    url
}: Props) {
    const correctedUrl = escapeCharacters(url);
    const getUrl = correctedUrl.replace('download', 'getDownloadUrl');
    const context = React.useContext(Context);

    const {data: downloadUrl, refetch, remove} = useQuery(
        ['getDownloadUrl', url],
        () => axios.get<DownloadUrlModel>(`${getUrl}`, {
            ...commonHeaders(),
        }),
        {
            enabled: false,
        });

    return {downloadUrl, refetch, remove};
}

function replaceInFileName(
    input: string,
    search: string,
    replacement: string
): string {
    const marker = 'fileName';
    const markerIndex = input.indexOf(marker);
    if (markerIndex === -1) {
        return input;
    }

    const beforeMarker = input.substring(0, markerIndex + marker.length);
    const afterMarker = input.substring(markerIndex + marker.length);

    const updatedAfterMarker = afterMarker.split(search).join(replacement);
    return beforeMarker + updatedAfterMarker;
}

function replaceInFilePath(
    input: string,
    target: string,
    replacement: string
): string {
    const start = 'filePath';
    const end = '&fileName';
    const startIndex = input.indexOf(start);
    const endIndex = input.indexOf(end, startIndex + start.length);
  
    if (startIndex === -1 || endIndex === -1 || startIndex >= endIndex) {
      return input;
    }
  
    const before = input.slice(0, startIndex + start.length);
    const middle = input.slice(startIndex + start.length, endIndex).replace(target, replacement);
    const after = input.slice(endIndex);
  
    return before + middle + after;
}

function replaceCharacter(
    input: string,
    target: string,
    replacement: string
): string {
    return replaceInFileName(replaceInFilePath(input, target, replacement), target, replacement);
}

function escapeCharacters(url: string) : string{
    const escpapeSpaces = replaceCharacter(url, ' ', '%20');
    const escapePlus = replaceCharacter(escpapeSpaces, '+', '%2B');
    const escapeHash = replaceCharacter(escapePlus, '#', '%23')
    return replaceCharacter(escapeHash, '&', '%26');
}

export {useGetDownloadUrl};
