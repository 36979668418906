import { DefaultButton, IColumn, Link, SelectionMode, Spinner, Stack, Text, useTheme } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { Axios, AxiosResponse } from "axios";
import { FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useJobContext } from ".";
import { DataTable, Modal } from "../../components";
import { IClientComment, useGetClientComments } from "./hooks"


interface IClientCommentsContentProps{
    commentsData?: AxiosResponse<IClientComment[], any>;
    isCommentsFetching: boolean;
    isCommentsLoading: boolean;
}

const ClientCommentsContent: FunctionComponent<IClientCommentsContentProps> = ({ commentsData, isCommentsFetching, isCommentsLoading }) => {
    const { formatMessage } = useIntl();

    const [columns] = useState<IColumn[]>([
        {
            key: 'commentDescription',
            name: formatMessage({ id: 'commentDescription' }),
            minWidth: 350,
            fieldName: 'commentDescription',
            onRender: (item) =>
                (<Text styles={{ root: { fontStyle: 'italic' } }}>{item.description}</Text>)
        },
        {
            key: 'comment',
            name: formatMessage({ id: 'comment' }),
            minWidth: 350,
            fieldName: 'comment',
            onRender: (item) =>
                (<Text styles={{ root: { fontStyle: 'italic', whiteSpace: 'normal', wordWrap: 'break-word' } }}>
                    {item.comment}
                </Text>)
        }
    ]);

    if (isCommentsLoading) return <Stack horizontalAlign='center'><Spinner /></Stack>;
    if (!commentsData?.data) return <Stack>No data</Stack>;
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <DataTable
                initialColumns={columns}
                columns={columns}
                items={commentsData?.data}
                selectionMode={SelectionMode.none}
                enableShimmer={isCommentsFetching}
                containerHeight='100%'
                emptyProps={{
                    iconName:'CommentUrgent'
                }}
            />
        </Stack>
    );
}

export const ClientComments: FunctionComponent = () => {
    const { formatMessage } = useIntl();
    const [isOpenModal, { toggle: toggleOpenModal }] = useBoolean(false);
    const { job } = useJobContext();
    const { tabId } = useParams();
    const theme = useTheme();

    const { commentsData, isCommentsFetching, isCommentsLoading } = useGetClientComments({ jobId: job.id, tabId: Number(tabId) });

    const linkClickHandler = () => {
        if (!isOpenModal) {
            toggleOpenModal();
        }
    }

    return (
        <>
            {(!commentsData?.data || (commentsData?.data && commentsData.data.length == 0)) && <Link
                style={{ color: theme.schemes?.default?.semanticColors.bodyText }}
                onClick={linkClickHandler}>
                {formatMessage({ id: 'clientComments' })}
            </Link>}
            {(commentsData?.data && commentsData.data.length > 0) && <Link style={{ color: theme.schemes?.default?.palette.red }} onClick={linkClickHandler}>
                {formatMessage({ id: 'clientComments' })}
            </Link>}
            <Modal
                title={formatMessage({ id: 'clientComments' })}
                isOpen={isOpenModal}
                onDismiss={() => {
                    toggleOpenModal()
                }}>
                <ClientCommentsContent commentsData={commentsData} isCommentsFetching={isCommentsFetching} isCommentsLoading={isCommentsLoading}/>
            </Modal>
        </>);
}