import { MessageBarType } from '@fluentui/react';
import axios from 'axios';
import { AxiosError } from 'axios';
import { useCommonErrorNotification, useNotifications } from 'components/notifications';
import {useMutation} from 'react-query';

type FileUrl = {
    url: string;
    fileName: string;
};

/**Mutation query for downloading file*/

function useGetFile() {
    const {showCommonError} = useCommonErrorNotification();
    const {mutate: getFile, isLoading: isExportDownloading} = useMutation(
        (data: FileUrl) => axios.get(`${data.url}`, {
            responseType: 'blob'
        }),
        {
            onSuccess: ({data}, {fileName}) => {
                let streamsaver = require('streamsaver');
                
                let blob = new Blob([data], {type: 'application/octet-stream'});
                let fileStream = streamsaver.createWriteStream(fileName, {
                    size: blob.size
                });

                const readableStream = new Response(blob).body;
                if (readableStream === null) {
                    console.error("[USE_GET_FILE]: Readable stream is null. Check the Blob you are downloading.");
                    return;
                }
                
                if (window.WritableStream && readableStream.pipeTo) {
                    console.debug("[USE_GET_FILE]: Saving via pipeTo...");
                    return readableStream.pipeTo(fileStream)
                        .then(() => console.debug('[USE_GET_FILE]: Downloaded', fileName))
                        .catch((e) => console.error('[USE_GET_FILE]: Error saving via pipeTo.', e));
                }

                console.warn("[USE_GET_FILE]: pipeTo not supported. Using legacy FileSaver");

                let FileSaver = require('file-saver');
                try {
                    FileSaver.saveAs(blob, fileName);
                } catch (e) {
                    console.error('[USE_GET_FILE]: Failed to save file', e);
                }
            },
            onError: (error: AxiosError) => {
                showCommonError();
            }
        }
    );

    return {getFile, isExportDownloading};
}

export {useGetFile};
