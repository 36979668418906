import { Stack, useTheme, Text, TextField, PrimaryButton } from '@fluentui/react';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Context, formatDate } from '../../../../../../utils';
import { useSubmitJob } from '../../hooks/useSubmitJob';
import { useUnlockJobSubmit } from '../../hooks/useUnlockJobSubmit';
import { PortalRole, useWorkContext } from "../../../../../../providers";

interface IInitialInformationChecklistPageProps {
    job: any;
    areSoftwareFilesUploaded: boolean;
    isFundCodeNeeded: boolean;
    clientName: string;
}

export const JobInformation: FunctionComponent<IInitialInformationChecklistPageProps> = ({ job, areSoftwareFilesUploaded, isFundCodeNeeded, clientName }) => {
    const { formatMessage } = useIntl();
    const context = useContext(Context);
    const { update: submit, isUpdating: isSubmitting } = useSubmitJob();
    const { update: unlockSubmit, isUpdating: isInsubmitting } = useUnlockJobSubmit();
    const theme = useTheme();
    const { isInRoleOrSuperAdministrator, isInRole } = useWorkContext()

    const submitButtonClickHandler = () => {
        if (job?.dateReceived) {
            unlockSubmit({ jobGuid: job.guid });
        }
        else {
            submit({ jobGuid: job.guid });
        }
    }

    const [isUnlockSubmitButtonDisabled, setIsUnlockSubmitButtonDisabled] = useState<boolean>(true);
    useEffect(() => {
        setIsUnlockSubmitButtonDisabled((job?.dateReceived ? !isInRoleOrSuperAdministrator() : !isInRoleOrSuperAdministrator(PortalRole.CPUser)) || !job?.dateReceived && ((isFundCodeNeeded && !job?.fundCode) || (job?.clientQuestionsState === 'NeedAnswers' && !context?.tenantInfo?.IsWhiteLabel && job.iicQuestionsEnabled) || !areSoftwareFilesUploaded))
    }, [job?.dateReceived, job?.fundCode, isFundCodeNeeded, job?.clientQuestionsState, context?.tenantInfo?.IsWhiteLabel, job?.iicQuestionsEnabled, areSoftwareFilesUploaded]);

    return (
            <Stack tokens={{ childrenGap: 12 }} styles={{ root: { width:'100%'}}}>
                <Stack tokens={{ childrenGap: 16 }} styles={{ root: {margin: '0.7rem'}}} horizontal>
                    <Stack.Item styles={{ root: { width: '25%' } }} align={'center'}>
                        <Text theme={theme.schemes?.default}>{formatMessage({ id: 'clientName' })}</Text>
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: '35%' } }} align={'center'}>
                        <TextField styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 } }} disabled theme={theme.schemes?.default} value={clientName} />
                    </Stack.Item>
                </Stack>
                <Stack tokens={{ childrenGap: 16 }} styles={{ root: { margin: '0.7rem' } }} horizontal>
                    <Stack.Item styles={{ root: { width: '25%' } }} align={'center'}>
                        <Text theme={theme.schemes?.default}>{formatMessage({ id: 'fundName' })}</Text>
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: '35%' } }} align={'center'}>
                        <TextField styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 } }} disabled theme={theme.schemes?.default} value={job?.fund.name} />
                    </Stack.Item>
                </Stack>
                <Stack tokens={{ childrenGap: 16 }} styles={{ root: { margin: '0.7rem' } }} horizontal>
                    <Stack.Item styles={{ root: { width: '25%' } }} align={'center'}>
                        <Text theme={theme.schemes?.default}>{formatMessage({ id: 'jobYear' })}</Text>
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: '35%' } }} align={'center'}>
                        <TextField styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 } }} disabled theme={theme.schemes?.default} value={job?.year} />
                    </Stack.Item>
                </Stack>
                {job?.dateReceived &&
                <Stack tokens={{ childrenGap: 16 }} styles={{ root: { margin: '0.7rem' } }} horizontal>
                    <Stack.Item styles={{ root: { width: '25%' } }} align={'center'}>
                        <Text theme={theme.schemes?.default}>{formatMessage({ id: 'dateSubmitted' })}</Text>
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: '35%' } }} align={'center'}>
                    <TextField styles={{ root: { border: '1px solid ' + theme.schemes?.default?.palette.blackTranslucent40 } }} disabled theme={theme.schemes?.default} value={formatDate(job?.dateReceived, 'dd/MM/yyyy')} />
                    </Stack.Item>
                </Stack>
                }
                <Stack tokens={{ childrenGap: 16 }} styles={{ root: { margin: '0.7rem' } }} horizontal>
                    <Stack.Item styles={{ root: { width: '25%' } }} align={'center'}>
                        <Text theme={theme.schemes?.default}></Text>
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: '35%' } }} align={'center'}>
                    <PrimaryButton disabled={isUnlockSubmitButtonDisabled}
                        text={job?.dateReceived ? formatMessage({ id: 'unlockSubmit' }) : formatMessage({ id: 'submitAuditJob' })}
                        onClick={submitButtonClickHandler}
                    />
                    </Stack.Item>
                </Stack>
        </Stack>
    );
}