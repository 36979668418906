import { IDetailsRowProps, IStyle, Link, useTheme } from "@fluentui/react";
import { CustomProcedureRowRender } from "../CustomProcedureTemplates";
import { createElement, FunctionComponent, ReactElement, useMemo } from "react";
import { ITableColumn, SanitizedText } from "components";
import { useJobRedirects } from "../../shared";

export const ensureAlighWithStrategyProcedureRowRender: CustomProcedureRowRender = (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => ReactElement | null, rows?: any[], onRowItemUpdate?: (newItem: any) => void) => {
    if (!props || !defaultRender) {
        //technically these may be undefined...
        return null;
    }

    const auditProcedureColumn = (props?.columns ?? []).find(x => x.key === 'auditProcedure');
        if (auditProcedureColumn) {
            // custom auditProcedure column render
            const ensureAlignAuditProcedureColumn: ITableColumn = {
                ...auditProcedureColumn,
                key: 'auditProcedure_ensureAlign',
                onRender: (item, index, column) => {
                    return index != undefined && createElement(EnsureAlignWithStrategyProcedureLinkTemplate, {
                        procedure: item
                    })
                }
            }
    
            const index = props.columns.indexOf(auditProcedureColumn);
    
            const columns = [...props.columns];
            columns[index] = {...ensureAlignAuditProcedureColumn}
    
            props.columns = [...columns]
        }

    return defaultRender(props);
}

interface IProcedureProps {
    procedure: any
}

const EnsureAlignWithStrategyProcedureLinkTemplate 
    : FunctionComponent<IProcedureProps> = ({ procedure }: IProcedureProps) => {

    const theme = useTheme()
    const linkStyles: IStyle = useMemo(() => ({
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer',
    }), [theme]);

    
    const {navigateToTableRow} = useJobRedirects();
    const onProcedureClicked = (): void => {
            navigateToTableRow({tabId: 6})
        };

    return (
        <div onClick={() => onProcedureClicked()}>
            <SanitizedText data={ procedure.title} styles={{root: linkStyles }} color='blue'/>
        </div>
    );
}