import * as React from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { CalculatorTemplateProps } from '../../types';
import { useIntl } from 'react-intl';
import { useUpdateCalculatorData } from '../../hooks';
import { useFieldArray, useForm } from 'react-hook-form';
import { DefaultFormSettings } from '../../../../../../constants';
import { CalculatorType } from '../../CalculatorType';
import {
    ControlledDatePicker,
    ControlledDropdown,
    ControlledTextField,
    DialogMessage,
    ITableColumn,
    Modal,
    ModalFooter,
    SanitizedText,
} from '../../../../../../components';
import {
    ActionButton,
    DialogType,
    IColumn,
    IconButton,
    IDropdownOption,
    MessageBarType,
    PrimaryButton,
    Stack,
    TextField,
    useTheme,
} from '@fluentui/react';
import { IPensionCalculatorData } from './IPensionCalculatorData';
import { useJobContext } from '../../../../JobPortalLayoutPage';
import { useBoolean } from '@fluentui/react-hooks';
import { CalculatorDataTable } from '../shared';
import { toCurrencyFormat } from 'utils/stringHelper';
import { useNotifications } from 'components/notifications';
import { useTabContext } from 'pages/JobPortal';

type PensionCalculatorProps = CalculatorTemplateProps;
export const PensionCalculator = forwardRef(({ data }: PensionCalculatorProps, ref) => {
    const { formatMessage } = useIntl();
    const { isTabEnabled } = useTabContext();
    const theme = useTheme();
    const { showNotification } = useNotifications();
    const { jobId, jobYear } = useJobContext();

    const [showCommentsDialog, setShowCommentsDialog] = useState<boolean>(false);
    const [commentItemIndex, setCommentItemIndex] = useState<number>();
    const [editingField, setEditingField] = useState<{ index: number; columnName: string } | undefined>(undefined);
    const { update, isUpdating } = useUpdateCalculatorData();
    const [pensionCalculatorData, setPensionCalculatorData] = useState<IPensionCalculatorData>(data?.data as IPensionCalculatorData);
    const { handleSubmit, watch, control, getValues, setValue } = useForm<IPensionCalculatorData>({
        ...DefaultFormSettings,
        defaultValues: pensionCalculatorData,
        mode: 'onTouched',
    });
    const watchFields = watch();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'details',
    });
    const accountTypeOptions = useMemo<IDropdownOption[]>(() => {
        return pensionCalculatorData.accountTypes.map((x) => ({ key: x.value, text: x.field } as IDropdownOption));
    }, [pensionCalculatorData.accountTypes]);

    const [currentItem, setCurrentItem] = useState<number | null>();
    const [showDeleteDialog, { toggle: toggleDeleteDialog }] = useBoolean(false);

    const updateCalcData = React.useCallback(() => {
        if (!isTabEnabled) return;

        const answers = getValues('details');
        const isValid = answers.every((x) => !!x.account && !!x.accountType && !!x.memberName && !!x.startDate && !!x.endDate);

        if (!isValid) {
            showNotification({
                type: MessageBarType.blocked,
                name: formatMessage({ id: 'pleaseCheckCalculatorData' }),
                description: formatMessage({ id: 'pensionFieldsShouldntBeEmpty' }),
            });

            return;
        }

        update({
            saveComments: false,
            calculatorType: CalculatorType.Pension,
            isCustom: false,
            answer: undefined,
            answers: getValues('details'),
            jobId: jobId,
        });
    }, [fields, getValues, isTabEnabled]);

    const calcSum = (fieldName: string) => {
        let sum = 0;
        watchFields.details.forEach((value: any, index) => {
            let fieldValue = +value[fieldName];
            if (!isNaN(fieldValue)) {
                sum += fieldValue;
            }
        });

        return sum;
    };

    const onEditClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, column: IColumn) => {
            if (!isTabEnabled) return;

            setEditingField({ index: index!, columnName: column?.name! });
            event.stopPropagation();
        },
        [isTabEnabled]
    );

    const totalMinPensionDrawn = useMemo(() => {
        return calcSum('minPensionDrawnCalc');
    }, [watchFields]);

    const totalMaxPensionDrawn = useMemo(() => {
        return calcSum('maxPensionDrawnCalc');
    }, [watchFields]);
    const totalActualPensionPaid = useMemo(() => {
        return calcSum('actualPensionPaid');
    }, [watchFields]);
    const totalVariance = useMemo(() => {
        const sumActualPensionPaid = calcSum('actualPensionPaid');
        const sumMinimumPensiontobeDrawn = calcSum('minPensionDrawnCalc');
        const sumMaximumPensiontobeDrawn = calcSum('maxPensionDrawnCalc');

        const minValue =
            sumActualPensionPaid < sumMinimumPensiontobeDrawn ? toCurrencyFormat(sumActualPensionPaid - sumMinimumPensiontobeDrawn) : 'OK';
        const maxValue =
            sumActualPensionPaid > sumMaximumPensiontobeDrawn ? toCurrencyFormat(sumActualPensionPaid - sumMaximumPensiontobeDrawn) : 'OK';
        return `Max ${maxValue}\nMin ${minValue}`;
    }, [watchFields]);

    const handleOnAddClick = () => {
        if (!isTabEnabled) return;

        append({
            id: 0,
            isCustom: true,
            memberName: '',
            startDate: new Date(jobYear - 1, 6, 1, 12, 0, 0).toISOString(),
            endDate: new Date(jobYear, 5, 30, 12, 0, 0).toISOString(),
        });
    };
    const handleOnRemoveClick = (index: number) => {
        if (!isTabEnabled) return;

        remove(index);
    };
    const handleOnCommentIconClick = (index?: number) => {
        setCommentItemIndex(index);
        setShowCommentsDialog(true);
    };

    const handleOnEditingCommentDismiss = () => {
        setCommentItemIndex(undefined);
        setShowCommentsDialog(false);
    };

    const getDatesDifferenceInDays = React.useCallback((startDate: Date, endDate: Date) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        const utc1 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
        const utc2 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

        const totalDays = Math.floor((utc1 - utc2) / _MS_PER_DAY) + 1;
        return totalDays;
    }, []);

    useEffect(() => {
        if (fields.length) {
            calculateTotalDays(fields.length - 1);
            calculateMinRate(fields.length - 1);
            calculateMaxRate(fields.length - 1);
        }
    }, [fields.length]);

    const calculateTotalDays = React.useCallback(
        (index: number) => {
            const endDate = new Date(getValues(`details.${index}.endDate`));
            const startDate = new Date(getValues(`details.${index}.startDate`));

            if (!endDate || !startDate) return;

            const totalDays = getDatesDifferenceInDays(startDate, endDate);

            if (Number.isNaN(totalDays)) return;

            setValue(`details.${index}.totalDays`, totalDays);
        },
        [getValues, setValue, getDatesDifferenceInDays]
    );

    const calculateVariance = React.useCallback(
        (index: number) => {
            const maxDiff = getValues(`details.${index}.actualPensionPaid`) - getValues(`details.${index}.maxPensionDrawnCalc`);
            const minDiff = getValues(`details.${index}.actualPensionPaid`) - getValues(`details.${index}.minPensionDrawnCalc`);

            setValue(
                `details.${index}.variance`,
                `MAX ${maxDiff > 0 ? toCurrencyFormat(maxDiff) : 'OK'} \nMIN ${minDiff < 0 ? toCurrencyFormat(minDiff) : 'OK'}`
            );
        },
        [getValues, setValue]
    );

    const calculateValidity = React.useCallback(
        (index: number) => {
            setValue(
                `details.${index}.valid`,
                getValues(`details.${index}.actualPensionPaid`) >= getValues(`details.${index}.minPensionDrawnCalc`) &&
                    getValues(`details.${index}.actualPensionPaid`) <= getValues(`details.${index}.maxPensionDrawnCalc`) &&
                    getValues(`details.${index}.age`) >= 55
            );
        },
        [getValues, setValue]
    );

    const calculateMinRate = React.useCallback(
        (index: number) => {
            const item = getValues(`details.${index}`);

            if (item.accountType === 'DBP') {
                setValue(`details.${index}.minRate`, 0);
                return;
            }

            const mainDate = new Date(jobYear, 5, 1);

            if (new Date(item.startDate) >= mainDate) {
                setValue(`details.${index}.minRate`, 0);
                return;
            }

            const minPensionExpanded = item.parentMinPensionExpanded ?? pensionCalculatorData.minPensionExpanded;

            setValue(`details.${index}.minRate`, minPensionExpanded.find((x) => x.age == item.age)?.percent ?? 0);
        },
        [getValues, jobYear, data]
    );

    const calculateMaxRate = React.useCallback(
        (index: number) => {
            const item = getValues(`details.${index}`);

            switch (item.accountType) {
                case 'TRIS':
                    setValue(`details.${index}.maxRate`, 10);
                    return;
                case 'ABP':
                    setValue(`details.${index}.maxRate`, 100);
                    return;
                default:
                    setValue(`details.${index}.maxRate`, 0);
                    return;
            }
        },
        [getValues]
    );

    const calculateMinPensionDrawn = React.useCallback(
        (index: number) => {
            const item = getValues(`details.${index}`);
            console.log(item);
            if (item.accountType === 'DBP' || item.accountType === '') {
                setValue(`details.${index}.minPensionDrawnCalc`, item.minPensionDrawn);
                return;
            }

            const days = (jobYear % 4 === 0 && jobYear % 100 !== 0) || jobYear % 400 === 0 ? 366 : 365;

            setValue(
                `details.${index}.minPensionDrawnCalc`,
                Math.round((((item.assetsForPension * item.minRate) / 100) * item.totalDays) / days / 10) * 10
            );

            calculateVariance(index);
        },
        [getValues, jobYear, calculateVariance]
    );

    const calculateMaxPensionDrawn = React.useCallback(
        (index: number) => {
            const item = getValues(`details.${index}`);
            console.log(item);

            if (item.accountType === 'DBP' || item.accountType === '') {
                setValue(`details.${index}.maxPensionDrawnCalc`, item.maxPensionDrawn);
                return;
            }

            const days = (jobYear % 4 === 0 && jobYear % 100 !== 0) || jobYear % 400 === 0 ? 366 : 365;

            setValue(
                `details.${index}.maxPensionDrawnCalc`,
                Math.round((((item.assetsForPension * item.maxRate) / 100) * item.totalDays) / days / 10) * 10
            );

            calculateVariance(index);
        },
        [getValues, jobYear, calculateVariance]
    );

    //Added in order to trigger re-render after dropdown or datepicker value is changed
    //because they dont have editing logic like textfields and always enabled
    const [editingHelper, setEditingHelper] = useState(0);

    const columns = useMemo<ITableColumn[]>(() => {
        return [
            {
                key: 'comments',
                name: '',
                fieldName: 'comments',
                minWidth: 35,
                maxWidth: 35,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <Stack horizontalAlign={'center'} grow>
                            <IconButton
                                onClick={() => handleOnCommentIconClick(index)}
                                styles={
                                    getValues(`details.${index}.comments`)?.length
                                        ? {
                                              icon: { color: theme.palette.red },
                                              iconHovered: { color: theme.palette.redDark },
                                          }
                                        : undefined
                                }
                                iconProps={{ iconName: 'comment' }}
                            />
                        </Stack>
                    ) : null,
            },
            {
                key: 'memberName',
                name: formatMessage({ id: 'calculator_pension_title_memberName' }),
                fieldName: 'memberName',
                minWidth: 120,
                maxWidth: 120,
                onRender: (item, index, column) => (
                    <Stack
                        grow
                        styles={{
                            root: {
                                overflow: 'visible',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                            },
                        }}
                    >
                        {index !== undefined &&
                            (!editingField || editingField.index !== index || editingField.columnName !== column?.name!) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => {
                                            onEditClick(event, index, column!);
                                        }}></div>
                                    <TextField
                                        disabled
                                        multiline
                                        resizable={false}
                                        styles={{
                                            root: { width: '100%' },
                                            field: {
                                                color:
                                                    pensionCalculatorData.details[index]?.isMemberNameChanged ||
                                                    pensionCalculatorData.details[index]?.memberName !=
                                                        getValues(`details.${index}.memberName`)
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                                whiteSpace: 'normal',
                                                wordBreak: 'break-word',
                                                overflow: 'visible',
                                            },
                                        }}
                                        value={getValues(`details.${index}.memberName`) || ''}
                                    />
                                </>
                            )}
                        {index !== undefined &&
                            !!editingField &&
                            editingField.index === index &&
                            editingField.columnName === column?.name! && (
                                <ControlledTextField
                                    autoFocus
                                    name={`details.${index}.memberName`}
                                    control={control}
                                    onChange={(e) => setValue(`details.${index}.isMemberNameChanged`, true)}
                                    multiline
                                />
                            )}
                    </Stack>
                ),
            },
            {
                key: 'account',
                name: formatMessage({ id: 'calculator_pension_title_account' }),
                fieldName: 'account',
                minWidth: 60,
                maxWidth: 60,
                onRender: (item, index, column) => (
                    <Stack grow>
                        {index !== undefined &&
                            (!editingField || editingField.index !== index || editingField.columnName !== column?.name!) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => {
                                            onEditClick(event, index, column!);
                                        }}></div>
                                    <TextField
                                        disabled
                                        styles={{
                                            field: {
                                                borderColor: !getValues(`details.${index}.account`)
                                                    ? theme.schemes?.default?.palette.red
                                                    : theme.schemes?.default?.semanticColors.bodyText,
                                                color:
                                                    pensionCalculatorData.details[index]?.isAccountChanged ||
                                                    pensionCalculatorData.details[index]?.account != getValues(`details.${index}.account`)
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                            },
                                        }}
                                        value={getValues(`details.${index}.account`) || ''}
                                    />
                                </>
                            )}
                        {index !== undefined &&
                            !!editingField &&
                            editingField.index === index &&
                            editingField.columnName === column?.name! && (
                                <ControlledTextField
                                    autoFocus
                                    name={`details.${index}.account`}
                                    control={control}
                                    onChange={(e) => setValue(`details.${index}.isAccountChanged`, true)}
                                />
                            )}
                    </Stack>
                ),
            },
            {
                key: 'age',
                name: formatMessage({ id: 'calculator_pension_title_age' }),
                fieldName: 'age',
                minWidth: 35,
                maxWidth: 35,
                onRender: (item, index, column) => (
                    <Stack grow>
                        {index !== undefined &&
                            (!editingField || editingField.index !== index || editingField.columnName !== column?.name!) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => {
                                            onEditClick(event, index, column!);
                                        }}></div>
                                    <TextField
                                        disabled
                                        styles={{
                                            field: {
                                                color:
                                                    pensionCalculatorData.details[index]?.isAgeChanged ||
                                                    pensionCalculatorData.details[index]?.age != getValues(`details.${index}.age`)
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                            },
                                        }}
                                        value={(getValues(`details.${index}.age`) || 0)?.toString()}
                                    />
                                </>
                            )}
                        {index !== undefined &&
                            !!editingField &&
                            editingField.index === index &&
                            editingField.columnName === column?.name! && (
                                <ControlledTextField
                                    autoFocus
                                    name={`details.${index}.age`}
                                    control={control}
                                    onChange={(e) => {
                                        setValue(`details.${index}.isAgeChanged`, true);
                                        calculateMinRate(index);
                                        calculateMinPensionDrawn(index);
                                        calculateMaxPensionDrawn(index);
                                        calculateValidity(index);
                                    }}
                                />
                            )}
                    </Stack>
                ),
            },
            {
                key: 'assetsForPension',
                name: formatMessage({ id: 'calculator_pension_title_assetsForPension' }),
                fieldName: 'assetsForPension',
                minWidth: 80,
                maxWidth: 80,
                onRender: (item, index, column) => (
                    <Stack grow>
                        {index !== undefined &&
                            (!editingField || editingField.index !== index || editingField.columnName !== column?.name!) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => {
                                            onEditClick(event, index, column!);
                                        }}></div>
                                    <TextField
                                        disabled
                                        styles={{
                                            field: {
                                                color:
                                                    pensionCalculatorData.details[index]?.isAssetsForPensionChanged ||
                                                    pensionCalculatorData.details[index]?.assetsForPension !=
                                                        getValues(`details.${index}.assetsForPension`)
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                            },
                                        }}
                                        value={toCurrencyFormat((getValues(`details.${index}.assetsForPension`) || 0)?.toString())}
                                    />
                                </>
                            )}
                        {index !== undefined &&
                            !!editingField &&
                            editingField.index === index &&
                            editingField.columnName === column?.name! && (
                                <ControlledTextField
                                    autoFocus
                                    name={`details.${index}.assetsForPension`}
                                    control={control}
                                    onChange={(e) => {
                                        setValue(`details.${index}.isAssetsForPensionChanged`, true);
                                        calculateMinPensionDrawn(index);
                                        calculateMaxPensionDrawn(index);
                                    }}
                                />
                            )}
                    </Stack>
                ),
            },
            {
                key: 'accountType',
                name: formatMessage({ id: 'calculator_pension_title_accountType' }),
                fieldName: 'accountType',
                minWidth: 70,
                maxWidth: 70,
                onRender: (item, index) => (
                    <Stack grow>
                        {index !== undefined && (
                            <ControlledDropdown
                                disabled={!isTabEnabled}
                                color={
                                    pensionCalculatorData.details[index]?.isAccountTypeChanged ||
                                    pensionCalculatorData.details[index]?.accountType != getValues(`details.${index}.accountType`)
                                        ? theme.schemes?.default?.palette.blue
                                        : theme.schemes?.default?.semanticColors.bodyText
                                }
                                options={accountTypeOptions}
                                name={`details.${index}.accountType`}
                                control={control}
                                onValueChange={(e) => {
                                    setValue(`details.${index}.isAccountTypeChanged`, true);
                                    calculateMaxRate(index);
                                    calculateMinRate(index);
                                    calculateMinPensionDrawn(index);
                                    calculateMaxPensionDrawn(index);
                                    calculateVariance(index);
                                    calculateValidity(index);
                                    setEditingHelper((prev) => prev + 1);
                                }}
                            />
                        )}
                    </Stack>
                ),
            },
            {
                key: 'dates',
                name: formatMessage({ id: 'calculator_pension_title_dates' }),
                fieldName: 'dates',
                minWidth: 160,
                maxWidth: 160,
                onRender: (item, index) => (
                    <Stack grow>
                        {index !== undefined && (
                            <ControlledDatePicker
                                disabled={!isTabEnabled}
                                styles={{
                                    root: {
                                        input: {
                                            color:
                                                pensionCalculatorData.details[index]?.isStartDateChanged ||
                                                pensionCalculatorData.details[index]?.startDate != getValues(`details.${index}.startDate`)
                                                    ? theme.schemes?.default?.palette.blue
                                                    : theme.schemes?.default?.semanticColors.bodyText,
                                        },
                                    },
                                }}
                                onDateSelected={(e) => {
                                    setValue(`details.${index}.isStartDateChanged`, true);
                                    calculateTotalDays(index);
                                    calculateMinPensionDrawn(index);
                                    calculateMaxPensionDrawn(index);
                                    setEditingHelper((prev) => prev + 1);
                                }}
                                underlined
                                label={formatMessage({ id: 'calculator_pension_label_startDate' })}
                                name={`details.${index}.startDate`}
                                control={control}
                            />
                        )}
                        {index !== undefined && (
                            <ControlledDatePicker
                                disabled={!isTabEnabled}
                                styles={{
                                    root: {
                                        input: {
                                            color:
                                                pensionCalculatorData.details[index]?.isEndDateChanged ||
                                                pensionCalculatorData.details[index]?.endDate != getValues(`details.${index}.endDate`)
                                                    ? theme.schemes?.default?.palette.blue
                                                    : theme.schemes?.default?.semanticColors.bodyText,
                                        },
                                    },
                                }}
                                onDateSelected={(e) => {
                                    setValue(`details.${index}.isEndDateChanged`, true);
                                    calculateTotalDays(index);
                                    calculateMinPensionDrawn(index);
                                    calculateMaxPensionDrawn(index);
                                    setEditingHelper((prev) => prev + 1);
                                }}
                                underlined
                                label={formatMessage({ id: 'calculator_pension_label_endDate' })}
                                name={`details.${index}.endDate`}
                                control={control}
                            />
                        )}
                    </Stack>
                ),
            },
            {
                key: 'totalDays',
                name: formatMessage({ id: 'calculator_contribution_totalDays' }),
                fieldName: 'totalDays',
                minWidth: 40,
                maxWidth: 40,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText textAlign={'center'} data={getValues(`details.${index}.totalDays`)?.toString()} />
                    ) : null,
            },
            {
                key: 'minRate',
                name: formatMessage({ id: 'calculator_contribution_minRate' }),
                fieldName: 'minRate',
                minWidth: 50,
                maxWidth: 50,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText
                            textAlign={'right'}
                            numberFormat={'fractional'}
                            data={getValues(`details.${index}.minRate`)?.toString()}
                        />
                    ) : null,
            },
            {
                key: 'maxRate',
                name: formatMessage({ id: 'calculator_contribution_maxRate' }),
                fieldName: 'maxRate',
                minWidth: 50,
                maxWidth: 50,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText
                            textAlign={'right'}
                            numberFormat={'fractional'}
                            data={getValues(`details.${index}.maxRate`)?.toString()}
                        />
                    ) : null,
            },
            {
                key: 'minPensionDrawn',
                name: formatMessage({ id: 'calculator_contribution_minPensionDrawn' }),
                fieldName: 'minPensionDrawn',
                minWidth: 70,
                maxWidth: 70,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText
                            textAlign={'right'}
                            numberFormat={'fractional'}
                            data={getValues(`details.${index}.minPensionDrawnCalc`)?.toString()}
                        />
                    ) : null,
            },
            {
                key: 'maxPensionDrawn',
                name: formatMessage({ id: 'calculator_contribution_maxPensionDrawn' }),
                fieldName: 'maxPensionDrawn',
                minWidth: 70,
                maxWidth: 70,
                onRender: (item, index) =>
                    index !== undefined ? (
                        <SanitizedText
                            textAlign={'right'}
                            numberFormat={'fractional'}
                            data={getValues(`details.${index}.maxPensionDrawnCalc`)?.toString()}
                        />
                    ) : null,
            },
            {
                key: 'actualPensionPaid',
                name: formatMessage({ id: 'calculator_contribution_actualPensionPaid' }),
                fieldName: 'actualPensionPaid',
                minWidth: 70,
                maxWidth: 70,
                onRender: (item, index, column) => (
                    <Stack grow>
                        {index !== undefined &&
                            (!editingField || editingField.index !== index || editingField.columnName !== column?.name!) && (
                                <>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            zIndex: 100,
                                        }}
                                        onClick={(event) => {
                                            onEditClick(event, index, column!);
                                        }}></div>
                                    <TextField
                                        disabled
                                        styles={{
                                            field: {
                                                color:
                                                    pensionCalculatorData.details[index]?.isActualPensionPaidChanged ||
                                                    pensionCalculatorData.details[index]?.actualPensionPaid !=
                                                        getValues(`details.${index}.actualPensionPaid`)
                                                        ? theme.schemes?.default?.palette.blue
                                                        : theme.schemes?.default?.semanticColors.bodyText,
                                            },
                                        }}
                                        value={toCurrencyFormat((getValues(`details.${index}.actualPensionPaid`) || 0)?.toString())}
                                    />
                                </>
                            )}
                        {index !== undefined &&
                            !!editingField &&
                            editingField.index === index &&
                            editingField.columnName === column?.name! && (
                                <ControlledTextField
                                    autoFocus
                                    name={`details.${index}.actualPensionPaid`}
                                    control={control}
                                    onChange={(e) => {
                                        setValue(`details.${index}.isActualPensionPaidChanged`, true);
                                        calculateValidity(index);
                                        calculateVariance(index);
                                    }}
                                />
                            )}
                    </Stack>
                ),
            },
            {
                key: 'valid',
                name: formatMessage({ id: 'calculator_contribution_valid' }),
                fieldName: 'valid',
                minWidth: 50,
                maxWidth: 50,
                onRender: (item, index) =>
                    index !== undefined ? <SanitizedText data={getValues(`details.${index}.valid`) ? 'YES' : 'NO'} /> : null,
            },
            {
                key: 'variance',
                name: formatMessage({ id: 'calculator_contribution_variance' }),
                fieldName: 'variance',
                minWidth: 70,
                maxWidth: 70,
                onRender: (item, index) =>
                    index !== undefined ? <SanitizedText data={getValues(`details.${index}.variance`)?.toString() || ''} /> : null,
            },
            {
                key: 'actions',
                name: '',
                onRender: (item, index) => (
                    <Stack grow horizontal horizontalAlign={'center'}>
                        {index !== undefined &&
                            isTabEnabled &&
                            getValues(`details.${index}.isCustom`) &&
                            (!editingField || editingField.index !== index) && (
                                <IconButton
                                    iconProps={{ iconName: 'delete' }}
                                    styles={{ icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.redDark } }}
                                    onClick={() => {
                                        setCurrentItem(index!);
                                        toggleDeleteDialog();
                                    }}
                                />
                            )}
                    </Stack>
                ),
                minWidth: 70,
                maxWidth: 70,
            },
        ];
    }, [pensionCalculatorData, editingField, editingHelper, isTabEnabled]);

    const totalColumns = useMemo<ITableColumn[]>(() => {
        return [
            {
                key: 'comments',
                name: '',
                fieldName: 'comments',
                minWidth: 35,
                maxWidth: 35,
                onRender: () => null,
            },
            {
                key: 'memberName',
                name: formatMessage({ id: 'calculator_pension_title_memberName' }),
                fieldName: 'memberName',
                minWidth: 120,
                maxWidth: 120,
                onRender: () => null,
            },
            {
                key: 'account',
                name: formatMessage({ id: 'calculator_pension_title_account' }),
                fieldName: 'account',
                minWidth: 60,
                maxWidth: 60,
                onRender: () => null,
            },
            {
                key: 'age',
                name: formatMessage({ id: 'calculator_pension_title_age' }),
                fieldName: 'age',
                minWidth: 35,
                maxWidth: 35,
                onRender: () => null,
            },
            {
                key: 'assetsForPension',
                name: formatMessage({ id: 'calculator_pension_title_assetsForPension' }),
                fieldName: 'assetsForPension',
                minWidth: 80,
                maxWidth: 80,
                onRender: () => null,
            },
            {
                key: 'accountType',
                name: formatMessage({ id: 'calculator_pension_title_accountType' }),
                fieldName: 'accountType',
                minWidth: 70,
                maxWidth: 70,
                onRender: () => null,
            },
            {
                key: 'dates',
                name: formatMessage({ id: 'calculator_pension_title_dates' }),
                fieldName: 'dates',
                minWidth: 160,
                maxWidth: 160,
                onRender: () => null,
            },
            {
                key: 'totalDays',
                name: formatMessage({ id: 'calculator_contribution_totalDays' }),
                fieldName: 'totalDays',
                minWidth: 40,
                maxWidth: 40,
                onRender: () => null,
            },
            {
                key: 'minRate',
                name: formatMessage({ id: 'calculator_contribution_minRate' }),
                fieldName: 'minRate',
                minWidth: 50,
                maxWidth: 50,
                onRender: () => null,
            },
            {
                key: 'maxRate',
                name: formatMessage({ id: 'calculator_contribution_maxRate' }),
                fieldName: 'maxRate',
                minWidth: 50,
                maxWidth: 50,
                onRender: () => null,
            },
            {
                key: 'minPensionDrawn',
                name: formatMessage({ id: 'calculator_contribution_minPensionDrawn' }),
                fieldName: 'minPensionDrawn',
                minWidth: 70,
                maxWidth: 70,
                onRender: (item, index) =>
                    index !== undefined ? <SanitizedText textAlign={'center'} data={totalMinPensionDrawn.toString()} /> : null,
            },
            {
                key: 'maxPensionDrawn',
                name: formatMessage({ id: 'calculator_contribution_maxPensionDrawn' }),
                fieldName: 'maxPensionDrawn',
                minWidth: 70,
                maxWidth: 70,
                onRender: (item, index) =>
                    index !== undefined ? <SanitizedText textAlign={'center'} data={totalMaxPensionDrawn.toString()} /> : null,
            },
            {
                key: 'actualPensionPaid',
                name: formatMessage({ id: 'calculator_contribution_actualPensionPaid' }),
                fieldName: 'actualPensionPaid',
                minWidth: 70,
                maxWidth: 70,
                onRender: (item, index) =>
                    index !== undefined ? <SanitizedText textAlign={'center'} data={totalActualPensionPaid.toString()} /> : null,
            },
            {
                key: 'valid',
                name: formatMessage({ id: 'calculator_contribution_valid' }),
                fieldName: 'valid',
                minWidth: 50,
                maxWidth: 50,
                onRender: () => null,
            },
            {
                key: 'variance',
                name: formatMessage({ id: 'calculator_contribution_variance' }),
                fieldName: 'variance',
                minWidth: 70,
                maxWidth: 70,
                onRender: (item, index) =>
                    index !== undefined ? <SanitizedText textAlign={'center'} data={totalVariance.toString()} /> : null,
            },
            {
                key: 'actions',
                name: '',
                onRender: () => null,
                minWidth: 70,
                maxWidth: 70,
            },
        ];
    }, [pensionCalculatorData, editingField]);

    useImperativeHandle(
        ref,
        () => ({
            onSubmit() {
                handleSubmit(updateCalcData)();
            },
        }),
        [updateCalcData]
    );

    useEffect(() => {
        setPensionCalculatorData(data?.data as IPensionCalculatorData);
        setValue('details', (data?.data as IPensionCalculatorData).details);
    }, [data?.data]);

    const dialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: formatMessage({ id: 'removingItemDialogTitle' }),
        closeButtonAriaLabel: 'Close',
        subText: formatMessage({ id: 'removingItemDialogMessage' }),
    };

    const handleClickOutside = (event: any) => {
        if ((event.srcElement.id as string).includes('TextField')) return;

        setEditingField(undefined);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <Stack styles={{ root: { minWidth: 1100 } }} horizontalAlign={'center'} tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    <CalculatorDataTable
                        initialColumns={columns}
                        columns={columns}
                        header={{
                            rowHeight: 100,
                            horizontalAlign: 'center',
                        }}
                        shimmerLines={fields?.length || 3}
                        enableShimmer={isUpdating}
                        items={fields || []}
                    />
                    <CalculatorDataTable
                        initialColumns={totalColumns}
                        columns={totalColumns}
                        isHeaderVisible={false}
                        enableShimmer={isUpdating}
                        items={[{}]}
                    />
                </Stack.Item>
                <Stack.Item>
                    {isTabEnabled && (
                        <ActionButton
                            styles={{ textContainer: { color: theme.schemes?.default?.semanticColors.bodyText } }}
                            text={formatMessage({ id: 'clickToAddRow' })}
                            iconProps={{ iconName: 'add' }}
                            onClick={handleOnAddClick}
                        />
                    )}
                </Stack.Item>
            </Stack>
            <Modal isOpen={showCommentsDialog} onDismiss={handleOnEditingCommentDismiss} title={formatMessage({ id: 'comments' })}>
                <Stack tokens={{ childrenGap: 16 }}>
                    <ControlledTextField disabled={!isTabEnabled} name={`details.${commentItemIndex!}.comments`} multiline control={control} />
                    <ModalFooter horizontalAlign={'end'}>
                        <PrimaryButton  disabled={!isTabEnabled} onClick={handleOnEditingCommentDismiss}>{formatMessage({ id: 'save' })}</PrimaryButton>
                    </ModalFooter>
                </Stack>
            </Modal>

            <DialogMessage
                onClick={() => {
                    toggleDeleteDialog();
                    handleOnRemoveClick(currentItem!);
                }}
                dialogContentProps={dialogContentProps}
                hidden={!showDeleteDialog}
                onDismis={() => {
                    toggleDeleteDialog();
                    setCurrentItem(null);
                }}
            />
        </>
    );
});
