import React, { cloneElement, FunctionComponent, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
    IColumn,
    Icon,
    IconButton,
    IContextualMenuItem,
    IDetailsGroupDividerProps,
    IDetailsGroupRenderProps,
    IDetailsRowProps,
    IGroup,
    mergeStyleSets,
    MessageBarType,
    NeutralColors,
    ScrollablePane, ScrollbarVisibility,
    SelectionMode,
    Stack, Sticky, StickyPositionType
} from "@fluentui/react";
import { DataTable, IDataTableProps, SanitizedText, useContextMenu } from 'components';
import { IReport } from 'pages/JobPortal/interfaces/IReportsInfo';
import { useCreateReviewItem } from '../../../../hooks';
import { logger } from '../../../../../../services';
import { useNotifications } from '../../../../../../components/notifications';
import { useJobContext } from '../../../../JobPortalLayoutPage';
import { useSectionContext } from '../../../Section';
import { useIntl } from 'react-intl';
import { ReviewType } from '../../../../enums';
import { GroupSummary, IGroupWithSummary } from './GroupSummary';
import { getDepthIndent } from './consts';
import { useIsDarkTheme } from '../../../../../../hooks';
import { useJobRedirects } from '../../shared';
import { useBoolean } from "@fluentui/react-hooks";
import { IDetailsHeaderProps } from "@fluentui/react/lib/components/DetailsList/DetailsHeader";
import { useLocation } from "react-router-dom";

export interface IExpandProps {
    expandChildren?: boolean;
    expandChildrenDepth?: number;
    showExpander?: boolean;
}

export interface IGroupSummaryProps {
    show: boolean;
    childRenderer(group: IGroupWithSummary): ReactNode;
    style?: any;
}

export interface IMergedGroupHeaderProps {
    column?: IColumn;
    propertyKey?: string;
    formatName?: (item: any) => string;
}

export interface IReportTableGroupProps extends IDetailsGroupRenderProps {
    mergedGroupHeader?: IMergedGroupHeaderProps;
    summary?: IGroupSummaryProps;
}

export interface IReportTableProps extends IDataTableProps {
    items: IReport[];
    isFetching?: boolean;
    openAll?: boolean;
    isOpenedItem?: (item: any) => boolean;
    expandProps?: IExpandProps;
    groupProps?: IReportTableGroupProps;
}

type ReportParentItemInfo = { parentId?: number | null, isOpen: boolean, isVisible: boolean };
type ReportTableItem = ReportParentItemInfo & IReport;

const getGroupIds = (reports: IReport[]): number[] =>
    reports.reduce((acc: any[], report) => {
        if (report.children?.length) {
            acc.push(...[report.id, ...getGroupIds(report.children)])
        }
        return acc;
    }, [])

const getOpenIds = (reports: IReport[], isOpenFn?: (item: any) => boolean): number[] => {
    if (!isOpenFn) {
        return [];
    }

    return reports.reduce((acc: any[], report) => {
        if (isOpenFn(report)) {
            acc.push(report.id);
        }
        if (report.children?.length) {
            acc.push(...getOpenIds(report.children, isOpenFn))
        }
        return acc;
    }, []);
}

export const ReportTable: FunctionComponent<IReportTableProps> = ({
                                                                      items,
                                                                      groups,
                                                                      initialColumns,
                                                                      isFetching,
                                                                      openAll = false,
                                                                      isOpenedItem, 
                                                                      expandProps, 
                                                                      groupProps,
                                                                      ...otherProps}: IReportTableProps) => {

    const {jobId} = useJobContext();
    const {section, styles} = useSectionContext();
    const {showNotification} = useNotifications();
    const {setContextMenuItems, closeMenu} = useContextMenu();
    const {formatMessage} = useIntl();
    const isDark = useIsDarkTheme();
    const {navigateToTableRow} = useJobRedirects();
    const { hash } = useLocation();
    
    const {create: sendToReview, isCreating: isSending} = useCreateReviewItem();

    expandProps = {...{ expandChildren: false, expandChildrenDepth: 1, showExpander: true }, ...expandProps}
    
    const [expandedItemIds, setExpandedItemIds] = useState<number[]>(openAll ? getGroupIds(items) : getOpenIds(items, isOpenedItem));
    const [isAllOpened, setIsAllOpened] = useState<boolean | null>(!!openAll || null);
    
    const isOpened = useCallback((id?: number): boolean => !!id && expandedItemIds.some(i => id === i), [expandedItemIds])

    const classNames = mergeStyleSets({
        container: {
            position: 'relative',
            width: '100%',
            height: styles?.container?.maxHeight ?? window.innerHeight,
            
            'div.ms-List-cell': {
                minHeight: 0
            },
            
            '.ms-DetailsHeader': {
                paddingTop: 0
            }
        },
        headerWrapper: {
            minHeight: otherProps.header?.rowHeight ?? 'inherit'
        },
        expanderColumn: {
            '&..ms-DetailsHeader-cell': {
                padding: 8,
                '& .ms-DetailsHeader-cellTitle': {
                    justifyContent: 'center'
                }
            }
        }
    })
    
    const getFlatReports = useCallback(
        (reports: IReport[], parentInfo: ReportParentItemInfo): ReportTableItem[] =>
            reports.reduce((acc: ReportTableItem[], report: IReport, currentIndex: number) => {
                const item: ReportTableItem = {
                    ...report,
                    isOpen: isOpened(report.id),
                    isVisible: parentInfo.isOpen && parentInfo.isVisible,
                    parentId: parentInfo.parentId,
                };
                acc.push(item);
                if (report.children?.length) {
                    const childrenAcc = getFlatReports(report.children, {
                        parentId: item.id,
                        isOpen: item.isOpen,
                        isVisible: item.isVisible,
                    });
                    acc.push(...childrenAcc);
                }
                return acc;
            }, []),
        [expandedItemIds]
    );
    
    const rows = useMemo<any[]>(() => getFlatReports(items, { parentId: null, isOpen: true, isVisible: true }), [items, expandedItemIds]);
    
    const tableGroups = useMemo<IGroup[] | undefined>(() => {
        if (!groups?.length) {
            return groups;
        }
        
        const _reducer = (gArr: IGroup[], isCollapsed: boolean): IGroup[] => {
            return gArr.reduce((acc: IGroup[], g: IGroup): IGroup[] => {
                acc.push({
                    ...g,
                    isCollapsed: isCollapsed,
                    children: g.children?.length ? [..._reducer(g.children, isCollapsed)] : g.children
                });
                return acc;
            }, []);
        }
        
        if (isAllOpened !== null) {
            return _reducer(groups, !isAllOpened);
        }
        
        return groups;
        
    }, [groups, isAllOpened])
    
    const sendItem = (item: any, reviewType: ReviewType) => {
        sendToReview({
            itemId: item.id,
            jobId: jobId,
            sectionId: section.id,
            reviewType: reviewType
        }, {
            onSuccess: (res: any) => {
                navigateToTableRow({ tabId: 9, sectionId: ReviewType.Manager ? 64 : 65, itemId: res.data.id })
                showNotification({
                    type: MessageBarType.success,
                    description: formatMessage({id: 'itemAddedToReviews'}),
                    name: ''
                })
            }
        });
        closeMenu();

    };

    const [contextMenuItems] = useState<IContextualMenuItem[]>([
        {
            key: 'sendToManager',
            text: formatMessage({id: 'sendToManager'}),
            iconProps: {iconName: 'Share'},
            onItemClick: (e: MouseEvent, item: any) => sendItem(item, ReviewType.Manager)
        },
        {
            key: 'sendToPartner',
            text: formatMessage({id: 'sendToPartner'}),
            iconProps: {iconName: 'Share'},
            onItemClick: (e: MouseEvent, item: any) => sendItem(item, ReviewType.Partner)
        }
    ]);
    
    const onExpanderColumnClick = useCallback((ev, column) => setIsAllOpened(prev => !prev), []);
    
    const onExpanderRowClick = useCallback((item: ReportTableItem) => {
        setIsAllOpened(null);
        if (item.isOpen) {
            onCollapse(item.id)
        } else {
            onExpand(item.id);
            if (expandProps?.expandChildren) {
                expandChildren(expandProps.expandChildrenDepth || 1)(item);
            }
        }
    }, [isAllOpened, expandProps])
    
    const tableColumns = useMemo(() => {
        let newColumns: IColumn[] = [];
        if (!!expandProps?.showExpander && _.find(items, 'children')) {
            newColumns.push(
                {
                    key: 'expander',
                    name: '',
                    minWidth: 32,
                    maxWidth: 32,
                    fieldName: 'expander',
                    headerClassName: classNames.expanderColumn,
                    onRender: (item: ReportTableItem) => (
                        <Stack horizontal>
                            <Stack.Item styles={{root: {width: 32}}}>
                                {!!item.children?.length &&
                                    <IconButton iconProps={{iconName: item.isOpen ? 'ChevronDown' : 'ChevronRight'}}
                                                onClick={() => onExpanderRowClick(item)}
                                    />
                                }
                            </Stack.Item>
                        </Stack>
                    ),
                    onColumnClick: onExpanderColumnClick,
                    onRenderHeader: (props, defaultRender) => {
                        return !!groups && defaultRender ? defaultRender(props) : <Icon iconName={isAllOpened ? 'ChevronDown' : 'ChevronRight'}></Icon>
                    }
                },
            );
        }
        newColumns = newColumns.concat(initialColumns);
        return newColumns;
    }, [initialColumns, items, isAllOpened]);
    
    
    const expandChildren = (maxDepth: number): (item: IReport) => void => {
        const expand = (item: IReport, depth: number = 0): void => {
            if (item.children?.length > 0 && depth < maxDepth) {
                item.children.map(i => {
                    onExpand(i.id);
                    expand(i, depth + 1)
                })
            }
        }
        
        return expand;
    }

    const onExpand = (id: number) => {
        setExpandedItemIds((prev) => [...prev, id]);
    }
    const onCollapse = (id: number) => {
        setExpandedItemIds((prev) => prev.filter((expandedItemId) => expandedItemId !== id));
    }
    
    useEffect(() => {
        setContextMenuItems(contextMenuItems);
    }, [setContextMenuItems])

    const onRenderGroupHeader = (headerProps?: IDetailsGroupDividerProps, defaultRender?: (props?: any) => JSX.Element | null) => {
        if (!headerProps || !defaultRender) {
            //technically these may be undefined...
            return null;
        }
        
        if (groupProps?.summary?.show) {
            return defaultRender({
                ...headerProps,
                onRenderTitle: () => {
                    return (
                        <GroupSummary group={headerProps.group as IGroupWithSummary} childRenderer={groupProps?.summary!.childRenderer}></GroupSummary>
                    )
                }
            });
        }

        return defaultRender(headerProps);
    };

    const onRenderRow = useCallback((props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => ReactElement | null) => {
        if (!props || !defaultRender) {
            //technically these may be undefined...
            return null;
        }

        if (!groups && groupProps?.mergedGroupHeader && props.item?.children?.length > 0) {
            const name = groupProps.mergedGroupHeader!.formatName ? groupProps.mergedGroupHeader!.formatName(props?.item) : props?.item[groupProps.mergedGroupHeader!.propertyKey || 0]
            const onRender = groupProps?.summary
                ? (i: any, _: any, column: IColumn) => {
                    const group: IGroupWithSummary = {
                        name: name,
                        key: `${name}_${Date.now()}`,
                        startIndex: 0,
                        count: 0,
                        summary: i.summary ?? {}
                    }
                    return (
                        <GroupSummary group={group} style={groupProps?.summary!.style} childRenderer={groupProps?.summary!.childRenderer}></GroupSummary>
                    )
                }
                : (i: any, _: any, column: IColumn) => (
                    <SanitizedText data={name} isHeadingRow={true} />
                )
            
            props.columns = [props.columns[0], {...groupProps.mergedGroupHeader.column ?? {
                    key: 'group',
                    name: '',
                    minWidth: 100,
                    fieldName: groupProps.mergedGroupHeader.propertyKey,
                    onRender: onRender,
                } as IColumn}]

            let element = defaultRender(props);
            if (!element) {
                return element
            }
            return cloneElement(element, {
                styles: {
                    root: {
                        paddingLeft: getDepthIndent(props.item?.depth),
                        borderTop: '1px solid ' + (isDark ? 'rgba(180, 180, 180, 0.4)' : NeutralColors.gray30),
                        '.ms-DetailsRow-cell, .ms-DetailsRow-cell:last-of-type': {
                            borderTop: 'none',
                            borderRight: 'none',
                            borderLeft: 'none',
                        }
                    }
                }
            });
        }

        let element = defaultRender(props);
        if (!element) {
            return element
        }

        return cloneElement(element, {
            styles: {
                root: {
                    display: !props?.item?.isVisible ? 'none' : 'inherit'
                }
            }
        })
    }, []);

    useEffect(() => {
        const id = +(hash.replace('#', ''));
        if (!isNaN(id)) {
            const groupIds: any[] = [];
            const _fn = (reports: any[]): boolean => {
                return reports.some(p => {
                    if (p.children?.length) {
                        if (p.children.some((c: any) => c.id === id) || _fn(p.children)) {
                            groupIds.push(p.id)
                            return true;
                        }
                    }
                    return false;
                })
            }

            _fn(items);
            groupIds.forEach(gId => onExpand(gId))
        }
    }, [hash, items]);

    useEffect(() => {
        if (isAllOpened === true) {
            setExpandedItemIds(getGroupIds(items)) 
        }
        if (isAllOpened === false) {
            setExpandedItemIds([])
        }
    }, [isAllOpened]);
    
    const onRenderFixedDetailsHeader = (headerProps?: IDetailsHeaderProps, defaultRender?: (props?: IDetailsHeaderProps) => JSX.Element | null) => {
        if (!headerProps || !defaultRender) {
            //technically these may be undefined...
            return null;
        }
        
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced >
                <div className={classNames.headerWrapper}>
                    {defaultRender({...headerProps})}
                </div>
                
            </Sticky>
        )
    }
    
    return (
        <Stack className={classNames.container}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DataTable
                    initialColumns={tableColumns}
                    groups={tableGroups}
                    items={rows}
                    selectionMode={SelectionMode.none}
                    enableShimmer={isFetching}
                    contextMenuOptions={{ meta: { sectionId: section.id }}}
                    containerHeight='100%'
                    groupProps={{
                        ...groupProps,
                        onToggleCollapseAll: (isAllCollapsed: boolean) => {
                            setIsAllOpened(prev => !isAllCollapsed)
                        },
                        onRenderHeader: onRenderGroupHeader
                    }}
                    onRenderRow={onRenderRow}
                    {...otherProps}
                    onRenderDetailsHeader={onRenderFixedDetailsHeader}
                    header={{ horizontalAlign:'center'}}
                />
            </ScrollablePane>
        </Stack>
    );
};

export {getDepthPaddingStyles} from './consts';