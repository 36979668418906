import React, {createRef, forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {useIntl} from 'react-intl';
import {ProceduresDataTable} from 'pages/JobPortal/components';
import {DialogMessage, ITableColumn, Loading, ModalWithForm, SanitizedText} from 'components';
import {formatDate} from 'utils';
import {IEventSummaryItem} from "./interfaces/IEventSummaryItem";
import {DialogType, IconButton, Stack, useTheme} from '@fluentui/react';
import {EventSummaryForm} from "./EventSummaryForm";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {useChangeEventSummary, useDeleteEventSummary} from "./hooks";
import {useGetProcedures} from "../../../../hooks";
import {ISectionInfo} from "../../../../interfaces";
import {IEventSummaryGeneralQuestion} from "./interfaces/IEventSummaryGeneralQuestion";
import { useSectionContext } from '../../..';
import { useTabContext } from "../../../../JobPortalPage";

interface Props {
    section: ISectionInfo;
}

export const EventsSummaryProcedureTemplate = forwardRef(({section}: Props, ref) => {
    const {job} = useJobContext();
    const {proceduresData, isProceduresLoading, isProceduresFetching, refetchProcedures} = useGetProcedures({
        templateId: job?.templateId,
        jobId: job?.id,
        jobYear: job?.year,
        sectionId: section.id,
        controlType: section.controlType,
        fundId: job?.fund?.id,
    });
    const {jobId} = useJobContext();
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const dialogContentProps = {
        theme: theme.schemes?.default,
        type: DialogType.normal,
        title: formatMessage({id: 'removingItemDialogTitle'}),
        closeButtonAriaLabel: 'Close',
        subText: formatMessage({id: 'removingItemDialogMessage'}),
    };
    const childRef = createRef<any>();

    const {deleteItem, isDeleting} = useDeleteEventSummary();
    const {change, isUpdating} = useChangeEventSummary();

    const [currentItem, setCurrentItem] = useState<IEventSummaryItem>();
    const [eventSummaryItems, setEventSummaryItems] = useState<IEventSummaryItem[]>();
    const [generalQuestions, setGeneralQuestions] = useState<IEventSummaryGeneralQuestion[]>();

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const {sections} = useTabContext();

    const [auditQualificationPtnApproved, setAuditQualificationPtnApproved] = useState((sections ?? []).find(x => x.reference === 'Audit Qualification')?.sectionAnswer?.partnerApproval);
    
    const columns: ITableColumn[] = [
        {
            key: 'eventName',
            name: formatMessage({id: 'eventName'}),
            fieldName: 'eventName',
            onRender: (item: IEventSummaryItem) => <SanitizedText data={item?.name}/>,
            minWidth: 300,
        },
        {
            key: 'eventDate',
            name: formatMessage({id: 'eventDate'}),
            fieldName: 'eventDate',
            onRender: (item: IEventSummaryItem) => <SanitizedText
                data={formatDate(item?.date?.toString())}/>,
            minWidth: 200,
            maxWidth: 300,
        },
        {
            key: 'completionDate',
            name: formatMessage({id: 'completionDate'}),
            fieldName: 'completionDate',
            onRender: (item: IEventSummaryItem) => <SanitizedText
                data={formatDate(item?.completionDate?.toString())}/>,
            minWidth: 200,
            maxWidth: 300,
        },
        {
            key: 'actions',
            name: '',
            onRender: (item: IEventSummaryItem) => {
                return (
                    <Stack horizontal horizontalAlign='center' grow>
                        <Stack.Item>
                            <IconButton onClick={() => {
                                setCurrentItem(item);
                                setShowEditModal(true);
                            }} iconProps={{iconName: 'edit'}} disabled={auditQualificationPtnApproved}/>
                        </Stack.Item>
                        <Stack.Item>
                            <IconButton iconProps={{iconName: 'delete'}}
                                        onClick={() => {
                                            setCurrentItem(item);
                                            setShowDeleteDialog(true);
                                        }}
                                        disabled={auditQualificationPtnApproved}
                                        styles={{
                                            iconHovered: {color: theme.palette.redDark},
                                            icon: {color: theme.palette.red}
                                        }}/>
                        </Stack.Item>
                    </Stack>
                );
            },
            minWidth: 70,
            maxWidth: 70
        }
    ];
    const handleDelete = (item: IEventSummaryItem) => {
        deleteItem({
            jobId: item.jobId,
            eventSummaryId: item.id
        }, {
            onSuccess: () => {
                setEventSummaryItems(prevState => {
                    if (!prevState)
                        return prevState;

                    let deletingItem = prevState?.indexOf(item);
                    if (deletingItem && deletingItem >= 0) {
                        prevState?.splice(deletingItem, 1);
                    }
                })
            }
        });
    };

    const handleSubmit = (item: IEventSummaryItem) => {
        change(item, {
            onSuccess: (resp) => {
                if (item.id)
                    refetchProcedures();
                else {
                    setEventSummaryItems(prevState => {
                        prevState?.push(resp.data);
                        return prevState;
                    });
                }
                
                setShowEditModal(false);
            }
        });
    }

    useImperativeHandle(ref, () => ({
        refresh() {
            refetchProcedures();
        },
        onAddClick() {
            setShowEditModal(true);
        }
    }));
    
    useEffect(() => {
        setEventSummaryItems(proceduresData?.data.items);
        setGeneralQuestions((proceduresData?.data as any)?.contraventionQuestions);
    }, [proceduresData?.data]);

    const { updateLoadedState } = useSectionContext();

    useEffect(() => {
        updateLoadedState(!isProceduresLoading);
    }, [proceduresData]);

    if (isProceduresLoading)
        return <Loading/>;

    return (
        <>
            <ProceduresDataTable
                items={eventSummaryItems || []}
                isLoading={isProceduresFetching || isUpdating}
                columns={columns}
                hideIfEmpty={false}
            />
            <ModalWithForm isOpen={showEditModal}
                           title={formatMessage({id: currentItem ? 'jobPortal_editEventSummary' : 'jobPortal_creatingEventSummary'})}
                           onSubmit={() => childRef?.current?.submit()}
                           isSubmitting={isUpdating}
                           submitDisabled={auditQualificationPtnApproved}
                           onDismiss={() => {
                               setShowEditModal(false);
                               setCurrentItem(undefined);
                           }}>
                <EventSummaryForm jobId={jobId} generalQuestions={generalQuestions || []} item={currentItem}
                                  ref={childRef} onSubmit={handleSubmit}/>
            </ModalWithForm>
            <DialogMessage
                onClick={() => {
                    setShowDeleteDialog(false);
                    handleDelete(currentItem!);
                }}
                dialogContentProps={dialogContentProps}
                hidden={!showDeleteDialog}
                onDismis={() => {
                    setShowDeleteDialog(false);
                }}/>
        </>
    );
});
